import {
  DataSourceParentObjectTypes,
  ObjectTypeAndPoliciesParams
} from './objectTypeAndPoliciesSlice'
import { GRAPHQL_API_FILTERS } from '../../constants'
import { gql } from 'graphql-request'

export const queryObjectTypeAndPolicies = (params: ObjectTypeAndPoliciesParams): string => {
  let filter = ''
  if (params.filters && !!Object.values(params.filters).length) {
    const filtersStr = Object.entries(params.filters).map(([key, values]) => {
      return `{key: ${key}, values: ${JSON.stringify(values)}}`
    })
    filter += `filter: [${filtersStr}],`
  }

  return gql`
  {
    datasources(id: "${params.datasourceId}", first: 1) {
      edges {
        node {
          parentObjectType
          policies(first: 1){
            count
          }
        }
      }
    }
    objectGroupByParent(${filter}){
      count
    }
    dataExtensions: objectGroupByParent(
      filter: [{ key: DATASOURCE_IDS, values: ["${params.datasourceId}"] }]
      booleanFilter: [{ key: ${GRAPHQL_API_FILTERS.onlyDataExtension}, value: true }]
    ) {
      count
    }
    businessUnits: objectGroupByParent(
      filter: [{ key: DATASOURCE_IDS, values: ["${params.datasourceId}"] }]
      booleanFilter: [{ key: ${GRAPHQL_API_FILTERS.onlyBusinessUnit}, value: true }]
    ) {
      count
    }
  }
`
}

// TODO: add type for graphql response
export const mapQueryObjectTypeAndPolicies = (
  raw: any
): {
  parentObjectType: DataSourceParentObjectTypes
  policiesCount: number
  parentObjectCount: number
  dataExtensionsCount: number
  businessUnitsCount: number
} => {
  try {
    return {
      policiesCount: raw.datasources.edges[0]?.node?.policies?.count || 0,
      parentObjectType: raw.datasources.edges[0]?.node?.parentObjectType || '',
      parentObjectCount: raw.objectGroupByParent?.count || 0,
      dataExtensionsCount: raw.dataExtensions?.count || 0,
      businessUnitsCount: raw.businessUnits?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

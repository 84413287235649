import {
  mapQueryCloudResourcesById,
  mapQueryDataSourceMixedSettings,
  mapQueryDataSourceProcessingStatus,
  mapQuerySotOptions,
  queryCloudResourcesById,
  queryDataSourceMixedSettings,
  queryDataSourceProcessingStatus,
  querySotOptions
} from './queries'
import { ScanConditionsDataSourceTypes, STATES, UnstructuredScanConditions } from '../../interfaces'
import apiService from '../../services/api/apiService'
import {
  DataSourcesConfigurations,
  DATA_SOURCE_ID,
  DATA_SOURCE_TYPE,
  DATA_SOURCE_TYPES,
  DATASOURCE_CREATE_OR_UPDATE_STATUS
} from '../../constants'
import graphqlService from '../../services/graphqlService'
import { ConnectionStatsTabs } from '../databases/databasesConnectionStatsList'
import { ConnectionStatsParams } from '../databases/databasesSlice'
import {
  DataSourceV2,
  HumanReadableSchedulePolicy,
  StructuredScanConditionsV2,
  TestConnectionDetails
} from '../../services/api/apiTypes'
import { DocumentLabel } from '../documentLabelsV2/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const DS_CONNECTION_TYPE = 'datasource_type'
export const DS_INSTANCE_NAME = 'name'
export const DS_SCAN_CONDITIONS = 'scanConditions'
export const DS_SCHEDULE = 'schedulePolicy'
export const DS_OWNER = 'createdBy'
export const DS_DESCRIPTION = 'description'
export const DS_ACCOUNT_NAME = 'accountName'
export const DS_USER_NAME = 'username'
export const DS_ACCESS_KEY = 'awsAccessKey'
export const DS_SECTET_KEY = 'awsSecretKey'
export const DS_PASSWORD = 'password'
export const DS_WAREHOUSE = 'warehouse'
export const DS_ROLE = 'role'
export const DS_PORT = 'port'
export const DS_DATABASE = 'database'
export const DS_HOST = 'host'
export const DS_HOST_NAME = 'hostname'
export const DS_SERVICE_NAME = 'serviceName'
export const DS_SOURCE_CAN_CREATE_ENTITIES = 'canCreateEntities'
export const DS_SOURCE_OF_TRUTH = 'isSourceOfTruth'
export const DS_SOT_CONFIG = 'sotConfiguration'
export const DS_LOCATION = 'location'
export const DS_PURPOSE = 'purpose'
export const DS_STAGE = 'stage'
export const DS_START_DATE = 'startDate'
export const DS_TIME_PERIOD = 'timePeriod'
export const DS_SUB_DOMAIN = 'subdomain'
export const DS_AUTH_METHOD = 'authMethod'
export const DS_EMAIL = 'email'
export const DS_API_TOKEN = 'apiToken'
export const DS_ACCESS_TOKEN = 'accessToken'
export const DS_REFRESH_TOKEN = 'refreshToken'
export const DS_CLIENT_ID = 'clientId'
export const DS_CLIENT_SECRET = 'clientSecret'
export const DS_TENANT_ID = 'tenantId'
export const DS_APP_ID = 'appId'
export const DS_ENDPOINT = 'endpoint'
export const DS_GRANT_TYPE = 'grantType'
export const DS_CLIENT_URL = 'clientUrl'
export const DS_URL = 'url'
export const DS_SSL = 'ssl'
export const DS_SSL_CERTIFICATE = 'sslCertificate'
export const DS_SSL_KEY = 'sslKey'
export const DS_SSL_CA_CERTIFICATE = 'sslCACert'
export const DS_DEFAULT_DATABASE = 'defaultDatabase'
export const DS_AUTH_MECHANISM = 'authenticationMechanism'
export const DS_AWS_REGION = 'awsRegion'
export const DS_AWS_ACCESS_KEY = 'awsAccessKey'
export const DS_AWS_SECRET_KEY = 'awsSecretKey'
export const DS_QUERY_ENGINE = 'queryEngine'
export const DS_SYNC_METHOD = 'syncMethod'
export const DS_SERVER_VERSION = 'serverVersion'
export const DS_BASE_DS_ID = 'baseDatasourceId'
export const DS_FOLDER_URI = 'folderURI'
export const DS_BASE_DS_SCAN_CONDITIONS = 'baseDatasourceScanConditions'
export const DS_DRIVE_ID = 'driveId'
export const DS_HUBSPOT_HUB_ID = 'hubID'
export const DS_HUBSPOT_CRENDENTIALS_TITLE = 'credentialsTitle'
export const DS_ACCOUNT_JSON = 'accountJson'
export const DS_API_KEY = 'apiKey'
export const DS_DATA_CENTER = 'dataCenter'
export const DS_DOCUMENT_LABEL_SETS = 'labels'
export const DS_DOMAIN_URL = 'domainURL'
export const DS_INTERNAL_DOMAIN_NAMES = 'internalDomainNames'
export const DS_REGION = 'region'
export const DS_WORKGROUP = 'workgroup'
export const DS_ENTRA_AUTH = 'entraAuthn'
export const DS_FLAVOUR = 'flavour'
export const DS_DOMAIN_NAME = 'domainName'
export const DS_CLOUD_RESOURCE_ID = 'cloudResourceId'
export const DS_WORKSPACE_URL = 'workspaceURL'
export const DS_WAREHOUSE_ID = 'warehouseId'
export const DS_WAREHOUSE_NAME = 'warehouseName'
export const DS_TOKEN = 'token'
export const DS_IS_SANDBOX = 'isSandbox'
export const DS_FREQUENCY_TYPE = 'frequencyType'
export const DS_FREQUENCY_VALUE = 'frequencyValue'
export const DS_SCOPE_OF_SCANNING = 'scopeOfScanning'
export const DS_SCAN_ITEMS = 'scanItems'
export const DS_DATA_SOURCE_ID = 'dataSourceId'
export const DS_DATA_SOURCE_TYPE = 'dataSourceType'
export const DS_LABELS = 'labels'
export const DS_FREQUENCY_TYPES = 'frequencyTypes'
export const DS_DAY_OF_WEEK = 'dayOfWeek'
export const DS_DAY_OF_MONTH = 'dayOfMonth'

export const QUERY_ENGINE_OPTIONS = ['Athena']
export const PRIMARY_WORKGROUP = 'primary'

export enum DS_CONNECTION_TYPES {
  remote = 'Remote'
}

export enum DS_DATA_AUTH_TYPES {
  basic = 'BASIC_AUTH',
  awsIam = 'AWS_IAM',
  awsIamRole = 'AWS_IAM_ROLE'
}

export type DataSourceRegisterValues = {
  [DS_INSTANCE_NAME]?: string
  [DS_SCAN_CONDITIONS]?: string[]
  [DS_SCHEDULE]?: string
  [DS_OWNER]?: string
  [DS_DESCRIPTION]?: string
  [DS_ACCOUNT_NAME]?: string
  [DS_USER_NAME]: string
  [DS_PASSWORD]: string
  [DS_WAREHOUSE]?: string
  [DS_ROLE]?: string
  [DS_PORT]?: string
  [DS_DATABASE]?: string
  [DS_HOST]?: string
  [DS_SERVICE_NAME]?: string
  [DS_SOURCE_OF_TRUTH]?: boolean
  [DS_SOURCE_CAN_CREATE_ENTITIES]?: boolean
  [DS_LOCATION]?: string
  [DS_PURPOSE]?: string
  [DS_STAGE]?: string
  [DS_SUB_DOMAIN]?: string
  [DS_START_DATE]?: string
  [DS_TIME_PERIOD]?: string
  [DS_AUTH_METHOD]?: ZenDeskSupportCredentials['authMethod']
  [DS_EMAIL]?: string
  [DS_API_TOKEN]?: string
  [DS_ACCESS_TOKEN]?: string
  [DS_REFRESH_TOKEN]?: string
  [DS_CLIENT_ID]?: string
  [DS_CLIENT_SECRET]?: string
  [DS_TENANT_ID]?: string
  [DS_ENDPOINT]?: string
  [DS_GRANT_TYPE]?: string
  [DS_CLIENT_URL]?: string
  [DS_URL]?: string
  [DS_APP_ID]?: string
  [DS_DOMAIN_URL]?: string
  [DS_SSL]?: string
  [DS_ENTRA_AUTH]?: boolean
  [DS_SSL_CERTIFICATE]?: string
  [DS_SSL_KEY]?: string
  [DS_SSL_CA_CERTIFICATE]?: string
  [DS_DEFAULT_DATABASE]?: string
  [DS_AUTH_MECHANISM]?: string
  [DS_AWS_REGION]?: string
  [DS_AWS_ACCESS_KEY]?: string
  [DS_AWS_SECRET_KEY]?: string
  [DATA_SOURCE_TYPE]?: string
  [DS_SYNC_METHOD]?: string
  [DS_SERVER_VERSION]?: string
  [DS_BASE_DS_ID]?: string
  [DS_FOLDER_URI]?: string
  [DS_BASE_DS_SCAN_CONDITIONS]?: UnstructuredScanConditions[]
  [DS_HUBSPOT_HUB_ID]?: string
  [DS_HUBSPOT_CRENDENTIALS_TITLE]?: string
  [DS_ACCOUNT_JSON]?: string
  [DS_API_KEY]?: string
  [DS_DATA_CENTER]?: string
  [DS_DOCUMENT_LABEL_SETS]?: DocumentLabel[]
  [DS_SOT_CONFIG]?: SotOption[]
  [DS_QUERY_ENGINE]?: string
  [DS_WORKGROUP]?: string
  [DS_DOMAIN_NAME]?: string
  [DS_WORKSPACE_URL]?: string
  [DS_TOKEN]?: string
  [DS_IS_SANDBOX]?: boolean
}

export const dataSourcesStructuredTypes = [
  DATA_SOURCE_TYPES.mssql,
  DATA_SOURCE_TYPES.mySql,
  DATA_SOURCE_TYPES.snowflake,
  DATA_SOURCE_TYPES.postgreSql,
  DATA_SOURCE_TYPES.cosmosPostgres,
  DATA_SOURCE_TYPES.oracle
]

export type DataSourcesCredentials = {
  [DS_USER_NAME]: string
  [DS_PASSWORD]: string
  [DS_PORT]: string
  [DS_DATABASE]: string
  [DS_HOST]: string
  [DS_ACCOUNT_NAME]: string
  [DS_WAREHOUSE]: string
  [DS_SERVICE_NAME]: string
  [DS_SYNC_METHOD]?: string
  [DS_ENTRA_AUTH]?: boolean
}

export enum DS_AUTH_METHODS {
  apiToken = 'api_token',
  accessToken = 'access_token'
}
export type ZenDeskSupportCredentials = {
  [DS_SUB_DOMAIN]: string
  [DS_START_DATE]: string
  [DS_AUTH_METHOD]: {
    [DS_AUTH_METHOD]: DS_AUTH_METHODS
    [DS_EMAIL]?: string
    [DS_API_TOKEN]?: string
    [DS_ACCESS_TOKEN]?: string
  }
}

export type InnovaccerCredentials = {
  [DS_CLIENT_ID]: string
  [DS_CLIENT_SECRET]: string
  [DS_GRANT_TYPE]: string
  [DS_CLIENT_URL]: string
}

export type ServiceNowCredentials = {
  [DS_USER_NAME]: string
  [DS_START_DATE]: string
  [DS_PASSWORD]: string
  [DS_URL]: string
}
export type SalesForceCredentials = {
  [DS_CLIENT_ID]: string
  [DS_CLIENT_SECRET]: string
  [DS_CLIENT_URL]: string
  [DS_REFRESH_TOKEN]: string
}
export type MarketoCredentials = {
  [DS_START_DATE]: string
  [DS_CLIENT_ID]: string
  [DS_CLIENT_SECRET]: string
  [DS_DOMAIN_URL]: string
}
export type RemoteDataSourceCredentials = {
  [DATA_SOURCE_TYPE]: DATA_SOURCE_TYPES
}
export type BigQueryCredentials = {
  [DS_ACCOUNT_JSON]: string
}
export type LookerCredentials = {
  [DS_URL]: string
  [DS_CLIENT_ID]: string
  [DS_CLIENT_SECRET]: string
}

export type DatabricksCredentials = {
  [DS_WORKSPACE_URL]: string
  [DS_TOKEN]: string
}

export const OFFLINE_SCHEMA_IMPORT = 'OFFLINE_SCHEMA_IMPORT'
export type OfflineSchemaUploadCredentials = {
  [DS_SYNC_METHOD]: string
  [DS_SERVER_VERSION]: string
  [DS_BASE_DS_ID]: string
  [DS_FOLDER_URI]: string
  [DS_BASE_DS_SCAN_CONDITIONS]: UnstructuredScanConditions[]
}

export type DataSourceMixedConfiguration = {
  [DS_INSTANCE_NAME]: string
  [DS_LOCATION]?: string
  [DATA_SOURCE_TYPE]?: DATA_SOURCE_TYPES
  [DataSourcesConfigurations.snowflake]?: DataSourcesCredentials
  [DataSourcesConfigurations.mssql]?: DataSourcesCredentials
  [DataSourcesConfigurations.mysql]?: DataSourcesCredentials
  [DataSourcesConfigurations.postgresql]?: DataSourcesCredentials
  [DataSourcesConfigurations.oracle]?: DataSourcesCredentials
  [DataSourcesConfigurations.zenDeskSupport]?: ZenDeskSupportCredentials
  [DataSourcesConfigurations.innovaccer]?: InnovaccerCredentials
  [DataSourcesConfigurations.serviceNow]?: ServiceNowCredentials
  [DataSourcesConfigurations.salesForce]?: SalesForceCredentials
  [DataSourcesConfigurations.marketo]?: MarketoCredentials
  [DataSourcesConfigurations.remote]?: RemoteDataSourceCredentials
  [DataSourcesConfigurations.offline]?: OfflineSchemaUploadCredentials
  [DataSourcesConfigurations.bigQuery]?: BigQueryCredentials
  [DataSourcesConfigurations.looker]?: LookerCredentials
}

export type DataSourceMixed = {
  id: string
  name?: string
  numberOfObjects?: number
  numberOfEntities?: number
  status: 'Good'
  lastModifiedTime: string
  createdBy: string
  schedulePolicy?: string
  scanConditions?: StructuredScanConditions[]
  [DS_SOURCE_OF_TRUTH]?: boolean
  [DS_SOURCE_CAN_CREATE_ENTITIES]?: boolean
  sotConfiguration: SotOption[]
  [DS_DOCUMENT_LABEL_SETS]: DocumentLabel[]
  configuration: DataSourceMixedConfiguration
}

export type StructuredScanConditions = {
  database?: string
  schemaName?: string
  type?: ScanConditionsDataSourceTypes
  projectId?: string
  catalogName?: string
  databaseName?: string
  datasetId?: string
  streamName?: string
  syncMode?: string
  isSourceOfTruth?: boolean
  canCreateEntities?: boolean
  modelName?: string
  conditionalExpressions?: Array<{
    attributeName: string
    attributeValue: string
    operator: string
    action: string
  }>
}

export type DataSourceMixedParams = {
  description?: string
  createdBy: string
  schedulePolicy?: string
  scanConditions?: StructuredScanConditions[]
  configuration: {
    name: string
    [DATA_SOURCE_TYPE]: DATA_SOURCE_TYPES
    // documentLabelSets
    location?: string
    [DataSourcesConfigurations.snowflake]?: DataSourcesCredentials
    [DataSourcesConfigurations.mssql]?: DataSourcesCredentials
    [DataSourcesConfigurations.mysql]?: DataSourcesCredentials
    [DataSourcesConfigurations.postgresql]?: DataSourcesCredentials
    [DataSourcesConfigurations.oracle]?: DataSourcesCredentials
    [DataSourcesConfigurations.bigQuery]?: BigQueryCredentials
    [DataSourcesConfigurations.looker]?: LookerCredentials
    [DataSourcesConfigurations.databricks]?: DatabricksCredentials
  }
  cloudResourceId?: string
}

type DataSourceMixedParamsV2 = Omit<DataSourceMixedParams, 'scanConditions' | 'schedulePolicy'> & {
  scanConditions: StructuredScanConditionsV2[]
  humanReadableSchedulePolicy?: HumanReadableSchedulePolicy
}

type MultipartCSVUpload = {
  uploadId?: string
  ETag?: string
  fileUrl?: string
  uploadFileMessage?: string
  chunkUploadMessage?: string
  completeUploadMessage?: string
}

interface DataSourceMixedState {
  testConnectionStatus: STATES | ''
  testConnectionErrorCode?: string
  testConnectionDetails?: TestConnectionDetails
  instance?: DataSourceMixed
  settings?: DataSourceMixedSettings
  cloudResources?: ClouldResourceData[]
  isUnderProcessing: boolean
  sotOptions?: SotOption[]
  projects?: StructuredDataSourceProject[]
  warehouse?: StructuredDataSourceWarehouse[]
  catalogue?: StructuredDataSourceCatalogue[]
  createdDatasourceId?: string
  registerOrUpdateStatus?: DATASOURCE_CREATE_OR_UPDATE_STATUS
  multipartCSVUpload?: MultipartCSVUpload
}

export type DataSourceMixedRegisterParams = {
  configuration: DataSourceMixedParams
  isStructuredDs: boolean
  connectionStatsListType: ConnectionStatsTabs
}

export type DataSourceMixedRegisterParamsV2 = Omit<
  DataSourceMixedRegisterParams,
  'connectionStatsListType' | 'configuration'
> & {
  configuration: DataSourceMixedParamsV2
  // connectionStatsListType: ConnectionStatsTabsV2
}

export const isPostgres = (dataSourceType: DATA_SOURCE_TYPES) =>
  dataSourceType === DATA_SOURCE_TYPES.postgreSql ||
  dataSourceType === DATA_SOURCE_TYPES.cosmosPostgres ||
  dataSourceType === DATA_SOURCE_TYPES.auroraPostgres ||
  dataSourceType === DATA_SOURCE_TYPES.csvDatasource

export const isMySql = (dataSourceType: DATA_SOURCE_TYPES) =>
  dataSourceType === DATA_SOURCE_TYPES.mySql || dataSourceType === DATA_SOURCE_TYPES.auroraMySql

export const getConfigName = (dataSourceType: DATA_SOURCE_TYPES) => {
  if (dataSourceType === DATA_SOURCE_TYPES.mssql) return DataSourcesConfigurations.mssql
  if (isMySql(dataSourceType)) return DataSourcesConfigurations.mysql
  if (isPostgres(dataSourceType)) return DataSourcesConfigurations.postgresql
  if (dataSourceType === DATA_SOURCE_TYPES.redshift) return DataSourcesConfigurations.redshift
  if (dataSourceType === DATA_SOURCE_TYPES.glue) return DataSourcesConfigurations.glue
  if (dataSourceType === DATA_SOURCE_TYPES.databricks) return DataSourcesConfigurations.databricks
  if (dataSourceType === DATA_SOURCE_TYPES.snowflake) return DataSourcesConfigurations.snowflake
  if (dataSourceType === DATA_SOURCE_TYPES.zenDeskSupport)
    return DataSourcesConfigurations.zenDeskSupport
  if (dataSourceType === DATA_SOURCE_TYPES.innovaccer) return DataSourcesConfigurations.innovaccer
  if (dataSourceType === DATA_SOURCE_TYPES.serviceNow) return DataSourcesConfigurations.serviceNow
  if (dataSourceType === DATA_SOURCE_TYPES.remote) return DataSourcesConfigurations.remote
  if (dataSourceType === DATA_SOURCE_TYPES.hubspot) return DataSourcesConfigurations.hubspot
  if (dataSourceType === DATA_SOURCE_TYPES.bigQuery) return DataSourcesConfigurations.bigQuery
  if (dataSourceType === DATA_SOURCE_TYPES.looker) return DataSourcesConfigurations.looker
  if (dataSourceType === DATA_SOURCE_TYPES.adp) return DataSourcesConfigurations.adp
  if (dataSourceType === DATA_SOURCE_TYPES.iterable) return DataSourcesConfigurations.iterable
  if (dataSourceType === DATA_SOURCE_TYPES.salesForce) return DataSourcesConfigurations.salesForce
  if (dataSourceType === DATA_SOURCE_TYPES.salesForceMarketingCloud)
    return DataSourcesConfigurations.salesForceMarketingCloud
  if (dataSourceType === DATA_SOURCE_TYPES.dynamicsSales)
    return DataSourcesConfigurations.dynamicsSales
  if (dataSourceType === DATA_SOURCE_TYPES.marketo) return DataSourcesConfigurations.marketo
  if (dataSourceType === DATA_SOURCE_TYPES.mongoDb) return DataSourcesConfigurations.mongoDb
  if (dataSourceType === DATA_SOURCE_TYPES.cosmosMongoDb) return DataSourcesConfigurations.mongoDb
  if (dataSourceType === DATA_SOURCE_TYPES.cosmosNoSql) return DataSourcesConfigurations.cosmosNoSql
  if (dataSourceType === DATA_SOURCE_TYPES.dynamoDb) return DataSourcesConfigurations.dynamoDb
  if (dataSourceType === DATA_SOURCE_TYPES.confluence) return DataSourcesConfigurations.confluence
  if (dataSourceType === DATA_SOURCE_TYPES.oracle) return DataSourcesConfigurations.oracle
  if (dataSourceType === DATA_SOURCE_TYPES.sapHana) return DataSourcesConfigurations.sapHana
  if (dataSourceType === DATA_SOURCE_TYPES.auroraMySql) return DataSourcesConfigurations.mysql
  if (dataSourceType === DATA_SOURCE_TYPES.auroraPostgres)
    return DataSourcesConfigurations.postgresql
  if (dataSourceType === DATA_SOURCE_TYPES.csvDatasource)
    return DataSourcesConfigurations.postgresql
  return ''
}

export const ACTION_DS_REGISTER = 'dataSourceMixed/register'

type DSCreationSuccess = { data: string }

export const dataSourceStructuredRegister = createAsyncThunk(
  ACTION_DS_REGISTER,
  async (data: DataSourceMixedRegisterParams, { rejectWithValue }) => {
    // business logic: in case of structured datasource it should be one additional check of connected databases
    try {
      if (data.isStructuredDs && data.connectionStatsListType === ConnectionStatsTabs.selected) {
        await apiService.postConnectionStats(data.configuration)
      }
    } catch (error) {
      return rejectWithValue({ statusMessage: 'datasources.datasource.connectionStats.error.text' })
    }

    try {
      const result = await apiService.postDatasource(data.configuration)
      return {
        statusMessage: 'datasources.datasource.create.success.text',
        data: result.data
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return rejectWithValue({ statusMessage: 'datasources.datasource.create.error.text' })
    }
  }
)

export const ACTION_DS_REGISTER_V2 = 'dataSourceMixed/structuredV2'

export const dataSourceStructuredRegisterV2 = createAsyncThunk(
  ACTION_DS_REGISTER_V2,
  async (data: DataSourceMixedRegisterParamsV2, { rejectWithValue }) => {
    // ! FIXME: check if this is necessary
    // ! business logic: in case of structured datasource it should be one additional check of connected databases
    // try {
    //   if (data.isStructuredDs && data.connectionStatsListType === ConnectionStatsTabs.selected) {
    //     await apiService.postConnectionStats(data.configuration)
    //   }
    // } catch (error) {
    //   return rejectWithValue({ statusMessage: 'datasources.datasource.connectionStats.error.text' })
    // }

    try {
      const result = await apiService.postDatasourceV2(data.configuration)
      return {
        statusMessage: 'datasources.datasource.create.success.text',
        data: result.data
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return rejectWithValue({ statusMessage: 'datasources.datasource.create.error.text' })
    }
  }
)

export const ACTION_DATA_SOURCE_PARTIAL_UPDATE_V2 = 'datasource/update/partialV2'
export const updateDatasourceFieldsV2 = createAsyncThunk(
  ACTION_DATA_SOURCE_PARTIAL_UPDATE_V2,
  async ({ datasourceId, datasource }: { datasourceId: string; datasource: DataSourceV2 }) =>
    await apiService.patchDatasourceByIdV2(datasourceId, datasource)
)

export type DataSourceMixedSettings = DataSourceMixed & {
  databases: Array<{
    databaseId: string
    databaseName: string
  }>
  schemas?: string[]
}
export type DataSourceMixedSettingsParams = {
  [DATA_SOURCE_ID]: string
  isStructured: boolean
}
export type CloudResourceMetadata = {
  hostname?: string
  port?: string
  domain?: string
  url?: string
}
export type ClouldResourceData = {
  datasourceType: string
  region?: string
  resourceMetadata?: CloudResourceMetadata
}

export const ACTION_DS_SETTINGS = 'dataSourceMixed/settings'
export const fetchDataSourceMixedSettings = createAsyncThunk(
  ACTION_DS_SETTINGS,
  async (params: DataSourceMixedSettingsParams) => {
    const resultRaw = await graphqlService.execute(queryDataSourceMixedSettings(params))
    return mapQueryDataSourceMixedSettings(resultRaw)
  }
)

export const ACTION_FETCH_CLOUD_RESOURCES_BY_ID = 'dataSourceMixed/cloudResourcesById'
export const fetchCloudResourcesById = createAsyncThunk(
  ACTION_FETCH_CLOUD_RESOURCES_BY_ID,
  async (cloudResourceId: string) => {
    const resultRaw = await graphqlService.execute(queryCloudResourcesById(cloudResourceId))
    return mapQueryCloudResourcesById(resultRaw)
  }
)

//this
export const ACTION_STRUCTURED_TEST_CONNECTION = 'dataSourceMixed/test-connection'
export const dataSourceStructuredTestConnection = createAsyncThunk(
  ACTION_STRUCTURED_TEST_CONNECTION,
  async (params: DataSourceMixedParams) => {
    const { scanConditions } = params
    return await apiService.testConnection({
      ...params,
      ...(scanConditions ? { scanConditions } : {})
    })
  }
)

export type DataSourceProcessingParams = { [DATA_SOURCE_ID]: string }
export const ACTION_DS_PROCESSING_STATUS = 'dataSourceMixed/processingStatus'
export const fetchDataSourceProcessingStatus = createAsyncThunk(
  ACTION_DS_PROCESSING_STATUS,
  async (params: DataSourceProcessingParams) => {
    const resultRaw = await graphqlService.execute(queryDataSourceProcessingStatus(params))
    return mapQueryDataSourceProcessingStatus(resultRaw)
  }
)
// BigQuery && Looker
export type StructuredDataSourceProject = {
  type: ScanConditionsDataSourceTypes
  projectId: string
  items: string[]
}

// Databricks
export type StructuredDataSourceWarehouse = {
  warehouse_id: string
  warehouse_name: string
}
export type StructuredDataSourceCatalogue = {
  type: ScanConditionsDataSourceTypes
  catalogName: string
  databases: string[]
}

export const ACTION_BIG_QUERY_PROJECTS = 'dataSourceMixed/bigQueryProjects'
export const fetchBigQueryProjects = createAsyncThunk(
  ACTION_BIG_QUERY_PROJECTS,
  async (params: ConnectionStatsParams): Promise<StructuredDataSourceProject[]> => {
    const result = await apiService.postBigQueryProjects(params)
    return result.map((project) => ({
      type: ScanConditionsDataSourceTypes.bigquery,
      projectId: project.projectId,
      items: project.datasets.map(({ datasetId }) => datasetId)
    }))
  }
)

export const ACTION_LOOKER_PROJECTS = 'dataSourceMixed/lookerProjects'
export const fetchLookerProjects = createAsyncThunk(
  ACTION_LOOKER_PROJECTS,
  async (params: ConnectionStatsParams): Promise<StructuredDataSourceProject[]> => {
    const result = await apiService.postLookerProjects(params)
    return result.map((project) => ({
      type: ScanConditionsDataSourceTypes.looker,
      projectId: project.project_name,
      items: project.models.map(({ model_name }) => model_name)
    }))
  }
)

export const ACTION_DATABRICKS_CATALOGUE = 'dataSourceMixed/databricksCatalogue'
export const fetchDatabricksCatalogue = createAsyncThunk(
  ACTION_DATABRICKS_CATALOGUE,
  async (
    params: ConnectionStatsParams
  ): Promise<{
    warehouse: StructuredDataSourceWarehouse[]
    catalogue: StructuredDataSourceCatalogue[]
  }> => {
    const result = await apiService.postDatabricksCatalogue(params)
    return {
      warehouse: result.warehouse,
      catalogue: result.database.map((db) => ({
        type: ScanConditionsDataSourceTypes.databricks,
        catalogName: db.catalog_name,
        databases: db.databases
      }))
    }
  }
)

// Source of truth options
export type SotOptionParams = {
  [DATA_SOURCE_ID]?: string
  [DATA_SOURCE_TYPE]?: DATA_SOURCE_TYPES
}
export type SotOption = {
  streamName: string
  canCreateEntities?: boolean
}
export const ACTION_FETCH_DS_SOT_OPTIONS = 'dataSources/sotOptions'
export const fetchSotOptions = createAsyncThunk(
  ACTION_FETCH_DS_SOT_OPTIONS,
  async (params: SotOptionParams) => {
    const resultRaw = await graphqlService.execute(querySotOptions(params))
    return mapQuerySotOptions(resultRaw)
  }
)

export const ACTION_UPLOAD_CSV_CHUNK = 'csvMultipartUpload/uploadChunk'
export const uploadCSVChunk = createAsyncThunk(
  ACTION_UPLOAD_CSV_CHUNK,
  async (params: UploadCSVChunkParams) => {
    return await apiService.uploadCSVChunks(params)
  }
)
export type UploadCSVChunkParams = {
  data: string
  uploadId: string
  partNumber: number
  objectName: string
}
export interface ChunkResponse {
  message: string
  ETag: string
}

export const ACTION_COMPLETE_CSV_MULTIPART_UPLOAD = 'csvMultipartUpload/completeUpload'
export const completeCSVMultipartUpload = createAsyncThunk(
  ACTION_COMPLETE_CSV_MULTIPART_UPLOAD,
  async (params: CompleteCSVMultipartUploadParams) => {
    return await apiService.completeCSVMultipartUpload(params)
  }
)
export type CompleteCSVMultipartUploadParams = {
  objectName: string
  uploadId: string
  parts: Array<{
    PartNumber: number
    ETag: string
  }>
}
export interface CompleteUploadResponse {
  fileUrl: string
  message: string
}

export const CHUNK_SIZE = 8 * 1024 * 1024 // 8MB
const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve((reader.result as string).split(',')[1])
    reader.onerror = reject
  })
}
export type InitiateCSVMultipartUploadParams = {
  fileName: string
}
export interface UploadResponse {
  uploadId: string
  message: string
}
export const ACTION_UPLOAD_CSV_FILE = 'csvMultipartUpload/upload'
export const uploadCSVFile = createAsyncThunk(
  ACTION_UPLOAD_CSV_FILE,
  async ({ file }: { file: File }, { dispatch }) => {
    const startResponse = await apiService.startCSVMultipartUpload({ fileName: file.name })
    const uploadId = startResponse.uploadId
    const fileSize = file.size
    const parts: Array<{ PartNumber: number; ETag: string }> = []

    if (fileSize <= CHUNK_SIZE) {
      const base64Data = await convertFileToBase64(file)
      const chunkResponse = await dispatch(
        uploadCSVChunk({
          data: base64Data as string,
          objectName: file.name,
          uploadId: uploadId,
          partNumber: 1
        })
      )
      const uploadedETag = (chunkResponse?.payload as ChunkResponse)?.ETag
      if (uploadedETag) parts.push({ PartNumber: 1, ETag: uploadedETag })
    } else {
      const chunks = Math.ceil(fileSize / CHUNK_SIZE)
      for (let i = 0; i < chunks; i++) {
        const chunk = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE)
        const base64Chunk = await convertFileToBase64(chunk)

        const chunkResponse = await dispatch(
          uploadCSVChunk({
            data: base64Chunk as string,
            objectName: file.name,
            uploadId: uploadId,
            partNumber: i + 1
          })
        )
        const uploadedETag = (chunkResponse?.payload as ChunkResponse)?.ETag
        if (uploadedETag) parts.push({ PartNumber: i + 1, ETag: uploadedETag })
      }
    }

    const completeUploadResponse = await dispatch(
      completeCSVMultipartUpload({ objectName: file.name, uploadId: uploadId, parts })
    )
    return {
      uploadId,
      message: startResponse.message,
      fileUrl: (completeUploadResponse.payload as CompleteUploadResponse).fileUrl
    }
  }
)

const initialState: DataSourceMixedState = {
  testConnectionStatus: '',
  isUnderProcessing: true,
  multipartCSVUpload: {
    uploadId: '',
    ETag: '',
    fileUrl: '',
    uploadFileMessage: '',
    chunkUploadMessage: '',
    completeUploadMessage: ''
  }
}

const dataSourceMixedSlice = createSlice({
  name: 'dataSourceMixed',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetCSVUpload: (state) => {
      state.multipartCSVUpload = initialState.multipartCSVUpload
    },
    resetTestConnection: (state) => {
      state.testConnectionStatus = initialState.testConnectionStatus
      state.testConnectionErrorCode = undefined
    },
    resetCreatedDatasourceId: (state) => {
      state.createdDatasourceId = initialState.createdDatasourceId
    }
  },
  extraReducers: (builder) => {
    builder.addCase(dataSourceStructuredTestConnection.pending, (state) => {
      state.testConnectionStatus = STATES.pending
      state.testConnectionDetails = undefined
    })
    builder.addCase(dataSourceStructuredTestConnection.fulfilled, (state, action) => {
      state.testConnectionStatus = STATES.success
      state.testConnectionDetails = action.payload.details
      state.testConnectionErrorCode = undefined
    })
    builder.addCase(dataSourceStructuredTestConnection.rejected, (state, error) => {
      state.testConnectionStatus = STATES.failed
      state.testConnectionErrorCode = error.error.code
    })
    builder.addCase(fetchDataSourceMixedSettings.fulfilled, (state, { payload }) => {
      state.settings = payload
    })
    builder.addCase(fetchCloudResourcesById.fulfilled, (state, { payload }) => {
      state.cloudResources = payload
    })
    builder.addCase(fetchDataSourceProcessingStatus.fulfilled, (state, { payload }) => {
      state.isUnderProcessing = payload
    })
    builder.addCase(fetchBigQueryProjects.fulfilled, (state, { payload }) => {
      state.projects = payload
    })
    builder.addCase(fetchLookerProjects.fulfilled, (state, { payload }) => {
      state.projects = payload
    })
    builder.addCase(fetchDatabricksCatalogue.fulfilled, (state, { payload }) => {
      state.warehouse = payload.warehouse
      state.catalogue = payload.catalogue
    })
    builder.addCase(fetchSotOptions.fulfilled, (state, { payload }) => {
      state.sotOptions = payload
    })
    builder.addCase(dataSourceStructuredRegister.fulfilled, (state, { payload }) => {
      const successPayload = payload as DSCreationSuccess
      if (successPayload?.data) {
        state.createdDatasourceId = successPayload.data
      }
    })
    builder.addCase(dataSourceStructuredRegisterV2.fulfilled, (state, { payload }) => {
      const successPayload = payload as DSCreationSuccess
      if (successPayload?.data) {
        state.createdDatasourceId = successPayload.data
      }
    })
    builder.addCase(updateDatasourceFieldsV2.fulfilled, (state) => {
      state.registerOrUpdateStatus = DATASOURCE_CREATE_OR_UPDATE_STATUS.updated
    })
    builder.addCase(updateDatasourceFieldsV2.rejected, (state) => {
      state.registerOrUpdateStatus = DATASOURCE_CREATE_OR_UPDATE_STATUS.updateFailed
    })
    builder.addCase(uploadCSVFile.fulfilled, (state, { payload }) => {
      state.multipartCSVUpload = {}
      state.multipartCSVUpload.uploadId = payload.uploadId
      state.multipartCSVUpload.fileUrl = payload.fileUrl
      state.multipartCSVUpload.uploadFileMessage = payload.message
    })
    builder.addCase(uploadCSVChunk.fulfilled, (state, { payload }) => {
      state.multipartCSVUpload = state.multipartCSVUpload || {}
      state.multipartCSVUpload.ETag = payload.ETag
      state.multipartCSVUpload.chunkUploadMessage = payload.message
    })
    builder.addCase(completeCSVMultipartUpload.fulfilled, (state, { payload }) => {
      state.multipartCSVUpload = state.multipartCSVUpload || {}
      state.multipartCSVUpload.completeUploadMessage = payload.message
    })
  }
})

export const {
  resetState,
  resetTestConnection,
  resetCSVUpload,
  resetCreatedDatasourceId
} = dataSourceMixedSlice.actions

export default dataSourceMixedSlice.reducer

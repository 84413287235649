import apiService from '../../services/api/apiService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type DateRange = {
  startDate?: string
  endDate?: string
}

export type CookiesPerCategory = {
  categoryName: string
  cookieCount: number
  domains: {
    [key: string]: number
  }
}

export type ScanHistoryItem = {
  domainId: string
  domainName?: string
  timestamp: string
  cookies: number
}

export type ConsentsByDomain = {
  domainId?: string
  count: number
  domainName: string
  additionalData?: {
    label: string
    value: number
  }[]
}

export type ConsentsByRegion = {
  [region: string]: {
    consentCount: number
    consentsByDomain: {
      [domain: string]: number
    }
  }
}

export type ConsentsByCategory = {
  [key: string]: { optInCount: number; optOutCount: number }
}

type CookieConsentState = {
  overview: {
    categoryCount?: number
    cookieCount?: number
    consentLogsCount?: number
    scansCount?: number
    consentsByCategory?: ConsentsByCategory
    cookiesPerCategory?: CookiesPerCategory[]
    scanHistory?: ScanHistoryItem[]
    consentsByDomain?: ConsentsByDomain[]
    consentsByRegion?: ConsentsByRegion
  }
}

export const ACTION_FETCH_COOKIE_DISTRIBUTION = 'cookieConsent/fetchCookieDistribution'
export const fetchCookieDistribution = createAsyncThunk(
  ACTION_FETCH_COOKIE_DISTRIBUTION,
  async () => await apiService.getCookieDistribution()
)

export const ACTION_FETCH_COOKIE_DISTRIBUTION_PER_DOMAIN =
  'cookieConsent/fetchCookieDistributionPerDomain'
export const fetchCookieDistributionPerDomain = createAsyncThunk(
  ACTION_FETCH_COOKIE_DISTRIBUTION_PER_DOMAIN,
  async (domainId: string) => await apiService.getCookieDistribution(domainId)
)

export type CookieConsentScanStatsParams = {
  domainId?: string
  page?: number
  limit?: number
  sortBy?: string
}
export const ACTION_FETCH_COOKIE_CONSENT_SCAN_STATS = 'cookieConsent/fetchCookieConsentScanStats'
export const fetchCookieConsentScanStats = createAsyncThunk(
  ACTION_FETCH_COOKIE_CONSENT_SCAN_STATS,
  async (params?: CookieConsentScanStatsParams) => {
    return await apiService.getCookieConsentScanStats(params)
  }
)
export type CookieConsentDistributionParams = {
  domainId?: string
  duration?: number
}
export const ACTION_FETCH_COOKIE_CONSENT_CATEGORY = 'cookieConsent/fetchCookieConsentMetrics'
export const fetchCookieConsentByCategory = createAsyncThunk(
  ACTION_FETCH_COOKIE_CONSENT_CATEGORY,
  async (params: CookieConsentDistributionParams) =>
    await apiService.getCookieConsentByCategory(params)
)

export const ACTION_FETCH_COOKIE_CONSENT_DOMAINS = 'cookieConsent/fetchCookieConsentByDomains'
export const fetchCookieConsentByDomains = createAsyncThunk(
  ACTION_FETCH_COOKIE_CONSENT_DOMAINS,
  async (params: CookieConsentDistributionParams) =>
    await apiService.getCookieConsentByDomain(params)
)

export const ACTION_GET_COOKIE_CONSENTS_BY_REGION = 'cookieConsent/getCookieConsentsByRegion'
export const fetchCookieConsentsByRegion = createAsyncThunk(
  ACTION_GET_COOKIE_CONSENTS_BY_REGION,
  async (params: CookieConsentDistributionParams) => {
    return await apiService.getCookiesConsentByRegion(params)
  }
)

const mapConsentsByCategory = (
  payload: any
): { cookieCount: number; cookiesPerCategory: CookiesPerCategory[] } => {
  const cookiesPerCategory: CookiesPerCategory[] = []
  const cookieDistributionByCategories = payload?.cookieDistributionByCategories || []
  const totalCount = payload?.totalCookieCount
  cookieDistributionByCategories.forEach((category) => {
    const cookieCount = category?.cookieCount ? Number(category.cookieCount) : 0
    cookiesPerCategory.push({
      categoryName: category?.categoryName || '',
      cookieCount,
      domains: category?.domains || []
    })
  })

  return { cookieCount: totalCount, cookiesPerCategory }
}

const mapConsentsByDomain = (domainWiseData: any): ConsentsByDomain[] => {
  const result: ConsentsByDomain[] = []

  for (const domainId in domainWiseData) {
    const currentDomainData = domainWiseData[domainId]

    const currentResult: ConsentsByDomain = {
      domainId,
      domainName: currentDomainData.domainName,
      count: currentDomainData.consentCount,
      additionalData: Object.entries(currentDomainData.eventTypedistribution).map(
        ([label, value]) =>
          ({
            label,
            value: value
          } as { label: string; value: number })
      )
    }
    result.push(currentResult)
  }
  return result
}

const initialState: CookieConsentState = {
  overview: {}
}

const cookieConsentSlice = createSlice({
  name: 'cookieConsent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCookieDistribution.fulfilled, (state, { payload }) => {
      const { cookieCount, cookiesPerCategory } = mapConsentsByCategory(payload)
      const categoryCount = cookiesPerCategory.length
      state.overview.categoryCount = categoryCount
      state.overview.cookieCount = cookieCount
      state.overview.cookiesPerCategory = cookiesPerCategory
    })
    builder.addCase(fetchCookieDistributionPerDomain.fulfilled, (state, { payload }) => {
      const { cookiesPerCategory } = mapConsentsByCategory(payload)
      state.overview.cookiesPerCategory = cookiesPerCategory
    })
    builder.addCase(fetchCookieConsentScanStats.fulfilled, (state, { payload }) => {
      const { data = [], totalCount = 0 } = payload

      state.overview.scansCount = totalCount

      const scanHistory: ScanHistoryItem[] = [...(state.overview.scanHistory || [])]
      data.forEach(({ domainId, domainName, updatedAt, scanResult }) => {
        scanHistory.push({
          domainId,
          domainName,
          timestamp: updatedAt,
          cookies: scanResult?.cookies || 0
        })
      })
      state.overview.scanHistory = scanHistory
    })
    builder.addCase(fetchCookieConsentByDomains.fulfilled, (state, { payload }) => {
      const { domainWiseData = {} } = payload || {}
      state.overview.consentsByDomain = mapConsentsByDomain(domainWiseData)
    })
    builder.addCase(fetchCookieConsentByCategory.fulfilled, (state, { payload }) => {
      state.overview.consentsByCategory = payload?.consentsbycategory || {}
    })
    builder.addCase(fetchCookieConsentsByRegion.fulfilled, (state, { payload }) => {
      state.overview.consentsByRegion = payload as ConsentsByRegion
    })
  }
})

export default cookieConsentSlice.reducer

import {
  mapQueryBLOBS,
  mapQueryBLOBSGrouped,
  mapQueryBlocksList,
  mapQueryObjectType,
  mapQueryStructuredBLOBS,
  mapQueryUnstructuredBLOBSCount,
  mapQueryUnstructuredBLOBSList,
  queryBLOBS,
  queryBLOBSGrouped,
  queryBlocksList,
  queryObjectType,
  queryStructuredBLOBS,
  queryUnstructuredBLOBSCount,
  queryUnstructuredBLOBSList
} from './queries'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { FilterParams } from '../../interfaces'
import graphqlService from '../../services/graphqlService'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface IBlob {
  attribuesCount: number
  entitiesCount: number
  attribueInstancesCount: number
  name: string
  sharedWhen?: string
  blobsCount?: number
  blobsWithPIICount?: number
  id?: string
  objectLink?: string
  metadataId?: string
  internalName?: string
  sensitivity?: string
  rowCount?: number
  attributeInstanceCount?: number
  // for structured blobs
  tableName?: string
  databaseName?: string
  qualifiedName?: string
  blobScanStatus?: string // get enum values from Kaif
  blobCellCount?: number
  // ? for Salesforce Marketing Cloud
  dataExtensionName?: string
  businessUnitId?: string
  blobFieldName?: string
  primaryKey?: string
  folderPath?: string | null
  dataSourceName?: string
}
export interface IBlock {
  rowId?: string
  attributes: string[]
}
export type BlocksListSettings = {
  list?: IBlock[]
  total?: number
  sort: SortParams
}

interface IBlobState {
  list?: IBlob[]
  cards?: IBlob[]
  blocks: BlocksListSettings
  total?: number
  objectType?: string
  sort: SortParams
  attributes?: IBlob[]
}

export const initialState: IBlobState = {
  sort: defaultSortParams,
  blocks: { sort: defaultSortParams }
}
export interface IGetBLOBSParams {
  datasourceId?: string
  page?: number
  pageSize?: number
  fileCategory?: string
  filters: FilterParams
  isStructured?: boolean
  blobId?: string
  searchQuery?: string
}
export const ACTION_BLOBS_CARDS_FETCH = 'blobs/cards'
export const fetchBLOBSGrouped = createAsyncThunk(
  ACTION_BLOBS_CARDS_FETCH,
  async (params: IGetBLOBSParams) => {
    const resultRaw = await graphqlService.execute(queryBLOBSGrouped(params))
    return mapQueryBLOBSGrouped(resultRaw)
  }
)
export const ACTION_BLOBS_LIST_FETCH = 'blobs/list'
export const fetchBLOBSList = createAsyncThunk(
  ACTION_BLOBS_LIST_FETCH,
  async (params: IGetBLOBSParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    if (params.isStructured) {
      const resultRaw = await graphqlService.execute(queryStructuredBLOBS(queryParams))
      return mapQueryStructuredBLOBS(resultRaw)
    } else {
      const resultRaw = await graphqlService.execute(queryBLOBS(queryParams))
      return mapQueryBLOBS(resultRaw)
    }
  }
)

export const ACTION_UNSTRUCTURED_BLOBS_LIST_FETCH = 'unstructured/blobs/list'
export const fetchUnstructuredBLOBSList = createAsyncThunk(
  ACTION_UNSTRUCTURED_BLOBS_LIST_FETCH,
  async (params: IGetBLOBSParams) => {
    const resultRaw = await graphqlService.execute(queryUnstructuredBLOBSList(params))
    return mapQueryUnstructuredBLOBSList(resultRaw)
  }
)

export const ACTION_UNSTRUCTURED_BLOBS_COUNT_FETCH = 'unstructured/blobs/count'
export const fetchUnstructuredBLOBSCount = createAsyncThunk(
  ACTION_UNSTRUCTURED_BLOBS_COUNT_FETCH,
  async (params: IGetBLOBSParams) => {
    const resultRaw = await graphqlService.execute(queryUnstructuredBLOBSCount(params))
    return mapQueryUnstructuredBLOBSCount(resultRaw)
  }
)

export interface IGetBlocksParams {
  page?: number
  blobId?: string
  filters?: { [key: string]: string[] }
}
export const ACTION_BLOCKS_LIST_FETCH = 'blobs/blocks'
export const fetchBlocksList = createAsyncThunk(
  ACTION_BLOCKS_LIST_FETCH,
  async (params: IGetBlocksParams) => {
    const resultRaw = await graphqlService.execute(queryBlocksList(params))
    return mapQueryBlocksList(resultRaw)
  }
)

export const ACTION_BLOBS_OBJECT_TYPE_FETCH = 'blobs/objectType'
export const fetchBLOBSObjectType = createAsyncThunk(
  ACTION_BLOBS_OBJECT_TYPE_FETCH,
  async (datasourceId: string) => {
    const resultRaw = await graphqlService.execute(queryObjectType(datasourceId))
    return mapQueryObjectType(resultRaw)
  }
)

const blobSlice = createSlice({
  name: 'blobs',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state.sort = getSortDirection(state.sort, payload.column)
    },
    resetList: (state) => {
      state.cards = initialState.cards
      state.list = initialState.list
      state.total = initialState.total
      state.sort = initialState.sort
      state.attributes = initialState.attributes
    },
    resetObjectType: (state) => {
      state.objectType = initialState.objectType
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBLOBSGrouped.fulfilled, (state, { payload }) => {
      state.cards = payload.list
      state.total = payload.total
    })
    builder.addCase(fetchBLOBSList.fulfilled, (state, { payload }) => {
      state.list = payload.list
      state.total = payload.total
    })
    builder.addCase(fetchUnstructuredBLOBSList.fulfilled, (state, { payload }) => {
      state.list = payload.list
    })
    builder.addCase(fetchUnstructuredBLOBSCount.fulfilled, (state, { payload }) => {
      state.total = payload.total
    })
    builder.addCase(fetchBLOBSObjectType.fulfilled, (state, { payload }) => {
      state.objectType = payload.parentObjectType
    })
    builder.addCase(fetchBlocksList.fulfilled, (state, { payload }) => {
      state.blocks.list = payload.list
      state.blocks.total = payload.total
    })
  }
})

export const { setSort, resetList, resetObjectType } = blobSlice.actions

export default blobSlice.reducer

import {
  AccessControlEmployeeType,
  AccessControlObject,
  AccessControlObjectSummary,
  AccessControlGroupsWidget,
  AccessControlIdpWidget,
  AccessControlObjectsWithImpactedAccess,
  AccessControlUserDatasource,
  AccessControlUserDetailsOverview,
  AccessControlUserEntities,
  AccessControlUserGroupByDepartmentItem,
  AccessControlUsersSummary,
  AccessControlUsersWidget,
  IdpStatus,
  IGetAccessControlUsersFilterParams,
  ObjectsImpactedAccessTypeFilter,
  DataSensitivityLevels,
  AccessControlObjectCard,
  AccessControlEntityWidget,
  Access360Widget,
  AccessControlGlobalObjectCard,
  AccessControlUserEntityCard,
  AccessControlEntityAccessWidget,
  AccessControlObjectsWithImpactedAccessByDept,
  AccessControlUserAttributesWidget,
  AccessControlUserAttributeCard,
  AccessControlCrossDepartmentAccessWidget,
  AccessControlAccessibleWidget,
  AccessControlFilters,
  AccessControlObjectCardBySensitivity,
  IGetAccessControlGroupsFilterParams,
  IdentityProviderParams,
  DirectoryService,
  AccessControlGroupDetailsOverview,
  AlertsOverviewWidget,
  TestConnectionParams,
  AccessControlGroupCard,
  accessControlMemberTypes
} from './types'
import { AccessControlObjectsParams, AccessControlUserAttributesParams } from './accessControlSlice'
import { DirectoryOptions } from './utils/constants'
import {
  ALERT_STATUS,
  ALERT_STATUS_API_MAP,
  LIMIT_DEFAULT,
  POLICY_TYPES,
  SENSITIVE_LABEL,
  SEVERITY_LEVEL
} from '../../constants'
import { getAfterCursor } from '../../utils/graphqlUtil'
import { PageInfo } from '../../services/graphqlSchemaTypes'
import { DataSource } from '../../services/api/apiTypes'
import { gql } from 'graphql-request'

export const getUserFiltersString = (
  params: IGetAccessControlUsersFilterParams,
  { hasPagination = false }: { hasPagination?: boolean } = {}
) => {
  const {
    page = 1,
    datasourceId,
    datasourceIds,
    dataPrivilegeLevels,
    employeeType,
    departmentName,
    entityTypes,
    attributeIds,
    objectIds,
    hasEntityAccess,
    searchText,
    isDirectMembership,
    hasMembers
  } = params
  let filterPagination = ''
  if (hasPagination) {
    const cursor = getAfterCursor(page, LIMIT_DEFAULT)
    filterPagination = `first: ${LIMIT_DEFAULT}, after: "${cursor}", `
  }
  let filterStr = ``
  if (dataPrivilegeLevels?.length) {
    filterStr += `sensitivity: [${dataPrivilegeLevels}], `
  }
  if (employeeType) {
    filterStr += `employeeType: ${employeeType}`
  }
  if (departmentName) {
    filterStr += ` ,departmentName: "${departmentName}"`
  }
  if (datasourceId) {
    filterStr += ` ,datasourceId: "${datasourceId}"`
  }
  if (datasourceIds?.length) {
    filterStr += ` ,datasourceIds: ${JSON.stringify(datasourceIds)}`
  }
  if (entityTypes?.length) {
    filterStr += ` ,entityTypeIds: ${JSON.stringify(entityTypes)}`
  }
  if (attributeIds?.length) {
    filterStr += ` ,attributeTypeIds: ${JSON.stringify(attributeIds)}`
  }

  if (objectIds?.length) {
    filterStr += ` ,objectIds: ${JSON.stringify(objectIds)}`
  }
  if (hasEntityAccess !== undefined) {
    filterStr += ` ,hasEntityAccess: ${hasEntityAccess}`
  }
  if (isDirectMembership !== undefined) {
    filterStr += ` ,isDirectMembership: ${isDirectMembership}`
  }

  if (searchText !== undefined && searchText !== '') {
    filterStr += ` ,search: "${searchText}"`
  }

  if (!filterStr && !filterPagination) {
    return ''
  }
  if (hasMembers !== undefined) {
    filterStr += ` ,hasMembers: ${hasMembers}`
  }

  return `${filterPagination} ${filterStr}`
}

export const getGroupFiltersString = (
  params: IGetAccessControlGroupsFilterParams,
  { hasPagination = false }: { hasPagination?: boolean } = {}
) => {
  const { page = 1, isDirect } = params

  let filterPagination = ''
  if (hasPagination) {
    const cursor = getAfterCursor(page, LIMIT_DEFAULT)
    filterPagination = `first: ${LIMIT_DEFAULT}, after: "${cursor}", `
  }

  let filterStr = ``
  if (isDirect !== undefined) {
    filterStr += ` ,isDirect: ${isDirect}`
  }

  if (!filterStr && !filterPagination) {
    return ''
  }

  return `${filterPagination} ${filterStr}`
}

export const getObjectFiltersString = (
  params: AccessControlObjectsParams,
  { hasPagination = false }: { hasPagination?: boolean } = {}
) => {
  const {
    page = 1,
    hasOpenAccess,
    hasExcessiveAccess,
    hasCrossDepartmentsAccess,
    sensitivity,
    datasourceIds,
    attributeIds,
    accessControlUserIds,
    accessControlGroupIds,
    isOwner,
    departmentName
  } = params

  let filterPagination = ''
  if (hasPagination) {
    const cursor = getAfterCursor(page, LIMIT_DEFAULT)
    filterPagination = `first: ${LIMIT_DEFAULT}, after: "${cursor}", `
  }

  let filterStr = ``

  if (hasOpenAccess) {
    filterStr += `, hasOpenAccess: ${hasOpenAccess}`
  }
  if (hasExcessiveAccess) {
    filterStr += `, hasExcessiveAccess: ${hasExcessiveAccess}`
  }
  if (hasCrossDepartmentsAccess) {
    filterStr += `, hasCrossDepartmentAccess: ${hasCrossDepartmentsAccess}`
  }
  if (sensitivity) {
    filterStr += `, sensitivity: [${sensitivity.map((lvl) => lvl.toUpperCase())}]`
  }
  if (datasourceIds?.length) {
    filterStr += `, datasourceIds: ${JSON.stringify(datasourceIds)}`
  }
  if (attributeIds?.length) {
    filterStr += `, attributeTypeIds: ${JSON.stringify(attributeIds)}`
  }
  if (accessControlUserIds?.length) {
    filterStr += `, accessControlUserIds: ${JSON.stringify(accessControlUserIds)}`
  }
  if (accessControlGroupIds?.length) {
    filterStr += `, accessControlGroupIds: ${JSON.stringify(accessControlGroupIds)}`
  }
  if (isOwner) {
    filterStr += `, isOwner: ${isOwner}`
  }
  if (departmentName) {
    filterStr += `, departmentName: "${params.departmentName}"`
  }

  if (!filterStr && !filterPagination) {
    return ''
  }

  return `${filterPagination} ${filterStr}`
}

export const queryUserCardsGroupedByDepartments = (params: IGetAccessControlUsersFilterParams) => {
  const filters = getUserFiltersString(params, { hasPagination: true })

  return gql`
    {

      accessControlUsersGroupedByDept(${filters}) {
        count
        edges {
          node {
            departmentName
            memberUsers {
              count
            }
            dataPrivilegedMembers: memberUsers(sensitivity:[${DataSensitivityLevels.High}]) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryUserCardsGroupedByDepartments = (
  raw: any
): { cards: AccessControlUserGroupByDepartmentItem[]; total: number } => {
  const { accessControlUsersGroupedByDept } = raw
  const cards = accessControlUsersGroupedByDept.edges.map(({ node }) => ({
    departmentName: node?.departmentName,
    memberCount: node?.memberUsers?.count || 0,
    dataPrivilegeMemberCount: node?.dataPrivilegedMembers?.count || 0
  }))

  return {
    cards,
    total: accessControlUsersGroupedByDept?.count || 0
  }
}

export const queryUsers = (params: IGetAccessControlUsersFilterParams) => {
  const { entityId, datasourceId } = params
  const filters = getUserFiltersString(params, { hasPagination: true })

  const filterDatasource = datasourceId ? ` ,datasourceId: "${datasourceId}"` : ``

  const filterDatasourceIds = datasourceId ? ` ,datasourceIds: ["${datasourceId}"]` : ``
  const accessModesFilter = params.accessModes?.length
    ? `, accessModes: [${params.accessModes}]`
    : ''
  const query = entityId
    ? gql`
    query accessControlUsers {
      userEntities(id: "${entityId}" ${filterDatasource}) {
          edges {
              node {
                  totalGroups: groupAccess(first: 1) {
                    count
                  }
                  totalUsers: userAccess(first: 1) {
                    count
                  }
                  userAccess(${filters}) {
                      count
                      edges {
                          node {
                              id
                              name
                              employeeType
                              departmentName
                              dataPrivilegeLevel
                              datasources {
                                  count
                              }
                              memberOfGroups {
                                count
                              }
                          }
                      }
                  }
              }
          }
      }
  }
  `
    : params.objectIds?.length
    ? gql`
  query accessControlUsers {
    objects(id: "${params.objectIds[0]}", ${filterDatasourceIds}) {
          edges {
              node {

                  userAccess(first: 50 ${accessModesFilter}) {
                      count
                      edges {
                          node {
                              id
                              name
                              employeeType
                              departmentName
                              dataPrivilegeLevel
                              datasources {
                                  count
                              }
                              memberOfGroups {
                                count
                              }
                          }
                      }
                  }
              }
          }
      }
  }

  `
    : gql`
  {
    accessControlUsers(${filters}) {
        count
        edges {
            node {
                id
                name
                employeeType
                departmentName
                datasources {
                    count
                }
                entities {
                  count
                }
                memberOfGroups {
                  count
                }
                dataPrivilegeLevel
            }
        }
    }
  }
  `
  return query
}

export const mapQueryUsers = (
  raw: any
): {
  list: AccessControlUserDetailsOverview[]
  total: number
  groupsCount?: number
  usersCount?: number
} => {
  const { accessControlUsers } = raw
  const list = accessControlUsers.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    employeeType: node?.employeeType,
    departmentName: node?.departmentName,
    datasourcesCount: node?.datasources?.count || 0,
    entitiesCount: node?.entities?.count || 0,
    groupsCount: node?.memberOfGroups?.count || 0,
    dataPrivilegeLevel: node?.dataPrivilegeLevel
  }))

  return { list, total: accessControlUsers.count }
}

export const mapQueryUsersForEntities = (
  raw: any
): {
  list: AccessControlUserDetailsOverview[]
  total: number
  groupsCount: number
  usersCount: number
} => {
  const { userEntities } = raw
  const accessControlUsers = userEntities?.edges?.[0]?.node?.userAccess
  const groups = userEntities?.edges?.[0]?.node?.totalGroups
  const users = userEntities?.edges?.[0]?.node?.totalUsers
  const list = accessControlUsers.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    employeeType: node?.employeeType,
    departmentName: node?.departmentName,
    datasourcesCount: node?.datasources?.count || 0,
    dataPrivilegeLevel: node?.dataPrivilegeLevel,
    groupsCount: node?.memberOfGroups?.count || 0
  }))

  return {
    list,
    total: accessControlUsers.count,
    groupsCount: groups?.count || 0,
    usersCount: users?.count || 0
  }
}

export const mapQueryUsersForObjects = (
  raw: any
): {
  list: AccessControlUserDetailsOverview[]
  total: number
  groupsCount: number
  usersCount: number
} => {
  const { objects } = raw
  const accessControlUsers = objects?.edges?.[0]?.node?.userAccess
  const groups = objects?.edges?.[0]?.node?.groupAccess
  const list = accessControlUsers.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    employeeType: node?.employeeType,
    departmentName: node?.departmentName,
    datasourcesCount: node?.datasources?.count || 0,
    dataPrivilegeLevel: node?.dataPrivilegeLevel,
    groupsCount: node?.memberOfGroups?.count || 0
  }))

  return {
    list,
    total: accessControlUsers.count,
    groupsCount: groups?.count || 0,
    usersCount: accessControlUsers?.count || 0
  }
}

export const mapQueryGroupsForEntities = (raw: any) => {
  const { userEntities } = raw
  const accessControlGroups = userEntities?.edges?.[0]?.node?.groupAccess
  const list = accessControlGroups.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    employeeType: node?.employeeType,
    departmentName: node?.departmentName,
    usersCount: node?.memberUsers?.count || 0,
    groupsCount: node?.memberGroups?.count || 0,
    attributesCount: node?.attributesGroupedByType?.count || 0,
    datasourcesCount: node?.datasources?.count || 0,
    dataPrivilegeLevel: node?.dataPrivilegeLevel
  }))

  return {
    list,
    total: accessControlGroups.count,
    groupsCount: accessControlGroups?.count || 0,
    highSensitiveCount: 0
  }
}

export const queryUsersSummary = (params: IGetAccessControlUsersFilterParams) => {
  const filterStr = params.datasourceId ? ` ,datasourceId: "${params.datasourceId}"` : ``
  return gql`
    query accessControlUsersSummary {
        contractors: accessControlUsers(sensitivity:[${DataSensitivityLevels.High}], employeeType: ${AccessControlEmployeeType.Contractor} ${filterStr}) {
            count
        }

        employees: accessControlUsers(sensitivity:[${DataSensitivityLevels.High}], employeeType: ${AccessControlEmployeeType.Employee}, ${filterStr}) {
            count
        }

        totalContractors: accessControlUsers(employeeType: ${AccessControlEmployeeType.Contractor} ${filterStr}) {
            count
        }

        totalEmployees: accessControlUsers(employeeType: ${AccessControlEmployeeType.Employee}, ${filterStr}) {
            count
        }
    }
  `
}

export const mapQueryUsersSummary = (raw: any): AccessControlUsersSummary => {
  return {
    employeesCount: raw?.employees?.count || 0,
    contractorsCount: raw?.contractors?.count || 0,
    totalEmployeesCount: raw?.totalEmployees?.count || 0,
    totalContractorsCount: raw?.totalContractors?.count || 0
  }
}

export const queryUserOverview = (params: IGetAccessControlUsersFilterParams) => {
  const { id, datasourceId } = params
  const filterStr = datasourceId ? ` ,datasourceId: "${datasourceId}"` : ``
  const dsIdsFilter = datasourceId ? `, datasourceIds: "${datasourceId}"` : ``
  const dsKeyfilterStr = datasourceId ? ` ,{ key: DATASOURCE_ID, values: "${datasourceId}"}` : ``
  const filterAccessUserIds = id ? ` ,accessControlUserIds: ${id}` : ``
  const filterIsOwner = datasourceId ? ` ,isOwner: true` : ``
  return gql`
    query userDetailsOverview {
     ${
       dsIdsFilter
         ? `
     highSensitiveOwnedObjects: accessControlObjects(accessControlUserIds: ${id} ${dsIdsFilter} sensitivity: [${DataSensitivityLevels.High}] ${filterIsOwner}) {
        count
      }
     `
         : ``
     } 
      entitiesCount: userEntities(
        filter: [{ key: ACCESS_CONTROL_USER_IDS, values: ["${id}"]} ${dsKeyfilterStr}]
        booleanFilter: [{key:APPROX_ACCESSORS_COUNT, value: true}]
      ) {
        count
      }
      ${
        dsIdsFilter
          ? `openAccessObjects: accessControlObjects(
        first: 1,
        hasOpenAccess: true,
        ${filterIsOwner}
        ${filterAccessUserIds}
        ${dsIdsFilter}
      ) {
        count
      }
      excessiveAccessObjects: accessControlObjects(
        first: 1,
        hasExcessiveAccess: true
        ${filterIsOwner}
        ${filterAccessUserIds}
        ${dsIdsFilter}
      ) {
        count
      }
      crossDeptAccessObjects: accessControlObjects(
        first: 1,
        hasCrossDepartmentAccess: true,
        ${filterIsOwner}
        ${filterAccessUserIds}
        ${dsIdsFilter}
      ) {
        count
      }
       totalObjects: accessControlObjects(${filterIsOwner}${filterAccessUserIds}${dsIdsFilter}) {
      count
    }
      `
          : ''
      }
      accessControlUsers(id: ${id} ${filterStr}) {
        edges {
          node {
            id
            name
            departmentName
            userType
            region
            dataPrivilegeLevel
            employeeType
            memberOfGroups {
              count
            }
            objects {
              count
            }
            sensitiveObjects: objects(isSensitive: true) {
              count
            }
            datasources {
              count
            }
            email
            attributesGroupedBySensitivity {
              edges {
                node {
                  count
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryUserOverview = (raw: any): AccessControlUserDetailsOverview => {
  const {
    accessControlUsers,
    entitiesCount,
    openAccessObjects,
    totalObjects,
    excessiveAccessObjects,
    crossDeptAccessObjects,
    highSensitiveOwnedObjects
  } = raw

  const user = accessControlUsers?.edges?.[0]?.node
  let attributesCount = 0
  user?.attributesGroupedBySensitivity.edges.forEach(({ node }) => {
    attributesCount += node?.count || 0
  })

  return {
    id: user?.id,
    name: user?.name,
    departmentName: user?.departmentName,
    userType: user?.userType,
    region: user?.region,
    dataPrivilegeLevel: user?.dataPrivilegeLevel,
    employeeType: user?.employeeType,
    groupsCount: user?.memberOfGroups?.count,
    objectsCount: user?.objects?.count,
    datasourcesCount: user?.datasources?.count,
    entitiesCount: entitiesCount?.count || 0,
    email: user?.email,
    attributesCount,
    sensitiveObjects: user?.sensitiveObjects?.count || 0,
    highSensitiveOwnedObjects: highSensitiveOwnedObjects?.count || 0,
    openAccessObjectsCount: openAccessObjects?.count || 0,
    excessiveAccessObjectsCount: excessiveAccessObjects?.count || 0,
    crossDepartmentAccessObjectsCount: crossDeptAccessObjects?.count || 0,
    totalObjectsCount: totalObjects?.count || 0
  }
}

export const queryUserDatasources = (params: IGetAccessControlUsersFilterParams) => {
  const {
    id,
    page = 1,
    entityTypes,
    datasourceId,
    sensitivityLevels,
    attributeIds,
    groupId
  } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  let filterObjectsStr = ``
  if (id) {
    filterObjectsStr += ` ,accessControlUserIds: [${id}]`
  }
  if (groupId) {
    filterObjectsStr += ` ,accessControlGroupIds: [${groupId}]`
  }
  if (datasourceId) {
    filterObjectsStr += ` ,datasourceIds: "${datasourceId}"`
  }
  if (entityTypes?.length) {
    filterObjectsStr += ` ,entityTypeId: "${entityTypes[0]}"`
  }
  if (sensitivityLevels) {
    filterObjectsStr += ` ,sensitivity: [${sensitivityLevels.join(',')}]`
  }

  if (attributeIds?.length) {
    filterObjectsStr += ` ,attributeTypeIds: ${JSON.stringify(attributeIds)}`
  }

  return gql`
    query userDetailsDatasources {
      accessControlObjectGroupByDatasource(first: ${LIMIT_DEFAULT}, after: "${cursor}" ${filterObjectsStr}){
        count
        edges {
          node {
            count
            datasource {
              id
              name
              type
              ${window.__featureFlags.riskScore ? 'riskDensity' : ''}
            }
          }
        }
      }
      
    }
  `
}
export const mapQueryUserDatasources = (
  raw: any
): { list: AccessControlUserDatasource[]; total: number } => {
  const {
    accessControlObjectGroupByDatasource,
    accessControlUsersEntityGroupedByDatasource,
    accessControlGroupsEntityGroupedByDatasource
  } = raw

  const entityRawNode =
    accessControlGroupsEntityGroupedByDatasource || accessControlUsersEntityGroupedByDatasource

  const list = accessControlObjectGroupByDatasource?.edges?.map(({ node }) => {
    const dataSource = node?.datasource || {}
    const objectsCount = node?.count || 0
    const entitiesCount =
      entityRawNode?.edges?.find(({ node }) => node.id === dataSource?.id)?.node?.entitiesCount || 0
    return {
      id: dataSource?.id,
      name: dataSource?.name,
      type: dataSource?.type,
      entitiesCount,
      objectsCount,
      riskDensity: dataSource?.riskDensity
    }
  })
  return {
    total: accessControlObjectGroupByDatasource?.count || 0,
    list
  }
}

export const queryUserEntities = (params: IGetAccessControlUsersFilterParams) => {
  const { id, page = 1, datasourceId, risky, entityTypes, groupId } = params
  const fetchCount = risky === undefined
  const filterStr = datasourceId ? ` ,{ key: DATASOURCE_ID, values: "${datasourceId}"}` : ``
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  const booleanFilter = risky ? ` booleanFilter: [{ key: IS_RISKY, value: ${risky} }]` : ``

  const entityTypeFilter = entityTypes?.length
    ? `, { key: TYPE, values: ${JSON.stringify(entityTypes)} }`
    : ''

  const groupIdFilter = groupId ? `, { key: ACCESS_CONTROL_GROUP_IDS, values: ["${groupId}"] }` : ''
  const userIdFilter = id ? `, { key: ACCESS_CONTROL_USER_IDS, values: ["${id}"] }` : ''

  const idFilter = groupId ? groupIdFilter : userIdFilter

  const queryName = groupId ? `groupDetailsEntities` : `userDetailsEntities`

  return gql`
    query ${queryName} {
      ${
        fetchCount
          ? `entitiesCount: userEntities(
        first: ${LIMIT_DEFAULT} after: "${cursor}"
        filter: [${idFilter} ${entityTypeFilter} ${filterStr}]
        ${booleanFilter}
      ) {
        count
      }`
          : ''
      }
      
      userEntities(
        first: ${LIMIT_DEFAULT} after: "${cursor}"
        filter: [${idFilter} ${entityTypeFilter} ${filterStr}]
        ${booleanFilter}
      ) {
          # count
          edges {
            node {
              id
              name
              riskStatus
              type {
                edges {
                  node {
                    name
                  }
                }
              }
              attributesCount
              datasources {
                count
              }
              objects {
                count
              }
            }
        }
      }
    }
  `
}
export const mapQueryUserEntities = (
  raw: any
): { list: AccessControlUserEntities[]; total: number } => {
  const { userEntities, entitiesCount } = raw

  const list = userEntities.edges?.map(({ node }) => {
    return {
      id: node?.id,
      name: node?.name?.[0] || '',
      type: node?.type?.edges?.[0]?.node?.name,
      riskStatus: node?.riskStatus || false,
      attributesCount: node?.attributesCount || 0,
      objectsCount: node?.objects?.count || 0,
      datasourcesCount: node?.datasources?.count || 0
    }
  })
  return {
    total: entitiesCount?.count || list?.length,
    list
  }
}

export const queryUsersWidget = (params: IGetAccessControlUsersFilterParams) => {
  const filterStr = getUserFiltersString(params)
  return gql`
    query accessControlUsersWidget {
      totalUsers: accessControlUsers ${filterStr ? `(${filterStr})` : ''} {
        count
      }
      privilegeUsers: accessControlUsers(sensitivity:[${DataSensitivityLevels.High}, ${
    DataSensitivityLevels.Medium
  }, ${DataSensitivityLevels.Low}] ${filterStr}) {
        count
      }
      employeeUsers: accessControlUsers(employeeType: EMPLOYEE ${filterStr}) {
        count
      }
      contractorUsers: accessControlUsers(employeeType: CONTRACTOR ${filterStr}) {
        count
      }
    }
  `
}

export const mapQueryUsersWidget = (raw: any): AccessControlUsersWidget => {
  const { totalUsers, privilegeUsers, employeeUsers, contractorUsers } = raw
  return {
    totalUsers: totalUsers?.count || 0,
    privilegeUsers: privilegeUsers?.count || 0,
    employeeUsers: employeeUsers?.count || 0,
    contractorUsers: contractorUsers?.count || 0
  }
}

export const queryGroupsWidget = (params: IGetAccessControlUsersFilterParams) => {
  const filterStr = getUserFiltersString(params)
  return gql`
    query accessControlGroupsWidget {
      totalGroups: accessControlGroups ${filterStr ? `(${filterStr})` : ''}{
        count
      }
      privilegeGroups: accessControlGroups(sensitivity:[${DataSensitivityLevels.High}, ${
    DataSensitivityLevels.Medium
  }, ${DataSensitivityLevels.Low}] ${filterStr}) {
        count
      }
    }
  `
}

export const mapQueryGroupsWidget = (raw: any): AccessControlGroupsWidget => {
  const { totalGroups, privilegeGroups } = raw
  return {
    totalGroups: totalGroups?.count || 0,
    privilegeGroups: privilegeGroups?.count || 0
  }
}

export const queryIdpWidget = () => {
  return gql`
    query accessControlIdentityProvider {
      identityProvider {
        count
        edges {
          node {
            id
            name
            idpType
          }
        }
      }
    }
  `
}

export const mapQueryIdpWidget = (raw: any): AccessControlIdpWidget => {
  const { identityProvider } = raw
  return {
    totalProviders: identityProvider?.count || 0,
    providers: identityProvider?.edges?.map(({ node }) => ({
      name: node?.name || '',
      type: node?.idpType || '',
      id: node?.id || '',
      status: IdpStatus.Active // TODO
    }))
  }
}

const getSensitivityFilterValue = (sensitivityLevel: DataSensitivityLevels) => {
  return sensitivityLevel === DataSensitivityLevels.All
    ? `[${DataSensitivityLevels.High}, ${DataSensitivityLevels.Medium}, ${DataSensitivityLevels.Low}, ${DataSensitivityLevels.None}]`
    : sensitivityLevel
}

export const queryObjectsWithImpactedAccess = (
  filter: ObjectsImpactedAccessTypeFilter,
  sensitivityLevel: DataSensitivityLevels
) => {
  const sensitivity = getSensitivityFilterValue(sensitivityLevel)
  return gql`
    query accessControlObjectWithImpactedAccess {
      impactedObjects: accessControlObjectGroupByDatasource(${filter}: true, sensitivity: ${sensitivity}) {
        edges {
          node {
            datasource {
              id
              name
              type
            }
            count
          }
        }
      }
  
    }
  `
}

export const mapQueryObjectsWithImpactedAccess = (
  raw: any
): AccessControlObjectsWithImpactedAccess => {
  const impactedObjects = raw?.impactedObjects?.edges || []

  const payload: AccessControlObjectsWithImpactedAccess = {
    totalObjects: 0,
    impactedObjects: 0,
    datasources: []
  }

  for (let i = 0; i < impactedObjects.length; i++) {
    const impactedNode = impactedObjects[i]?.node

    payload.impactedObjects += +(impactedNode?.count || 0)
    const datasourceNode = impactedNode?.datasource
    if (datasourceNode?.id) {
      payload.datasources.push({
        id: datasourceNode?.id || '',
        type: datasourceNode?.type || '',
        name: datasourceNode?.name || '',
        impactedObjects: +(impactedNode?.count || 0)
      })
    }
  }

  return payload
}

export const queryObjectsWithImpactedAccessByDept = (
  filter: ObjectsImpactedAccessTypeFilter,
  params: IGetAccessControlUsersFilterParams
) => {
  const { sensitivityLevel, datasourceId } = params
  const filterDataSourceIds = `datasourceIds: ["${datasourceId}"]`
  const accessFilter =
    filter === ObjectsImpactedAccessTypeFilter.OpenAccess
      ? `hasOpenAccess: true`
      : `hasExcessiveAccess: true`
  const sensitivity = sensitivityLevel ? getSensitivityFilterValue(sensitivityLevel) : ''
  return gql`
    query accessControlObjectGroupByDepartmentWithImpactedAccessWidget {
      impactedObjects :accessControlObjectGroupByDepartment(
        ${accessFilter}
        sensitivity: ${sensitivity}
        ${filterDataSourceIds}
    ) {
        edges {
            node {
                departmentName
                objects {
                    count
                }
            }
        }
    }
    totalDepartments: accessControlObjectGroupByDepartment(
      ${accessFilter}
      sensitivity: ${sensitivity}
      ${filterDataSourceIds}
    ) {
      count
    }
    impactedAccessOwners: accessControlObjectGroupByUserOwner(
        ${accessFilter}
        ${filterDataSourceIds}
        sensitivity: ${sensitivity}
    ) {
        count
    }
    totalObjects: accessControlObjects(
      ${accessFilter}
      sensitivity: ${sensitivity}
      ${filterDataSourceIds}) {
        count
      }
    }
  `
}

export const mapQueryObjectsWithImpactedAccessByDept = (
  raw: any
): AccessControlObjectsWithImpactedAccessByDept => {
  const { totalObjects, impactedAccessOwners, impactedObjects, totalDepartments } = raw

  return {
    totalObjects: totalObjects?.count || 0,
    totalDepartments: totalDepartments?.count || 0,
    totalOwners: impactedAccessOwners?.count || 0,
    departments: impactedObjects?.edges?.map(({ node }) => ({
      name: node?.departmentName || '',
      totalObjects: +(node?.objects?.count || 0)
    }))
  }
}

export const queryEntitiesWithAccess = () => {
  return gql`
    query accessControlEntityWidget {
      employeesCount: accessControlEntities(employeeType: EMPLOYEE) {
        count
      }
      contractorsCount: accessControlEntities(employeeType: CONTRACTOR) {
        count
      }
      employeesUsersCount: accessControlUsers(hasEntityAccess: true, employeeType: EMPLOYEE) {
        count
      }
      contractorsUsersCount: accessControlUsers(hasEntityAccess: true, employeeType: CONTRACTOR) {
        count
      }
      userEntities {
        count
      }
    }
  `
}

export const mapQueryUserEntitiesWithAccess = (raw: any): AccessControlEntityWidget => {
  const {
    employeesCount,
    contractorsCount,
    userEntities,
    employeesUsersCount,
    contractorsUsersCount
  } = raw
  return {
    employeesCount: employeesCount?.count || 0,
    contractorsCount: contractorsCount?.count || 0,
    totalEntities: userEntities?.count || 0,
    employeesUsersCount: employeesUsersCount?.count || 0,
    contractorsUsersCount: contractorsUsersCount?.count || 0
  }
}

// User Attributes
export const queryAccessControlUserAttributesSummary = (
  params: AccessControlUserAttributesParams
) => {
  const filters = params.datasourceIds?.length
    ? `datasourceIds: ${JSON.stringify(params.datasourceIds)}`
    : ''

  const filterSensitivity = params.sensitivity
    ? ` ,sensitivity: [${params.sensitivity.join(',')}]`
    : ''

  const { userId, groupId } = params

  const node = groupId ? `accessControlGroups` : `accessControlUsers`
  const idFilter = groupId ? groupId : userId
  const filtersString = filters || filterSensitivity ? `(${filters} ${filterSensitivity})` : ''

  return gql`
    {
      ${node}(first: 1, id: ${idFilter} ) {
        edges {
          node {
            attributesGroupedBySensitivity${filtersString} {
              edges {
                node {
                  attributeSensitivity
                  count
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryAccessControlUserAttributesSummary = (
  raw: any
): AccessControlUserAttributesWidget[] => {
  try {
    const { accessControlUsers, accessControlGroups } = raw || {}
    const rawNode = accessControlGroups || accessControlUsers
    return rawNode?.edges[0]?.node?.attributesGroupedBySensitivity?.edges?.map(({ node }) => ({
      attributeSensitivity: node.attributeSensitivity,
      count: node.count
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlObjectsCount = (params: AccessControlUserAttributesParams) => {
  let filtersStr = ``
  if (params.datasourceIds?.length) {
    filtersStr += `,datasourceIds: ${JSON.stringify(params.datasourceIds)}`
  }
  if (params.userId) {
    filtersStr += `,accessControlUserIds: [${params.userId}]`
  }
  if (params.groupId) {
    filtersStr += `,accessControlGroupIds: [${params.groupId}]`
  }
  if (params.sensitivity) {
    filtersStr += ` ,sensitivity: [${params.sensitivity.join(',')}]`
  }

  return gql`
  {
    accessControlObjects(first: 1 ${filtersStr}) {
      count
    }
  }
  `
}

export const mapQueryAccessControlObjectsCount = (raw: any): number => {
  const { accessControlObjects } = raw
  return accessControlObjects?.count || 0
}

export const queryAccessControlUserAttributeCards = (params: AccessControlUserAttributesParams) => {
  const filterDsIds = params.datasourceIds?.length
    ? `datasourceIds: ${JSON.stringify(params.datasourceIds)},`
    : ''

  const filterSensitivity = params.sensitivity
    ? `attributeSensitivity: [${params.sensitivity.join(',')}],`
    : ''

  const filterAttributeIds = params.attributeIds?.length
    ? `attributeTypeIds: ${JSON.stringify(params.attributeIds)},`
    : ''

  const filtersString = `${filterDsIds}${filterSensitivity}${filterAttributeIds}`

  const { userId, groupId } = params

  const node = groupId ? `accessControlGroups` : `accessControlUsers`
  const idFilter = groupId ? groupId : userId
  return gql`
    {
      ${node}(first: 999, id: ${idFilter}) {
        edges {
          node {
            attributesGroupedByType(first: ${LIMIT_DEFAULT} ${filtersString}) {
              edges {
                node {
                  datasources {
                    edges {
                      node {
                        id
                        name
                        type
                      }
                    }
                  }
                  attribute(first: 1) {
                    edges {
                      node {
                        id
                        name
                        sensitivityLabel
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryAccessControlUserAttributeCards = (
  raw: any
): { cards: AccessControlUserAttributeCard[]; total: number } => {
  try {
    const { accessControlUsers, accessControlGroups } = raw || {}
    const rawNode = accessControlGroups || accessControlUsers
    const rawCards = rawNode?.edges[0]?.node?.attributesGroupedByType?.edges?.map(({ node }) => ({
      dataSourcesCount: node?.datasources?.count || 0,
      attributeId: node?.attribute?.edges[0]?.node?.id,
      attributeName: node?.attribute?.edges[0]?.node?.name,
      sensitivityLabel: node?.attribute?.edges[0]?.node?.sensitivityLabel,
      dataSources: node?.datasources?.edges.map(({ node: ds }) => ({
        id: ds.id,
        name: ds.name,
        type: ds.type
      }))
    }))

    const aggregationByAttributeId: { [attributeId: string]: AccessControlUserAttributeCard } = {}

    rawCards.forEach((card) => {
      if (!aggregationByAttributeId[card.attributeId]) {
        aggregationByAttributeId[card.attributeId] = card
      } else {
        aggregationByAttributeId[card.attributeId].dataSourcesCount =
          (aggregationByAttributeId[card.attributeId].dataSourcesCount || 0) +
          (card.dataSourcesCount || 0)
        aggregationByAttributeId[card.attributeId].dataSourcesCount =
          (aggregationByAttributeId[card.attributeId].dataSourcesCount || 0) +
          (card.dataSourcesCount || 0)
        aggregationByAttributeId[card.attributeId].dataSources = [
          ...(aggregationByAttributeId[card.attributeId].dataSources || []),
          ...(card.dataSources || [])
        ]
      }
    })

    const cards = Object.values(aggregationByAttributeId)

    return { cards, total: cards.length }
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Datasource accessible  Objects
export const queryAccessControlObjectsSummary = (params: AccessControlObjectsParams) => {
  const filterStringWithOwner = getObjectFiltersString(params, { hasPagination: false })
  delete params.isOwner
  const filters = getObjectFiltersString(params, { hasPagination: false })

  return gql`
    {
      openAccessObjects: accessControlObjects(
        first: 1,
        hasOpenAccess: true,
        ${filterStringWithOwner}
      ) {
        count
      }
      openAccessOwners: accessControlObjectGroupByUserOwner (
        first: 1,
        hasOpenAccess: true,
        ${filters}
      ) {
        count
      }
      excessiveAccessObjects: accessControlObjects(
        first: 1,
        hasExcessiveAccess: true
        ${filterStringWithOwner}
      ) {
        count
      }
      excessiveAccessOwners: accessControlObjectGroupByUserOwner (
        first: 1,
        hasExcessiveAccess: true,
        ${filters}
      ) {
        count
      }
      crossDeptAccessObjects: accessControlObjects(
        first: 1,
        hasCrossDepartmentAccess: true,
        ${filterStringWithOwner}
      ) {
        count
      }
      crossDeptAccessOwners: accessControlObjectGroupByUserOwner (
        first: 1,
        hasCrossDepartmentAccess: true,
        ${filters}
      ) {
        count
      }
    }
  `
}
export const mapQueryAccessControlObjectsSummary = (
  raw: any
): {
  openAccess: AccessControlObjectSummary
  excessiveAccess: AccessControlObjectSummary
  crossDepartmentsAccess: AccessControlObjectSummary
} => {
  try {
    const openAccess: AccessControlObjectSummary = {
      objectsCount: raw.openAccessObjects?.count || 0,
      ownersCount: raw.openAccessOwners?.count || 0
    }
    const excessiveAccess: AccessControlObjectSummary = {
      objectsCount: raw.excessiveAccessObjects?.count || 0,
      ownersCount: raw.excessiveAccessOwners?.count || 0
    }

    const crossDepartmentsAccess: AccessControlObjectSummary = {
      objectsCount: raw.crossDeptAccessObjects?.count || 0,
      ownersCount: raw.crossDeptAccessOwners?.count || 0
    }

    return { openAccess, excessiveAccess, crossDepartmentsAccess }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlObjectsCountForList = (params: AccessControlObjectsParams) => {
  const filters = getObjectFiltersString(params, { hasPagination: false })

  return gql`
    query accessControlObjectsCount  {
      accessControlObjects(${filters}) {
        count
      }
    }
    `
}

export const queryAccessControlObjectsList = (params: AccessControlObjectsParams) => {
  const filters = getObjectFiltersString(params, { hasPagination: true })

  return gql`
    {
      accessControlObjects(${filters}) {
        edges {
          node {
            id
            name
            sensitivityLevel
            objectLink
            owner {
              edges {
                node {
                  name
                  id
                }
              }
            }
            groupAccess(first: 1) { count }
            userAccess(first: 1, 
            totalAccessorCount: true
            ) { count }
            object {
              edges {
                node {
                  id
                  
                  attribute(first: 1) { count }
                  entity { count }
                }
              }
             
            }
          }
        }
      }
    }
  `
}
export const mapQueryAccessControlObjectsList = (
  raw: any
): { list: AccessControlObject[]; pageInfo?: PageInfo } => {
  try {
    const list = raw.accessControlObjects?.edges?.map(({ node: item }) => {
      const object = item.object?.edges?.[0]?.node || {}
      return {
        id: item.id ?? '',
        mongoId: object?.id || '',
        name: item.name || '',
        owner: item.owner?.edges?.[0]?.node?.name || '',
        ownerInfo: item.owner?.edges?.[0]?.node,
        sensitivity: item.sensitivityLevel?.toUpperCase() || '',
        groupAccessCount: item?.groupAccess?.count || 0,
        userAccessCount: item?.userAccess?.count || 0,
        attributesCount: object.attribute?.count || 0,
        entitiesCount: object.entity?.count || 0
      }
    })

    return {
      list,
      pageInfo: raw.accessControlObjects?.pageInfo
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlObjectDetails = (params: IGetAccessControlUsersFilterParams) => {
  const filters = getUserFiltersString(params, { hasPagination: false })

  return gql`
    {
      accessControlObjects(first: 1, ${filters}) {
        count
        edges {
          node {
              id
              name
              owner {
                edges {
                  node {
                    name
                    email 
                    id
                  }
                }
              }
            sensitivityLevel
            groupAccess(first: ${LIMIT_DEFAULT}) {
              count
              edges {
                node {
                  id
                  name
                }
              }
            }
            userAccess(first: ${LIMIT_DEFAULT}) {
              count
              edges {
                node {
                  id
                  name
                }
              }
            }
            object {
              edges {
                node {
                  entities {
                    id
                    name
                  }
                  attribute(first: ${LIMIT_DEFAULT}) {
                    count
                    edges {
                      node {
                        id
                        name
                        internalName
                      }
                    }
                  }
                  labels(first: ${LIMIT_DEFAULT}) {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
              
            }
          }
        }
      }
    }
  `
}
export const mapQueryAccessControlObjectDetails = (raw: any): AccessControlObject => {
  try {
    const item = raw.accessControlObjects?.edges[0]?.node
    const object = raw.accessControlObjects?.edges[0]?.node?.object?.edges[0]?.node

    const result: AccessControlObject = {
      sensitivity: item?.sensitivityLevel?.toUpperCase() || '',
      id: item.id ?? '',
      name: item.name || '',
      owner: item.owner?.edges?.[0]?.node?.name || '',
      ownerInfo: item.owner?.edges?.[0]?.node,
      userAccess: item?.userAccess?.edges?.map(({ node }) => ({
        id: node.id,
        name: node.name
      })),
      groupAccess: item?.groupAccess?.edges?.map(({ node }) => ({
        id: node.id,
        name: node.name
      })),
      attributes: object?.attribute?.edges?.map(({ node }) => node),
      labels: object?.labels?.edges?.map(({ node }) => node),
      entities: object?.entities?.map(({ id, name }) => ({ id, name: name ? name[0] : '' })),
      isDetailsReceived: true
    }
    return result
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlCounts = (params: AccessControlObjectsParams) => {
  const filterDataSourceIds = params.datasourceIds?.length
    ? `datasourceIds: ${JSON.stringify(params.datasourceIds)},`
    : ''

  const filterOpenAccess = params.hasOpenAccess ? `hasOpenAccess: ${params.hasOpenAccess},` : ''
  const filterExcessiveAccess = params.hasExcessiveAccess
    ? `hasExcessiveAccess: ${params.hasExcessiveAccess},`
    : ''
  const filterCrossDeptAccess = params.hasCrossDepartmentsAccess
    ? `hasCrossDepartmentAccess: ${params.hasCrossDepartmentsAccess},`
    : ''
  const filterSensitivity = params.sensitivity
    ? `sensitivity: [${params.sensitivity.map((lvl) => lvl.toUpperCase())}],`
    : ''
  const filterAttributeIds = params.attributeIds?.length
    ? `attributeTypeIds: ${JSON.stringify(params.attributeIds)},`
    : ''
  const filterAccessUserIds = params.accessControlUserIds?.length
    ? `accessControlUserIds: ${JSON.stringify(params.accessControlUserIds)},`
    : ''

  const filterAccessGroupIds = params.accessControlGroupIds?.length
    ? `accessControlGroupIds: ${JSON.stringify(params.accessControlGroupIds)},`
    : ''

  const filterIsOwner = params.isOwner ? `isOwner: ${params.isOwner},` : ''

  // const filterDirectAccess = params.hasDirectAccess ? `hasDirectAccess: ${params.hasDirectAccess},` : ''

  const filterDepartment = params.departmentName
    ? `departmentName: "${params.departmentName}",`
    : ''

  return gql`
    {
      accessControlObjects(
        first: 1,
        ${filterOpenAccess}
        ${filterExcessiveAccess}
        ${filterCrossDeptAccess}
        ${filterSensitivity}
        ${filterDataSourceIds}
        ${filterAttributeIds}
        ${filterAccessUserIds}
        ${filterAccessGroupIds}
        ${filterIsOwner}
        ${filterDepartment}
      ) {
        count
      }
      accessControlObjectGroupByUserOwner(
        first: 1,
        ${filterOpenAccess}
        ${filterExcessiveAccess}
        ${filterCrossDeptAccess}
        ${filterSensitivity}
        ${filterAccessUserIds}
        ${filterAccessGroupIds}
        ${filterDataSourceIds}
        ${filterAttributeIds}
        ${filterDepartment}
      ) {
        count
      }
    }
  `
}
export const mapQueryAccessControlCounts = (
  raw: any
): { ownersCount: number; objectsCount: number } => {
  try {
    return {
      ownersCount: raw.accessControlObjectGroupByUserOwner?.count || 0,
      objectsCount: raw.accessControlObjects?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlObjectsCards = (params: AccessControlObjectsParams) => {
  const { fetchCount } = params
  const filterDataSourceIds = params.datasourceIds?.length
    ? `datasourceIds: ${JSON.stringify(params.datasourceIds)},`
    : ''

  const cursor = getAfterCursor(params.page || 1, LIMIT_DEFAULT)
  const filterOpenAccess = params.hasOpenAccess ? `hasOpenAccess: ${params.hasOpenAccess},` : ''
  const filterExcessiveAccess = params.hasExcessiveAccess
    ? `hasExcessiveAccess: ${params.hasExcessiveAccess},`
    : ''
  const filterCrossDeptAccess = params.hasCrossDepartmentsAccess
    ? `hasCrossDepartmentAccess: ${params.hasCrossDepartmentsAccess},`
    : ''
  const filterSensitivity = params.sensitivity
    ? `sensitivity: [${params.sensitivity.map((lvl) => lvl.toUpperCase())}],`
    : ''
  const filterAttributeIds = params.attributeIds?.length
    ? `attributeTypeIds: ${JSON.stringify(params.attributeIds)},`
    : ''

  const filterDepartment = params.departmentName
    ? `departmentName: "${params.departmentName}",`
    : ''
  const filterSearchText = params.searchText ? `search: "${params.searchText}",` : ''
  return gql`
    {
      accessControlObjectGroupByUserOwner(
        first: ${LIMIT_DEFAULT},
        after: "${cursor}",
        ${filterOpenAccess}
        ${filterExcessiveAccess}
        ${filterCrossDeptAccess}
        ${filterSensitivity}
        ${filterDataSourceIds}
        ${filterDepartment}
        ${filterAttributeIds}
        ${filterSearchText}
      ) {
        ${
          fetchCount
            ? 'count'
            : `
        `
        }
        edges {
          node {
            user {
              id
              email
              name
            }
            sensitiveObjects: object(sensitivity: [HIGH, MEDIUM, LOW]) {
              count
            }
            object {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryAccessControlObjectsCards = (
  raw: any
): { cards: AccessControlObjectCard[]; total: number; pageInfo?: PageInfo } => {
  try {
    const cards = raw.accessControlObjectGroupByUserOwner?.edges?.map(({ node: item }) => ({
      ownerId: item.user?.id ?? '',
      ownerEmail: item.user?.email || '',
      ownerName: item.user?.name || '',
      objectsCount: item.object?.count || 0,
      sensitiveObjectsCount: item.sensitiveObjects?.count || 0
    }))

    return {
      cards,
      total: raw.accessControlObjectGroupByUserOwner?.count || cards?.length || 0,
      pageInfo: raw.accessControlObjectGroupByUserOwner?.pageInfo
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlObjectsCardBySensitivity = (params: AccessControlObjectsParams) => {
  const filterDataSourceIds = params.datasourceIds?.length
    ? `datasourceIds: ${JSON.stringify(params.datasourceIds)},`
    : ''

  const filterAttributeIds = params.attributeIds?.length
    ? `attributeTypeIds: ${JSON.stringify(params.attributeIds)},`
    : ''

  const filterAccessUserIds = params.accessControlUserIds?.length
    ? `accessControlUserIds: ${JSON.stringify(params.accessControlUserIds)},`
    : ''

  const filterAccessGroupIds = params.accessControlGroupIds?.length
    ? `accessControlGroupIds: ${JSON.stringify(params.accessControlGroupIds)},`
    : ''

  const filterOpenAccess = params.hasOpenAccess ? `hasOpenAccess: ${params.hasOpenAccess},` : ''
  const filterExcessiveAccess = params.hasExcessiveAccess
    ? `hasExcessiveAccess: ${params.hasExcessiveAccess},`
    : ''

  const filterCrossDepartmentsAccess = params.hasCrossDepartmentsAccess
    ? `hasCrossDepartmentAccess
        : ${params.hasCrossDepartmentsAccess},`
    : ''

  const filterIsOwner = params.isOwner ? `isOwner: ${params.isOwner},` : ''

  const filters = `${filterOpenAccess}${filterExcessiveAccess}${filterCrossDepartmentsAccess}${filterDataSourceIds}${filterAttributeIds}${filterAccessUserIds}${filterAccessGroupIds}${filterIsOwner}`

  return gql`
    {
    totalObjects: accessControlObjects(${filterAccessUserIds}${filterDataSourceIds}${filterIsOwner}${filterOpenAccess} ${filterAccessGroupIds}) {
      count
    }
    totalHighObjects: accessControlObjects(
        ${filters}
        sensitivity: HIGH
    ) {
        count
    }
    totalMedObjects: accessControlObjects(
        ${filters}
        sensitivity: MEDIUM
    ) {
        count
    }
    totalLowObjects: accessControlObjects(
        ${filters}
        sensitivity: LOW
    ) {
        count
    }
}

  `
}

export const mapQueryAccessControlObjectsCardsBySensitivity = (
  raw: any
): AccessControlObjectCardBySensitivity => {
  const { totalHighObjects, totalMedObjects, totalLowObjects, totalObjects } = raw
  const nonSensitiveCount =
    totalObjects?.count - totalHighObjects?.count - totalMedObjects?.count - totalLowObjects?.count
  return {
    [DataSensitivityLevels.High]: totalHighObjects?.count || 0,
    [DataSensitivityLevels.Medium]: totalMedObjects?.count || 0,
    [DataSensitivityLevels.Low]: totalLowObjects?.count || 0,
    totalObjects: totalObjects?.count || 0,
    [DataSensitivityLevels.None]: nonSensitiveCount || 0
  }
}

// Access control global objects
export const queryAccessControlGlobalObjectsSummary = () => {
  const filterSensitivity = `sensitivity: [${SENSITIVE_LABEL.HIGH}, ${SENSITIVE_LABEL.MEDIUM}, ${SENSITIVE_LABEL.LOW}],`

  return gql`
    {
      openAccess: accessControlObjectGroupByDatasource(
        first: 1,
        hasOpenAccess: true,
        ${filterSensitivity}
      ) {
        count
        edges{
          node{
            datasourceId
          }
        }
      }
      excessiveAccess: accessControlObjectGroupByDatasource(
        first: 1,
        hasExcessiveAccess: true
        ${filterSensitivity}
      ) {
        count
        edges{
          node{
            datasourceId
          }
        }
      }
      crossDepartmentAccess: accessControlObjectGroupByDatasource(
        first: 1,
        hasCrossDepartmentAccess: true
        ${filterSensitivity}
      ) {
        count
        edges{
          node{
            datasourceId
          }
        }
      }
    }
  `
}
export const mapQueryAccessControlGlobalObjectsSummary = (
  raw: any
): {
  openAccess: AccessControlObjectSummary
  excessiveAccess: AccessControlObjectSummary
  crossDepartmentsAccess: AccessControlObjectSummary
} => {
  try {
    const openAccess: AccessControlObjectSummary = {
      dataSourcesCount: raw.openAccess?.count || 0
    }
    const excessiveAccess: AccessControlObjectSummary = {
      dataSourcesCount: raw.excessiveAccess?.count || 0
    }
    const crossDepartmentsAccess: AccessControlObjectSummary = {
      dataSourcesCount: raw.crossDepartmentAccess?.count || 0
    }

    return { openAccess, excessiveAccess, crossDepartmentsAccess }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlGlobalObjectsCards = (params: AccessControlObjectsParams) => {
  const cursor = getAfterCursor(params.page || 1, LIMIT_DEFAULT)
  const filterOpenAccess = params.hasOpenAccess ? `hasOpenAccess: ${params.hasOpenAccess},` : ''
  const filterExcessiveAccess = params.hasExcessiveAccess
    ? `hasExcessiveAccess: ${params.hasExcessiveAccess},`
    : ''
  const filterCrossDepartmentsAccess = params.hasCrossDepartmentsAccess
    ? `hasCrossDepartmentAccess: ${params.hasCrossDepartmentsAccess},`
    : ''
  const filterSensitivity = params.sensitivity
    ? `sensitivity: [${params.sensitivity.map((lvl) => lvl.toUpperCase())}],`
    : ''

  return gql`
    {
      accessControlObjectGroupByDatasource(
        first: ${LIMIT_DEFAULT},
        after: "${cursor}",
        ${filterOpenAccess}
        ${filterExcessiveAccess}
        ${filterCrossDepartmentsAccess}
      ) {
        count
        edges {
          node {
            count
            datasource {
              id
              name
              type
            }
          }
        }
      }
      sensitiveData: accessControlObjectGroupByDatasource(
        first: ${LIMIT_DEFAULT},
        after: "${cursor}",
        ${filterOpenAccess}
        ${filterExcessiveAccess}
        ${filterCrossDepartmentsAccess}
        ${filterSensitivity ? filterSensitivity : `sensitivity: [HIGH, MEDIUM, LOW]`}
      ) {
        count
        edges {
          node {
            count
            datasource {
              id
              name
              type
            }
          }
        }
      }
    }
  `
}
export const queryAccessControlEntitiesGroupByType = (
  params: IGetAccessControlUsersFilterParams
) => {
  const { id, datasourceId, groupId } = params

  const filterDataSourceIds = datasourceId ? `datasourceIds: ["${datasourceId}"],` : ''

  const groupIdFilter = groupId ? `accessControlGroupIds: [${groupId}],` : ''
  const userIdFilter = id ? `accessControlUserIds: [${id}],` : ''

  const idFilter = groupId ? groupIdFilter : userIdFilter

  const node = groupId
    ? `accessControlGroupsEntityGroupedByType`
    : `accessControlUsersEntityGroupedByType`

  return gql`
    query accessControl${groupId ? 'Group' : 'User'}EntitiesEntityGroupedByType {
      ${node}(${idFilter} ${filterDataSourceIds}) {
        count
        edges {
          node {
            name
            id
            entities {
              count
            }
          }
        }
      }
      atRisk:  ${node}(${idFilter} ${filterDataSourceIds}, isRisky: true) {
        edges {
          node {
            name
            id
            entities {
              count
            }
          }
        }
      }
    }
  `
}
export const mapQueryAccessControlGlobalObjectsCards = (
  raw: any
): { cards: AccessControlGlobalObjectCard[]; total: number } => {
  try {
    const { accessControlObjectGroupByDatasource, sensitiveData } = raw
    const cards: AccessControlGlobalObjectCard[] = accessControlObjectGroupByDatasource?.edges?.map(
      ({ node }) => {
        const sensitiveCount =
          sensitiveData?.edges?.find(
            ({ node: comparisonNode }) => comparisonNode?.datasource?.id === node?.datasource?.id
          )?.node?.count || 0
        return {
          dataSourceId: node.datasource?.id || '',
          dataSourceName: node.datasource?.name || '',
          dataSourceType: node.datasource?.type,
          objectsCount: node?.count || 0,
          sensitiveObjectsCount: sensitiveCount || 0
        }
      }
    )

    return { cards, total: raw.accessControlObjectGroupByDatasource?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mapAccessControlEntitiesGroupByType = (raw: any): AccessControlUserEntityCard[] => {
  const {
    accessControlUsersEntityGroupedByType,
    accessControlGroupsEntityGroupedByType,
    atRisk
  } = raw
  const rawNode = accessControlGroupsEntityGroupedByType || accessControlUsersEntityGroupedByType
  return rawNode?.edges?.map(({ node }) => ({
    id: node.id,
    name: node.name,
    entitiesCount: node.entities?.count || 0,
    atRiskCount:
      atRisk?.edges?.find(({ node: riskyNode }) => riskyNode?.id === node.id)?.node?.entities
        ?.count || 0,
    associatedDatasources: []
  }))
}

export const queryUserEntitiesGroupByDatasource = (
  entityTypes: { id: number; name: string }[],
  params: IGetAccessControlUsersFilterParams
) => {
  const { datasourceId, groupId, id } = params
  const filterDataSourceIds = datasourceId ? `datasourceIds: ["${datasourceId}"],` : ''
  const filterUserId = id ? `accessControlUserIds: [${id}],` : ''
  const filterGroupId = groupId ? `accessControlGroupIds: [${groupId}],` : ''
  const node = groupId
    ? `accessControlGroupsEntityGroupedByDatasource`
    : `accessControlUsersEntityGroupedByDatasource`
  const queryFragment = (id, name) =>
    gql`
      ${name}: ${node}(entityTypeId: "${id}" ${filterDataSourceIds} ${filterGroupId} ${filterUserId} ) {
        edges {
            node {
                id
                datasource {
                    name
                    type
                }
            }
        }
      }
  `
  const FRAGMENT = entityTypes.map((type) => queryFragment(type.id, type.name)).join('\n')

  return gql`
  query accessControl${groupId ? 'Group' : 'User'}sEntityGroupedByDatasource{
    ${FRAGMENT}
}
  `
}

export const mapAccessControlEntitiesGroupByDatasource = (
  raw: any,
  entityCards: AccessControlUserEntityCard[]
): {
  totalCards: number
  cards: AccessControlUserEntityCard[]
  entitiesAtRisk: number
  entityTypes: number
} => {
  try {
    const entityCardsWithDs = entityCards.map((card) => {
      const ds =
        raw?.[card.name]?.edges?.map(({ node }) => ({
          id: node?.id || '',
          name: node.datasource?.name || '',
          type: node.datasource?.type || ''
        })) || []
      return {
        ...card,
        associatedDatasources: ds
      }
    })

    const entitiesAtRisk = entityCardsWithDs.reduce((acc, card) => acc + card.atRiskCount, 0)

    return {
      totalCards: entityCardsWithDs.length,
      cards: entityCardsWithDs,
      entitiesAtRisk,
      entityTypes: entityCardsWithDs.length
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccess360Widget = (params: IGetAccessControlUsersFilterParams) => {
  const filterStr = getUserFiltersString(params)
  const dsIdsFilter = params.datasourceId ? `datasourceIds: ["${params.datasourceId}"],` : ''
  return gql`
    query access360Widget {
      openAccessObjects: accessControlObjects(hasOpenAccess: true ${dsIdsFilter}) {
        count
      }
      excessiveAccessObjects: accessControlObjects(hasExcessiveAccess: true ${dsIdsFilter}) {
        count
      }
      crossDepartmentAccessObjects: accessControlObjects(hasCrossDepartmentAccess: true ${dsIdsFilter}) {
        count
      }
      objects(isSensitive: true ${dsIdsFilter}) {
        count
      }
      usersWithAccessToEntities: accessControlUsers(hasEntityAccess: true ${filterStr}) {
        count
      }
      accessControlUsers(sensitivity: [HIGH, MEDIUM] ${filterStr}) {
        count
      }
    }
  `
}

export const mapQueryAccess360Widget = (raw: any): Access360Widget => {
  const {
    openAccessObjects,
    excessiveAccessObjects,
    crossDepartmentAccessObjects,
    objects,
    accessControlUsers,
    usersWithAccessToEntities
  } = raw
  return {
    usersCount: accessControlUsers?.count || 0,
    openAccessObjects: openAccessObjects?.count || 0,
    execessiveAccessObjects: excessiveAccessObjects?.count || 0,
    objectsCount: objects?.count || 0,
    crossDepartmentAccessObjects: crossDepartmentAccessObjects?.count || 0,
    usersWithAccessToEntities: usersWithAccessToEntities?.count || 0
  }
}

export const queryEntityTypesWithAccess = (params: IGetAccessControlUsersFilterParams) => {
  const { datasourceId } = params
  return gql`
  query accessControlUsersEntityGroupedByType {
      accessControlUsers(hasEntityAccess: true, datasourceId: "${datasourceId}") {
        count
      }
      accessControlUsersEntityGroupedByType(datasourceIds: "${datasourceId}") {
          edges {
              node {
                  name
                  id
                  entities {
                      count
                  }
                  entitiesCountAccessibleByUser
              }
          }
      }
  }
  `
}

export const mapQueryEntityTypesWithAccess = (raw: any): AccessControlEntityAccessWidget => {
  const { accessControlUsers, accessControlUsersEntityGroupedByType } = raw

  return {
    totalUsers: accessControlUsers?.count || 0,
    entityTypes: accessControlUsersEntityGroupedByType?.edges?.map(({ node }) => ({
      id: node.id,
      name: node.name,
      entityCount: node.entities?.count || 20,
      accessibleEntityCount: node?.entitiesCountAccessibleByUser || 0
    }))
  }
}

export const queryObjectsWithCrossDepartmentAccessWithDepartments = (
  params: IGetAccessControlUsersFilterParams
) => {
  const { datasourceId, sensitivityLevel } = params
  const filterDataSourceIds = `datasourceIds: ["${datasourceId}"]`

  return gql`
    query accessControlObjectsCrossDeptAccessGroupedByDept {
        accessControlObjectsCrossDeptAccessGroupedByDept(
            ${filterDataSourceIds}
            sensitivity: ${sensitivityLevel}
        ) {
            departmentName
            ownerCount
            objectCount
            crossDepartmentAccessInfo {
                userCount
                departmentName
            }
        }
        totalDepartments: accessControlObjectGroupByDepartment(
          hasCrossDepartmentAccess: true
          sensitivity: ${sensitivityLevel}
          ${filterDataSourceIds}
        ) {
          count
        }
        impactedAccessOwners: accessControlObjectGroupByUserOwner(
          hasCrossDepartmentAccess: true
          sensitivity: ${sensitivityLevel}
          ${filterDataSourceIds}
        ) {
            count
        }
        totalObjects: accessControlObjects(
          hasCrossDepartmentAccess: true
          sensitivity: ${sensitivityLevel}
          ${filterDataSourceIds}
        ) {
            count
        }
    }
  `
}

export const mapQueryObjectsWithCrossDepartmentAccessWithDepartments = (
  raw: any
): AccessControlCrossDepartmentAccessWidget => {
  const {
    totalObjects,
    impactedAccessOwners,
    accessControlObjectsCrossDeptAccessGroupedByDept,
    totalDepartments
  } = raw

  return {
    totalObjects: totalObjects?.count || 0,
    totalDepartments: totalDepartments?.count || 0,
    totalOwners: impactedAccessOwners?.count || 0,
    departments: accessControlObjectsCrossDeptAccessGroupedByDept?.map((node) => ({
      name: node?.departmentName || '',
      totalObjects: +node?.objectCount || 0,
      totalOwners: +node?.ownerCount || 0,
      crossDepartments: node?.crossDepartmentAccessInfo?.map((node) => ({
        name: node?.departmentName || '',
        userCount: +node?.userCount || 0
      }))
    }))
  }
}

export const queryAccessibleWidget = (params: IGetAccessControlUsersFilterParams) => {
  const { datasourceId, entityId, attributeId } = params
  const filterDataSourceId = datasourceId ? `datasourceId: "${datasourceId}"` : ''
  if (entityId) {
    return gql`
      query accessibleWidgets {
        userEntities(entityIds: ["${entityId}"], ${filterDataSourceId}) {
          edges {
            node {
                userAccess {
                    count
                }
                groupAccess {
                    count
                }
            }
          }
        }
      }
    `
  } else if (attributeId) {
    // todo add attribute integration
    return ``
  }
  return ``
}

export const mapQueryAccessibleWidget = (raw: any): AccessControlAccessibleWidget => {
  const { userEntities } = raw
  return {
    usersCount: userEntities?.edges?.[0]?.node?.userAccess?.count || 0,
    groupsCount: userEntities?.edges?.[0]?.node?.groupAccess?.count || 0,
    rolesCount: 0,
    resourcesCount: 0
  }
}

export const queryAccessControlFilters = () => {
  return gql`
    query accessControlFilters {
      entityType {
        edges {
          node {
            id
            name
          }
        }
      }
      totalDepartments: accessControlUsersGroupedByDept {
        count
        edges {
          node {
            departmentName
          }
        }
      }
      datasources(isIdpEnabled: true) {
        edges {
          node {
            id
            name
          }
        }
      }
      attribute(booleanFilter: [{ key: ENABLED, value: true }]) {
        edges {
          node {
            id
            name
            internalName
          }
        }
      }
    }
  `
}

export const mapQueryAccessControlFilters = (raw: any): AccessControlFilters => {
  const { entityType, datasources, attribute } = raw
  return {
    entityTypes: entityType?.edges?.map(({ node }) => ({
      id: node.id,
      name: node.name
    })),
    datasources: datasources?.edges?.map(({ node }) => ({
      id: node.id,
      name: node.name
    })),
    departments: raw?.totalDepartments?.edges?.map(({ node }) => node?.departmentName || ''),
    attributes: attribute?.edges?.map(({ node }) => ({
      id: node.id,
      name: node.name,
      internalName: node.internalName
    }))
  }
}

export const queryGroups = (params: IGetAccessControlGroupsFilterParams) => {
  const { entityId, datasourceId } = params
  const filters = getUserFiltersString(params, { hasPagination: true })

  const filterDatasource = datasourceId ? ` ,datasourceId: "${datasourceId}"` : ``

  const query = entityId
    ? gql`
    query accessControlGroups {
      userEntities(id: "${entityId}" ${filterDatasource}) {
          edges {
              node {
                  groupAccess(${filters})  {
                    count
                    edges {
                    node {
                      id
                      name
                      type
                      dataPrivilegeLevel
                      memberUsers {
                        count
                      }
                      memberGroups {
                        count
                      }
                      attributesGroupedByType {
                        count
                      }
                      datasources {
                        count
                      }

                    }
                  }
                  }
                  userAccess{
                      count
                  }
              }
          }
      }
  }
  `
    : gql`
      {
        totalGroups: accessControlGroups${filterDatasource ? `(${filterDatasource})` : ''} {
          count
        }
        highSensitiveCount: accessControlGroups(sensitivity: [HIGH] ${filterDatasource}) {
          count
        }
        accessControlGroups(${filters}) {
          count
          edges {
            node {
              id
              name
              type
              dataPrivilegeLevel
              memberUsers {
                count
              }
              memberGroups {
                count
              }
              attributesGroupedByType {
                edges {
                  node {
                    attribute{
                      edges{
                        node{
                          id
                        }
                      }
                    }
                  }
                }
              }
              datasources {
                count
              }
              dataPrivilegedMembers: memberUsers(sensitivity:[${DataSensitivityLevels.High}]) {
                count
              }
            }
          }
        }
      }
  `
  return query
}

export const mapQueryGroups = (raw: any) => {
  const { accessControlGroups, totalGroups, highSensitiveCount } = raw

  const list = accessControlGroups.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    type: node?.type,
    dataPrivilegeLevel: node?.dataPrivilegeLevel,
    usersCount: node?.memberUsers?.count || 0,
    groupsCount: node?.memberGroups?.count || 0,
    attributesCount: node?.attributesGroupedByType?.edges?.length || 0,
    datasourcesCount: node?.datasources?.count || 0,
    dataPrivilegeMemberCount: node?.dataPrivilegedMembers?.count || 0
  }))

  return {
    list,
    total: accessControlGroups?.count,
    groupsCount: totalGroups?.count,
    highSensitiveCount: highSensitiveCount?.count
  }
}

export const queryGroupOverview = (params: IGetAccessControlGroupsFilterParams) => {
  const { id, datasourceId } = params

  const filterStr = datasourceId ? ` ,datasourceId: "${datasourceId}"` : ``
  const dsKeyfilterStr = datasourceId ? ` ,{ key: DATASOURCE_ID, values: "${datasourceId}"}` : ``

  return gql`
    query groupDetailsOverview {
      entitiesCount: userEntities(
        filter: [{ key: ACCESS_CONTROL_GROUP_IDS, values: ["${id}"]} ${dsKeyfilterStr}]
        booleanFilter: [{key:APPROX_ACCESSORS_COUNT, value: true}]
      ) {
        count
      }
     
      accessControlGroups(id: ${id} ${filterStr}) {
        edges {
          node {
            id
            name
            type
            dataPrivilegeLevel
            memberGroups {
              count
              edges {
                node {
                  id
                  name
                }
              }
            }
            memberUsers {
              count
            }
            objects {
              count
            }
            sensitiveObjects: objects(isSensitive: true) {
              count
            }
            datasources {
              count
            }
            attributesGroupedBySensitivity {
              edges {
                node {
                  count
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryGroupOverview = (raw: any): AccessControlGroupDetailsOverview => {
  const { accessControlGroups, entitiesCount } = raw

  const group = accessControlGroups?.edges?.[0]?.node
  let attributesCount = 0
  group?.attributesGroupedBySensitivity.edges.forEach(({ node }) => {
    attributesCount += node?.count || 0
  })

  const subGroups = group?.memberGroups?.edges.map(({ node }) => {
    return node
  })

  return {
    id: group?.id,
    name: group?.name,
    type: group?.type,
    dataPrivilegeLevel: group?.dataPrivilegeLevel,
    usersCount: group?.memberUsers?.count,
    groupsCount: group?.memberGroups?.count,
    subGroups,
    objectsCount: group?.objects?.count,
    sensitiveObjects: group?.sensitiveObjects?.count || 0,

    datasourcesCount: group?.datasources?.count,
    entitiesCount: entitiesCount?.count || 0,
    attributesCount
  }
}

export const queryUsersByGroup = (params: IGetAccessControlUsersFilterParams) => {
  let filterStr = ''
  const filters = getUserFiltersString(params, { hasPagination: true })
  if (params.groupId) {
    filterStr += `,accessControlGroupIds: [${params.groupId}]`
  }
  return `
    query usersForGroup {
      totalContractors: accessControlUsers(employeeType: ${
        AccessControlEmployeeType.Contractor
      } ${filterStr}) {
        count
      }
      totalEmployees: accessControlUsers(employeeType: ${
        AccessControlEmployeeType.Employee
      }, ${filterStr}) {
        count
      }
      accessControlUsers(${filters + filterStr}) {
        count
        edges {
          node {
            id
            name
            userType
            employeeType
            dataPrivilegeLevel
            memberType
          }
        }
      }
    }
  `
}

export const mapQueryUsersByGroup = (raw: any) => {
  const { totalContractors, totalEmployees, accessControlUsers } = raw

  const usersList = accessControlUsers.edges.map(({ node }) => ({
    id: node?.id,
    name: node?.name,
    employeeType: node?.employeeType,
    dataPrivilegeLevel: node?.dataPrivilegeLevel,
    isDirectMember: node?.memberType === accessControlMemberTypes.Direct
  }))

  return {
    usersList,
    totalUsers: accessControlUsers.count,
    totalContractorsCount: totalContractors.count,
    totalEmployeesCount: totalEmployees.count
  }
}

export const querySubGroups = (params: IGetAccessControlGroupsFilterParams) => {
  let filterStr = ''
  const groupFilters = getGroupFiltersString(params)
  if (params.groupId) {
    filterStr += `id: [${params.groupId}]`
  }
  return gql`
    {
      accessControlGroups(${filterStr}) {
        edges {
          node {
            id
            name
            memberGroups${groupFilters ? `(${groupFilters})` : ''} {
              count
              edges {
                node {
                  id
                  name
                  dataPrivilegeLevel
                  memberUsers {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQuerySubGroups = (raw: any) => {
  const { accessControlGroups } = raw
  const subGroups = accessControlGroups?.edges[0]?.node?.memberGroups?.edges?.map(({ node }) => {
    return {
      id: node?.id,
      name: node?.name,
      dataPrivilegeLevel: node?.dataPrivilegeLevel,
      membersCount: node?.memberUsers?.count
    }
  })
  return {
    list: subGroups,
    total: accessControlGroups?.edges[0]?.node?.memberGroups?.count || 0
  }
}

export const queryGroupsByUser = (params: IGetAccessControlGroupsFilterParams) => {
  const groupFilters = getGroupFiltersString(params)
  return `
    {
      accessControlUsers(id: ${params?.id}) {
        edges {
          node {
            id
            memberOfGroups${groupFilters ? `(${groupFilters})` : ''} {
              count
              edges {
                node {
                  id
                  name
                  dataPrivilegeLevel
                  attributesGroupedByType {
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryGroupsByUser = (raw: any) => {
  const { accessControlUsers } = raw
  const groups = accessControlUsers?.edges[0]?.node.memberOfGroups?.edges.map(({ node }) => {
    return {
      id: node?.id,
      name: node?.name,
      dataPrivilegeLevel: node?.dataPrivilegeLevel,
      attributesCount: node?.attributesGroupedByType?.count
    }
  })
  return {
    groups,
    totalGroups: accessControlUsers?.edges[0]?.node.memberOfGroups?.count
  }
}

export const queryAccessControlIdpDatasources = () => {
  return gql`
    query datasourcesList {
      datasources {
        edges {
          node {
            id
            name
            type
          }
        }
      }
    }
  `
}

export const mapQueryAccessControlIdpDatasources = (raw: any): DataSource[] => {
  const { datasources } = raw
  return datasources?.edges?.map(({ node }) => ({
    id: node.id,
    name: node.name,
    type: node.type
  }))
}

const getFragmentForCronSchedule = (params: IdentityProviderParams) => {
  return `
  cronSchedule: {
    cronType: ${params?.cronSchedule?.cronType}
    humanReadableSchedule: {
      frequencyType: ${params.cronSchedule?.humanReadableSchedule?.frequencyType}
      frequencyValue: "${params.cronSchedule?.humanReadableSchedule?.frequencyValue}"
    }
  }
  `
}

const getFragmentForGoogle = (params: IdentityProviderParams) => {
  return `
    googleWorkspaceConfiguration: {
      delegatedCredential: "${params.delegatedCredential}"
      accountJson: "${params.accountJson}"
    }
    name: "${params.name}"
    ownerEmail: "${params.email}"
    datasourceIds: ${JSON.stringify(params.datasourceIds)}
    ${getFragmentForCronSchedule(params)}
  `
}

const getFragmentForOnPremActiveDirectory = (params: IdentityProviderParams, isUpdate = false) => {
  return `activeDirectoryConfiguration: {
            username: "${params.username}"
            password: "${params.password}"
            endpoint: "${params.endpoint}"
            domain: "${params.domain}"
            ssl: ${params.sslEnabled}
          }
          name: "${params.name}"
          ownerEmail: "${params.email}"
          datasourceIds: ${JSON.stringify(params.datasourceIds)}
          ${getFragmentForCronSchedule(params)}
          ${
            !isUpdate
              ? `entraAd: ${params.entraAd}
          `
              : ''
          }
        `
}

const getFragmentForHostedActiveDirectory = (params: IdentityProviderParams, isUpdate = false) => {
  return (
    params.hostedConfig &&
    `applicationCredentials: {
        clientId: "${params.hostedConfig.clientId}"
        clientSecret: "${params.hostedConfig.clientSecret}"
        tenantId: "${params.hostedConfig.tenantId}"
      }
    name: "${params.name}"
    ownerEmail: "${params.email}"
    datasourceIds: ${JSON.stringify(params.datasourceIds)}
    ${getFragmentForCronSchedule(params)}
    ${
      !isUpdate
        ? `entraAd: ${params.entraAd}
    `
        : ''
    }
        `
  )
}

export const mutationCreateIdentityProvider = (params: IdentityProviderParams) => {
  const { type } = params
  const isGoogle = type === DirectoryOptions.Google
  const isAzureOnPremise = type === DirectoryOptions.OnPremise
  let fragment = ``
  if (isGoogle) {
    fragment = `
      createGoogleWorkspaceIdp(
        clientMutationId: "1",
        googleWorkspaceData: {
          ${getFragmentForGoogle(params)}
        }
      )
    `
  } else {
    fragment = `
     createActiveDirectoryIdp(
        activeDirectoryData: {
          ${
            isAzureOnPremise
              ? getFragmentForOnPremActiveDirectory(params)
              : getFragmentForHostedActiveDirectory(params)
          }
        }
        clientMutationId: "1"
      )
    `
  }

  return gql`
    mutation createActiveDirectoryIdp {
      ${fragment}{
        clientMutationId
        idpId
      }
    }
  `
}

export const queryAccessControlIdpList = (params: IGetAccessControlUsersFilterParams) => {
  const filters = getUserFiltersString(params, { hasPagination: true })
  return gql`
    query accessControlIdentityProviderList {
      identityProvider(${filters}) {
        count
        edges {
          node {
            id
            name
            idpType
            lastSyncedAt
            datasource {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryAccessControlIdpList = (
  raw: any
): {
  list: DirectoryService[]
  total: number
} => {
  try {
    return {
      list: raw.identityProvider?.edges?.map(({ node }) => ({
        id: node?.id || '',
        name: node?.name || '',
        idpType: node?.idpType || '',
        lastSyncedAt: node?.lastSyncedAt || '',
        datasourceCount: node?.datasource?.count || 0
      })),
      total: raw.identityProvider?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessControlIdpById = (id: string) => {
  return gql`
    query accessControlIdentityProviderById {
      identityProvider(filter: [{key: ID, values: ["${id}"]}]) {
        edges {
          node {
            id
            name
            description
            ownerEmail
            # endpoint
            # domain
            idpType
            lastSyncedAt
            cronSchedule {
              humanReadableSchedule{
                frequencyType,
                frequencyValue
              }
              cronType
            }
            datasource {
              count
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAccessControlIdpById = (raw: any): DirectoryService => {
  try {
    const { datasource, ...rest } = raw?.identityProvider?.edges?.[0]?.node
    return {
      ...rest,
      datasources: datasource?.edges?.map(({ node }) => ({
        id: node.id
      })),
      datasourceCount: datasource?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const mutationUpdateIdentityProvider = (params: IdentityProviderParams) => {
  const isGoogle = params.type === DirectoryOptions.Google
  const isAzureOnPremise = params.type === DirectoryOptions.OnPremise
  const updateFragment = isGoogle
    ? getFragmentForGoogle(params)
    : isAzureOnPremise
    ? getFragmentForOnPremActiveDirectory(params, true)
    : getFragmentForHostedActiveDirectory(params, true)
  const { id } = params

  return gql`
    mutation updateIdp {
      updateIdp(
        clientMutationId: "1",
        idpId: "${id}",
        idpData: {
          ${updateFragment}
        }
      ) {
        clientMutationId
      }
    }
  `
}

export const queryAlertsOverviewWidget = (params: IGetAccessControlUsersFilterParams) => {
  const datasourceFilter = params.datasourceId ? `, datasourceIds: ["${params.datasourceId}"],` : ''
  return gql`
    query accessControlAlertsOverview {
      alert(
        first: 50
        severity: ${SEVERITY_LEVEL.critical}
        filter: [
          { key: STATUS, values: ["${ALERT_STATUS_API_MAP[ALERT_STATUS.active]}", "${
    ALERT_STATUS_API_MAP[ALERT_STATUS.resolving]
  }", "${ALERT_STATUS_API_MAP[ALERT_STATUS.muting]}", "${
    ALERT_STATUS_API_MAP[ALERT_STATUS.unmuting]
  }"] }
          { key : POLICY_TYPE, values: ["${POLICY_TYPES.ACCESS_POLICY}"]}
        ]
        ${datasourceFilter}
        ${params.emailId ? `subAlertAssignees: ["${params.emailId}"]` : ''}
      ) {
        edges {
          node {
            id
            name
            status
            severity
            generatedAt
            assignees
            datasource {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAlertsOverviewWidget = (raw: any): AlertsOverviewWidget[] => {
  const { edges } = raw.alert

  const alerts: AlertsOverviewWidget[] = edges.map(({ node }) => {
    const { assignees, datasource: { edges: datasourceEdges = [] } = {} } = node

    const [datasourceItem] = datasourceEdges || []
    const { node: { id: datasourceId = null } = {} } = datasourceItem || {}

    return {
      id: node.id,
      name: node.name,
      status: node.status,
      severity: node.severity,
      generatedAt: node.generatedAt,
      assignees: assignees?.length,
      datasourceId
    }
  })
  return alerts
}
export const mutationTestConnection = (params: TestConnectionParams) => {
  const { type, hostedConfig } = params
  const isGoogle = type === DirectoryOptions.Google
  const isAzureHosted = type === DirectoryOptions.Hosted
  const isAzureOnPremise = type === DirectoryOptions.OnPremise
  if (isGoogle) {
    return gql`
      mutation testConnectionGoogleWorkspaceIdp {
        testConnectionGoogleWorkspaceIdp(
          clientMutationId: "1",
          googleWorkspaceConfiguration: {
          delegatedCredential: "${params.delegatedCredential}"
          accountJson: "${params.accountJson}"
        }) {
          clientMutationId
          success
        }
      }
    `
  } else {
    if (isAzureOnPremise) {
      return gql`
        mutation testConnectionActiveDirectoryIdp {
          testConnectionActiveDirectoryIdp(
            clientMutationId: "1",
            activeDirectoryConfiguration: {
              username: "${params.username}"
              password: "${params.password}"
              endpoint: "${params.endpoint}"
              domain: "${params.domain}"
              ssl: ${params.sslEnabled}
            }) {
            clientMutationId
            success
          }
        }
      `
    } else if (isAzureHosted && hostedConfig) {
      return gql`
        mutation {
          testConnectionActiveDirectoryIdp(
            applicationCredentials: {
              clientId: "${hostedConfig.clientId}"
              clientSecret: "${hostedConfig.clientSecret}"
              tenantId: "${hostedConfig.tenantId}"
            }
            clientMutationId: ""
          ) {
            clientMutationId
            success
          }
        }
      `
    }
    return ``
  }
}

export const queryAccessControlTotalObjectPerUserOrGroup = (params: AccessControlObjectsParams) => {
  const filterDataSourceIds = params.datasourceIds?.length
    ? `datasourceIds: ${JSON.stringify(params.datasourceIds)},`
    : ''

  const filterAccessUserIds = params.accessControlUserIds?.length
    ? `accessControlUserIds: ${JSON.stringify(params.accessControlUserIds)},`
    : ''

  const filterAccessGroupIds = params.accessControlGroupIds?.length
    ? `accessControlGroupIds: ${JSON.stringify(params.accessControlGroupIds)},`
    : ''

  return gql`
   query totalObjects {
    totalObjects:   accessControlObjects(${filterAccessUserIds}${filterDataSourceIds} ${filterAccessGroupIds}) {
      count
    }
   }
    `
}

export const mapQueryAccessControlObjectsTotalObjects = (raw): number => {
  const { totalObjects } = raw
  return totalObjects?.count || 0
}

export const queryAccessControlObjectsAccessViaGroup = (params: AccessControlObjectsParams) => {
  const filterDataSourceIds = params.datasourceIds?.length
    ? `datasourceId: "${params.datasourceIds[0]}",`
    : ''

  const filterAccessUserIds = params.accessControlUserIds?.length
    ? `id: ${params.accessControlUserIds?.[0]},`
    : ''

  const filterAccessGroupIds = params.accessControlGroupIds?.length
    ? `id: ${params.accessControlGroupIds?.[0]},`
    : ''

  const node = filterAccessUserIds ? 'accessControlUsers' : 'accessControlGroups'

  return gql`
  query accessControlObjectsAccessViaGroup {
    ${node}(${filterAccessUserIds}, ${filterDataSourceIds}, ${filterAccessGroupIds}){
    edges{
      node{
        id, 
        name,
        objects {
          count
        }
        memberOfGroups(isDirect: true, sensitivity: [HIGH, MEDIUM, LOW, NONE]){
          count
          edges {
            node {
              id, 
              name,
              objects {
                count
              }
            }
          }
        }
      }
    }
  }
  }
`
}

export const mapQueryAccessControlObjectsAccessViaGroup = (
  raw
): {
  cards: AccessControlGroupCard[]
  total: number
} => {
  const { accessControlUsers, accessControlGroups } = raw
  const rawNode = accessControlGroups || accessControlUsers
  const cards = rawNode?.edges?.[0]?.node?.memberOfGroups?.edges
    ?.map(({ node }) => {
      if (!node) return
      return {
        groupId: node?.id,
        groupName: node?.name,
        objectsCount: node?.objects?.count || 0
      }
    })
    ?.filter((node) => node)
  return {
    cards,
    total: rawNode?.edges?.[0]?.node?.memberOfGroups?.count || 0
  }
}

export const queryEntitiesViaGroup = (params: IGetAccessControlUsersFilterParams) => {
  const filterDataSourceIds = params.datasourceId ? `datasourceId: "${params.datasourceId}",` : ''

  const filterAccessUserIds = params.id ? `id: ${params.id},` : ''

  const filterAccessGroupIds = params.groupId ? `id: ${params.groupId},` : ''

  const node = filterAccessUserIds ? 'accessControlUsers' : 'accessControlGroups'

  return gql`
  query accessControlEntitiesAccessViaGroup {
    ${node}(${filterAccessUserIds}, ${filterDataSourceIds}, ${filterAccessGroupIds}){
    edges{
      node{
        memberOfGroups(isDirect: true, hasEntityAccess: true){
          count
          edges {
            node {
              id, 
              name,
              entities {
                count
              }
            }
          }
        }
      }
    }
  }
  }
`
}

export const mapQueryAccessControlEntitiesViaGroup = (
  raw
): {
  list: AccessControlUserEntities[]
  listTotal: number
  cards: AccessControlUserEntityCard[]
  cardsTotal: number
} => {
  const { accessControlUsers, accessControlGroups } = raw
  const rawNode = accessControlGroups || accessControlUsers
  const cards = rawNode?.edges?.[0]?.node?.memberOfGroups?.edges
    ?.map(({ node }) => {
      if (!node) return
      return {
        groupId: node?.id,
        name: node?.name,
        entitiesCount: node?.entities?.count || 0
      }
    })
    ?.filter((node) => node)
  return {
    list: [],
    listTotal: 0,
    cards,
    cardsTotal: rawNode?.edges?.[0]?.node?.memberOfGroups?.count || 0
  }
}

export const queryAttributesViaGroup = (params: IGetAccessControlUsersFilterParams) => {
  const filterDataSourceIds = params.datasourceId ? `datasourceId: "${params.datasourceId}",` : ''

  const filterAccessUserIds = params.id ? `id: ${params.id},` : ''

  const filterAccessGroupIds = params.groupId ? `id: ${params.groupId},` : ''

  const node = filterAccessUserIds ? 'accessControlUsers' : 'accessControlGroups'

  return gql`
  query accessControlAttributesAccessViaGroup {
    ${node}(${filterAccessUserIds}, ${filterDataSourceIds}, ${filterAccessGroupIds}){
    edges{
      node{
        memberOfGroups(isDirect: true, hasAttributeAccess: true){
          count
          edges {
            node {
              id, 
              name,
              attributesGroupedByType {
                count
              }
              attributesGroupedBySensitivity {
                edges {
                  node {
                    attributeSensitivity
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }
`
}

export const mapQueryAttributesViaGroup = (
  raw
): {
  cards: AccessControlGroupCard[]
  cardsTotal: number
  widgets?: AccessControlUserAttributesWidget[]
} => {
  const { accessControlUsers, accessControlGroups } = raw
  const rawNode = accessControlGroups || accessControlUsers
  const highSensitiveCount = rawNode?.edges?.[0]?.node?.memberOfGroups?.edges?.reduce(
    (acc, { node }) => {
      const matchingNodes = node?.attributesGroupedBySensitivity?.edges?.filter(
        ({ node }) => node?.attributeSensitivity === DataSensitivityLevels.High
      )
      return acc + matchingNodes?.reduce((acc, curr) => acc + curr?.node?.count, 0) || 0
    },
    0
  )

  const mediumSensitiveCount = rawNode?.edges?.[0]?.node?.memberOfGroups?.edges?.reduce(
    (acc, { node }) => {
      const matchingNodes = node?.attributesGroupedBySensitivity?.edges?.filter(
        ({ node }) => node?.attributeSensitivity === DataSensitivityLevels.Medium
      )
      return acc + matchingNodes?.reduce((acc, curr) => acc + curr?.node?.count, 0) || 0
    },
    0
  )

  const lowSensitiveCount = rawNode?.edges?.[0]?.node?.memberOfGroups?.edges?.reduce(
    (acc, { node }) => {
      const matchingNodes = node?.attributesGroupedBySensitivity?.edges?.filter(
        ({ node }) => node?.attributeSensitivity === DataSensitivityLevels.Low
      )
      return acc + matchingNodes?.reduce((acc, curr) => acc + curr?.node?.count, 0) || 0
    },
    0
  )

  const widgetData: AccessControlUserAttributesWidget[] = [
    {
      attributeSensitivity: DataSensitivityLevels.High,
      count: highSensitiveCount
    },
    {
      attributeSensitivity: DataSensitivityLevels.Medium,
      count: mediumSensitiveCount
    },
    {
      attributeSensitivity: DataSensitivityLevels.Low,
      count: lowSensitiveCount
    }
  ]

  const cards = rawNode?.edges?.[0]?.node?.memberOfGroups?.edges
    ?.map(({ node }) => {
      if (!node) return
      return {
        groupId: node?.id,
        name: node?.name,
        attributesCount: node?.attributesGroupedByType?.count || 0
      }
    })
    ?.filter((node) => node)

  return {
    cards,
    cardsTotal: rawNode?.edges?.[0]?.node?.memberOfGroups?.count || 0,
    widgets: widgetData
  }
}

import {
  DRIVE_ID,
  IS_ALL,
  IS_AT_RISK,
  IS_SENSITIVE,
  META_URL,
  PAGE,
  SORT_BY,
  SORT_DIRECTION,
  URL_DATA_SOURCES_UNSTRUCTURED,
  URL_DATA_SOURCES_STRUCTURED,
  URL_DOCUMENTS_CLASSIFIED,
  URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES,
  URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES,
  URL_DRIVES,
  URL_EMAILS,
  URL_ENTITIES,
  URL_MESSAGES,
  ACTIVE_TAB,
  START_DATE,
  END_DATE,
  IS_DRAFT,
  FILTER_DANGER_KEY,
  FILTER_DANGER_VALUES,
  FILTER_TEMPLATE_KEY,
  FILTER_DATA_SOURCE_KEY,
  FILTER_CLASS_KEY,
  FILTER_SUB_CLASS_KEY,
  API_PARAM_TEMPLATE_NAME,
  API_PARAM_DATA_SOURCE_TYPE,
  API_PARAM_CLASS,
  API_PARAM_SUB_CLASS,
  FILTER_FILE_TYPE_KEY,
  API_PARAM_FILE_TYPE,
  PAGE_VIEW_VALUES,
  PAGE_VIEW_KEY,
  URL_DATABASES,
  URL_TABLES,
  IS_UNRESOLVED,
  IS_RESOLVED,
  DATABASE_ID,
  TABLE_ID,
  ENTITY_ID,
  BLOB_ID,
  URL_INSIGHTS,
  API_PARAM_ATTRIBUTE,
  FILTER_ATTRIBUTE_KEY,
  FILTER_RISK_KEY,
  FILTER_RESIDENCY_KEY,
  FILTER_SENSITIVITY_MULTIPLE,
  FILTER_CONSENT_EVENT,
  FILTER_CONSENT_PURPOSE,
  FILTER_ENTITY_TYPE,
  FILTER_LOCATION,
  FILTER_ACCESS,
  FILTER_RISK_LEVEL_KEY,
  FILTER_RISK_TYPE_KEY,
  FILTER_ATTRIBUTE_NAME,
  FILTER_ATTRIBUTE_SET,
  FILTER_DATABASE,
  FILTER_COLUMNS_STATUS,
  FILTER_CHANGE_TYPE,
  FILTER_PII,
  FILTER_REVIEW,
  FILTER_DATASOURCES_IDS,
  FILTER_CHANNEL_NAME,
  FILTER_CHANNEL_TYPE,
  FILTER_CHANNEL_ID,
  FILTER_TEAM_ID,
  FILTER_SEVERITY_KEY,
  FILTER_DATA_SOURCE_TYPE_KEY,
  FILTER_POLICY_TYPE,
  FILTER_ALERT_STATUS,
  DATA_SOURCE_TYPES,
  SEVERITY_LEVEL,
  POLICY_TYPES,
  URL_ALERTS,
  ALERT_ID,
  DATA_SOURCE_ID,
  DOCUMENT_ID,
  URL_POLICIES,
  FILTER_EMAIL_KEY,
  ALERT_STATUS,
  DATA_SOURCE_TYPE,
  PREVIEW,
  ANNOTATION_TYPE,
  FILTER_ATTRIBUTE_HAS_INSTANCES,
  FILTER_POLICY_TYPES_KEY,
  FILTER_RULESET_ID_KEY,
  FILTER_OWNER_KEY,
  FILTER_PROCESS_KEY,
  FILTER_DATA_SOURCE_STATUS,
  FILTER_ALERT_KEY,
  FILTER_SKIP_KEY,
  FILTER_DRIVES_KEY,
  FILTER_SHARED_KEY,
  URL_DATA_SOURCES,
  FILTER_FROM_EMAIL_KEY,
  FILTER_MAIL_RECIPIENT,
  FILTER_CLUSTER,
  URL_TABLE_CLUSTERS,
  CLUSTER_ID,
  FILTER_PROJECT_ID,
  GROUP_BY,
  FILTER_REPORTER_EMAIL,
  FILTER_ASSIGNEE_EMAIL,
  FILTER_HAS_ATTACHMENT,
  FILTER_PROJECT_IDS,
  URL_BUCKETS,
  BUCKET_ID,
  FILTER_BUCKET_KEY,
  FILTER_BLOB_PARENT,
  IS_FORMS,
  IS_TEMPLATES,
  IS_REQUESTS,
  FILTER_HAS_ENTITY_ACCESS,
  FILTER_MODULE_TYPE,
  FILTER_DSR_REQUEST_ASSIGNEE,
  FILTER_DSR_REQUEST_STEP,
  FILTER_DSR_REQUEST_TEMPLATE,
  FILTER_DSR_REQUEST_TYPE,
  FILTER_IDENTIFIER,
  FILTER_NO_OF_DATA_SOURCES,
  FILTER_DATA_SOURCE_NAME,
  FILTER_DATA_SOURCE_ID_KEY,
  FILTER_DATASOURCE_CATEGORY,
  FILTER_DATA_SOURCE_OWNER,
  IS_CLOSED,
  IS_IN_PROGRESS,
  FILTER_RETAIN_UNTIL,
  TICKET_ID,
  CONVERSATION_DOCUMENT_ID,
  ACTIVE_OBJECT_VIEWER_TAB,
  FILTER_STATUS_KEY,
  FILTER_SCHEMA,
  SEARCH_QUERY,
  URL_FOLDERS,
  URL_SHARES,
  SHARE_ID,
  TEMPLATE_ID,
  FILTER_ACCESS_TYPE_KEY,
  API_PARAM_ACCESS_TYPE,
  FILTER_EVENT_TYPE,
  FILTER_REQUEST_TYPE,
  FILTER_REVIEW_SCHEDULE,
  FILTER_RISK_LEVEL,
  FILTER_REVIEW_STATUS,
  URL_TEMPLATES,
  FILTER_LABEL_KEY,
  URL_BLOB,
  FILTER_COMMON_TYPE,
  FILTER_ATTRIBUTE_ID,
  FILTER_TABLE,
  FILTER_ENTITY,
  RETURN_URL,
  FILTER_PROCESS_GROUP,
  FILTER_TEMPLATE_IDS,
  FILTER_ORG_ROLE,
  FILTER_LAST_MODIFIED_TIME_KEY,
  FILTER_RULE_SET_KEY,
  FILTER_LEGAL_HOLD_KEY,
  FILTER_RETENTION_VIOLATION,
  FILTER_EMPLOYEE_TYPE,
  FILTER_DEPARTMENT,
  FILTER_USER_ID,
  USER_NAME,
  FILTER_IS_OWNER,
  URL_USERS,
  USER_ID,
  FILTER_MAILBOX_KEY,
  FILTER_RECIPIENT_EMAIL_KEY,
  FILTER_MAIL_KEY,
  NOTIFICATION_TYPES,
  URL_REQUEST,
  URL_DSR,
  URL_DSR_REQUEST_MANAGEMENT,
  DSR_REQUEST_STEP,
  URL_ROPA,
  URL_OVERVIEW,
  ASSIGNEE,
  URL_TICKETS,
  URL_DETAIL,
  POLICY_TYPE,
  PAGE_SIZE,
  LIMIT_DEFAULT,
  FILTER_SKIP_REASON_KEY,
  FILTER_SKIP_REASON_CATEGORY_KEY,
  URL_PIA,
  FILTER_MAIL_FOLDER_TYPE,
  MAIL_FOLDER_TYPE,
  FILTER_FILE_TYPE,
  FILTER_FILE_SIZE,
  FILTER_LAST_UPDATED,
  FILTER_SITES,
  FILTER_SCAN_STATUS,
  FROM,
  URL_LOGIN,
  URL_GROUPS,
  GROUP_ID,
  FILTER_MEMBER_TYPE,
  FILTER_ACTIONS_KEY,
  FILTER_MODULES_TYPE,
  FILTER_DATE_RANGE_KEY,
  FILTER_ATTRIBUTE_NAMES_KEY,
  FILTER_CLOUD_PLATFORM_ID,
  FILTER_CLOUD_ACCOUNT_ID,
  FILTER_REGION,
  FILTER_DUE_DATE_CUSTOM_KEY,
  FILTER_ACCESS_MODES,
  URL_FILES,
  URL_NOTIFICATIONS,
  URL_DASHBOARD,
  FILTET_ENTITY_KEY,
  FILTER_RISK_DENSITY,
  FILTER_RISK_SCORE,
  FILTER_SENSITIVITY,
  FILTER_CREATED_AT,
  FILTER_FILE_CATEGORY,
  URL_CUSTOM_REPORTS,
  CUSTOM_REPORT_ID,
  FILTER_ACTION_STATE,
  FILTER_ACTION_TAKEN_BY,
  FILTER_DATA_EXTENSIONS_KEY,
  FILTER_BUSINESS_UNITS_KEY,
  FILTER_HAS_MEMBERS
} from '../constants'
import history from '../services/historyService'
import {
  ANNOTATOR_TABS_CONVERSATION,
  ContextFilters,
  ContextPageFilters,
  SORT_ORDER
} from '../interfaces'
import { DSR_REQUEST_ID } from '../features/dsr/dsrForms/dsrFormsSlice'
import { FileType } from '../features/filters/filtersSlice'
import { RootState } from '../rootReducer'
import { IGlobalState } from '../reducers/globalReducer'
import { removeFalsyValues } from '../utils'
import { stringify, parse } from 'query-string'
import { Location } from 'history'
import dayjs from 'dayjs'

interface IAnyObject {
  [k: string]: string
}

export const serializeQueryParams = (currentQuery: IAnyObject, params: IAnyObject): string => {
  return '?' + stringify({ ...currentQuery, ...params })
}
export const getUrlParam = (key: string) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string)
  })
  return params[key]
}
const mapUrlToSourceIdKey = {
  [URL_DATA_SOURCES]: DATA_SOURCE_ID,
  [URL_USERS]: USER_ID,
  [URL_GROUPS]: GROUP_ID,
  [URL_DATA_SOURCES_UNSTRUCTURED]: DATA_SOURCE_ID,
  [URL_DATA_SOURCES_STRUCTURED]: DATA_SOURCE_ID,
  [URL_ALERTS]: ALERT_ID,
  [URL_POLICIES]: ALERT_ID,
  [URL_ENTITIES]: ENTITY_ID,
  [URL_BUCKETS]: BUCKET_ID,
  [URL_SHARES]: SHARE_ID,
  [URL_TEMPLATES]: TEMPLATE_ID,
  [URL_FOLDERS]: 'folderId',
  [URL_DRIVES]: 'driveId',
  [URL_EMAILS]: 'mailId',
  [URL_MESSAGES]: 'messageId',
  [URL_DOCUMENTS_CLASSIFIED]: DOCUMENT_ID,
  [URL_FILES]: DOCUMENT_ID,
  [URL_DATABASES]: DATABASE_ID,
  [URL_DOCUMENTS_UNCLASSIFIED_WITH_ENTITIES]: DOCUMENT_ID,
  [URL_DOCUMENTS_UNCLASSIFIED_WITHOUT_ENTITIES]: DOCUMENT_ID,
  [URL_TABLES]: TABLE_ID,
  [URL_TABLE_CLUSTERS]: CLUSTER_ID,
  [URL_BLOB]: BLOB_ID,
  [URL_CUSTOM_REPORTS]: CUSTOM_REPORT_ID
}

export type UrlContextParams = {
  pathname: string
  contextFilters: ContextFilters
  queryParams: { [key: string]: string | number | boolean }
  search: string
  listUrl: string
  sourceId: string
  pageFilters: PageFilters
  filters: Filters
  pageSort?: string
}

export const getContextParamsFromUrl = (
  { pathname, search }: Location,
  params?: { pageName: string }
): UrlContextParams => {
  // :list/[:dataSourceType]/:sourceId/:subPage?:subFilters&:metaUrl
  const pathNameParsed: string[] = pathname.split('/').filter((path) => !!path)
  const queryParams = parse(search) as UrlContextParams['queryParams']
  const masterUrl = queryParams[META_URL] || ''
  const ignoreFirstPath = '/' + pathNameParsed[0] === URL_INSIGHTS
  const listUrl = '/' + pathNameParsed[ignoreFirstPath ? 1 : 0]
  const sourceId = mapUrlToSourceIdKey[listUrl]
    ? pathNameParsed[ignoreFirstPath || listUrl === URL_POLICIES ? 2 : 1]
    : ''

  const contextFilters = getContextFilters(masterUrl + pathname)

  if (queryParams[DRIVE_ID]) {
    contextFilters.driveId = queryParams[DRIVE_ID] as string
  }
  if (queryParams[DATA_SOURCE_ID]) {
    contextFilters[DATA_SOURCE_ID] = queryParams[DATA_SOURCE_ID] as string
  }
  if (contextFilters.bucketId) {
    contextFilters[BUCKET_ID] = decodeURIComponent(contextFilters.bucketId)
  }
  const pageQueryString = queryParams[params?.pageName || '']
  const pageSubFilters = getFilters(pageQueryString as string)
  let sortKey
  if (pageSubFilters && pageSubFilters[SORT_BY]) {
    sortKey = pageSubFilters[SORT_BY]
    delete pageSubFilters[SORT_BY]
  }

  const contextObj: UrlContextParams = {
    pathname,
    search,
    contextFilters,
    queryParams,
    listUrl,
    sourceId,
    pageFilters: getPageFilters(pageQueryString as string),
    filters: pageSubFilters
  }
  if (sortKey) {
    contextObj.pageSort = sortKey
  }
  return contextObj
}

/** Context filters */
export const getContextFilters = (url: string): ContextFilters => {
  const urlArray: string[] = url.split('/').filter((path) => !!path)

  const filters = {}
  urlArray.forEach((page, i) => {
    if (i === 0) return
    const sourceIdKey = mapUrlToSourceIdKey['/' + urlArray[i - 1]]

    if (sourceIdKey) {
      filters[sourceIdKey] = page
    }
  })

  return filters
}

/** Page filters v2 - remove after filters pane implementation on all pages */
type PageFiltersRaw = {
  isAtRisk?: boolean
  isSensitive?: boolean
  isUnresolved?: boolean
  isResolved?: boolean

  page?: number
  isAtRisk_page?: number
  isSensitive_page?: number

  sortDir?: SORT_ORDER
  isAtRisk_sortDir?: SORT_ORDER
  isSensitive_sortDir?: SORT_ORDER

  sortBy?: string
  isAtRisk_sortBy?: string
  isSensitive_sortBy?: string
}

type TabFilters = {
  [PAGE]: number
  [SORT_DIRECTION]: SORT_ORDER
  [SORT_BY]?: string
  [START_DATE]?: string
  [END_DATE]?: string
}

export type PageFilters = {
  [ACTIVE_TAB]:
    | 'all'
    | 'isSensitive'
    | 'isAtRisk'
    | 'isDraft'
    | 'isUnresolved'
    | 'isResolved'
    | typeof IS_FORMS
    | typeof IS_TEMPLATES
    | typeof IS_REQUESTS
    | 'isCompanies'
    | 'isGroups'
    | 'isActivities'
  [IS_ALL]: TabFilters
  [IS_AT_RISK]: TabFilters
  [IS_SENSITIVE]: TabFilters
  [IS_DRAFT]?: TabFilters
  [IS_RESOLVED]: TabFilters
  [IS_UNRESOLVED]: TabFilters
  [IS_FORMS]: TabFilters
  [IS_TEMPLATES]: TabFilters
  [IS_REQUESTS]: TabFilters
  [DATA_SOURCE_ID]?: string
  [DSR_REQUEST_ID]?: string
  [CONVERSATION_DOCUMENT_ID]?: string
  [ACTIVE_OBJECT_VIEWER_TAB]?: ANNOTATOR_TABS_CONVERSATION
  [SORT_BY]?: string
}

const getPageFilters = (query = ''): PageFilters => {
  // we should try to keep filters flat (0 levels of nesting) to prevent parsing bugs
  // isAtRisk:true,isAtRisk_page:1,isAtRisk_sortDir:desc,isAtRisk_sortBy:name
  const pageQueryObj: ContextPageFilters = {}
  query.split(';').forEach((pair) => {
    const [key, value] = pair.split(':')
    pageQueryObj[key] = value
  })

  // TODO: refactor ACTIVE_TAB param. It should be empty string by default, now it is 'all'
  // TODO: include into ACTIVE_TAB options from all other tabs (ACTIVE_OBJECT_VIEWER_TAB etc)
  let activeTab: PageFilters['activeTab'] = IS_ALL
  if (pageQueryObj[IS_AT_RISK]) activeTab = IS_AT_RISK
  if (pageQueryObj[IS_SENSITIVE]) activeTab = IS_SENSITIVE
  if (pageQueryObj[IS_DRAFT]) activeTab = IS_DRAFT
  if (pageQueryObj[IS_RESOLVED]) activeTab = IS_RESOLVED
  if (pageQueryObj[IS_UNRESOLVED]) activeTab = IS_UNRESOLVED
  if (pageQueryObj[IS_FORMS]) activeTab = IS_FORMS
  if (pageQueryObj[IS_TEMPLATES]) activeTab = IS_TEMPLATES
  if (pageQueryObj[IS_REQUESTS]) activeTab = IS_REQUESTS
  if (pageQueryObj[IS_UNRESOLVED]) activeTab = IS_UNRESOLVED

  const pageFilters: PageFilters = {
    ...pageQueryObj,
    [ACTIVE_TAB]: activeTab,
    [IS_ALL]: buildPageTabFilters(pageQueryObj),
    [IS_AT_RISK]: buildPageTabFilters(pageQueryObj, IS_AT_RISK),
    [IS_SENSITIVE]: buildPageTabFilters(pageQueryObj, IS_SENSITIVE),
    [IS_UNRESOLVED]: buildPageTabFilters(pageQueryObj, IS_UNRESOLVED),
    [IS_RESOLVED]: buildPageTabFilters(pageQueryObj, IS_RESOLVED),
    [IS_FORMS]: buildPageTabFilters(pageQueryObj, IS_FORMS),
    [IS_TEMPLATES]: buildPageTabFilters(pageQueryObj, IS_TEMPLATES),
    [IS_REQUESTS]: buildPageTabFilters(pageQueryObj, IS_REQUESTS)
  }

  if (activeTab === pageQueryObj[IS_DRAFT]) {
    pageFilters[IS_DRAFT] = buildPageTabFilters(pageQueryObj, IS_SENSITIVE)
  }

  return pageFilters
}

const buildPageTabFilters = (source: ContextPageFilters, tabName?: string): TabFilters => {
  const tabKey = tabName ? tabName + '_' : ''

  const tabSettings = {
    [PAGE]: source[tabKey + PAGE] || 1,
    [PAGE_SIZE]: source[tabKey + PAGE_SIZE] || LIMIT_DEFAULT,
    [SORT_DIRECTION]: source[tabKey + SORT_DIRECTION] || SORT_ORDER.DESC
  }

  if (source[tabKey + SORT_BY]) tabSettings[SORT_BY] = source[tabKey + SORT_BY]
  if (source[tabKey + START_DATE]) tabSettings[START_DATE] = source[tabKey + START_DATE]
  if (source[tabKey + END_DATE]) tabSettings[END_DATE] = source[tabKey + END_DATE]

  return tabSettings
}

type UpdatePageFilters = {
  [ACTIVE_TAB]?: string
  [PAGE_SIZE]?: number
  [PAGE]?: number
  [SORT_BY]?: string
  [SORT_DIRECTION]?: SORT_ORDER
  [START_DATE]?: string
  [END_DATE]?: string
  [GROUP_BY]?: string
}

export const updateUrlPageFilters = (params: {
  pageName: string
  pageFilters: UpdatePageFilters
}): void => {
  const { pageName, pageFilters } = params

  // { datasourceType: "gdrive", /files: "isAtRisk:true,isAtRisk_page:2,isAtRisk_sortDir:ascending" }
  const queryParams = parse(history.location.search) || {}

  // isAtRisk:true,isAtRisk_page:3,isAtRisk_sortDir:ascending}
  const oldPageFiltersStr = (queryParams[pageName] || '') as string

  // {isAtRisk: "true", isAtRisk_page: "3", isAtRisk_sortDir: "ascending"}
  const pageQueryObj: PageFiltersRaw = {}
  if (oldPageFiltersStr) {
    oldPageFiltersStr.split(';').forEach((pair) => {
      const [key, value] = pair.split(':')
      pageQueryObj[key] = value
    })
  }

  // replace isAtRisk/isSensitive if needed. The rest tab-specific filters remain the same
  const newPageFilters: PageFiltersRaw = { ...pageQueryObj }
  if (pageFilters[ACTIVE_TAB]) {
    delete newPageFilters[IS_ALL]
    delete newPageFilters[IS_AT_RISK]
    delete newPageFilters[IS_SENSITIVE]
    delete newPageFilters[IS_DRAFT]
    delete newPageFilters[IS_RESOLVED]
    delete newPageFilters[IS_UNRESOLVED]
    delete newPageFilters[IS_FORMS]
    delete newPageFilters[IS_TEMPLATES]
    delete newPageFilters[IS_REQUESTS]
    delete newPageFilters[GROUP_BY]

    newPageFilters[pageFilters[ACTIVE_TAB] as string] = true
  }

  // add or replace tab-specific filters
  const tabPrefix =
    !pageFilters[ACTIVE_TAB] || pageFilters[ACTIVE_TAB] === IS_ALL
      ? ''
      : pageFilters[ACTIVE_TAB] + '_'

  if (pageFilters[PAGE]) newPageFilters[tabPrefix + PAGE] = pageFilters[PAGE]
  if (pageFilters[PAGE_SIZE]) newPageFilters[tabPrefix + PAGE_SIZE] = pageFilters[PAGE_SIZE]
  if (pageFilters[SORT_BY]) newPageFilters[tabPrefix + SORT_BY] = pageFilters[SORT_BY]
  if (pageFilters[GROUP_BY]) newPageFilters[tabPrefix + GROUP_BY] = pageFilters[GROUP_BY]
  if (pageFilters[SORT_DIRECTION])
    newPageFilters[tabPrefix + SORT_DIRECTION] = pageFilters[SORT_DIRECTION]
  if (pageFilters[START_DATE]) newPageFilters[tabPrefix + START_DATE] = pageFilters[START_DATE]
  if (pageFilters[END_DATE]) newPageFilters[tabPrefix + END_DATE] = pageFilters[END_DATE]

  const newQuery = { [pageName]: stringifyPageFilters(newPageFilters) }

  history.push({ pathname: '', search: '?' + stringify({ ...queryParams, ...newQuery }) })
}

/** Page filters v3 (filters pane) */
export type Filters = {
  [PAGE]?: number
  [PAGE_SIZE]?: number
  [FILTER_DANGER_KEY]?: FILTER_DANGER_VALUES
  [FILTER_TEMPLATE_KEY]?: string[]
  [FILTER_DATA_SOURCE_KEY]?: string[]
  [FILTER_CLASS_KEY]?: string[]
  [FILTER_SUB_CLASS_KEY]?: string[]
  [FILTER_FILE_TYPE_KEY]?: string[]
  [FILTER_STATUS_KEY]?: string[]
  [SORT_DIRECTION]?: SORT_ORDER
  [SORT_BY]?: string
  [START_DATE]?: string
  [FILTER_CHANNEL_NAME]?: string
  [START_DATE]?: string
  [END_DATE]?: string
  [IS_DRAFT]?: boolean
  [PAGE_VIEW_KEY]?: PAGE_VIEW_VALUES
  [FILTER_MODULES_TYPE]?: string[]
  [FILTER_ACTIONS_KEY]?: string[]
  [FILTER_DATE_RANGE_KEY]?: string
  [FILTER_ATTRIBUTE_NAMES_KEY]?: string[]
  link?: string
  postedOn?: string
}

export type FiltersApi = {
  [PAGE]?: number
  [PAGE_SIZE]?: number
  [IS_SENSITIVE]?: boolean
  [IS_AT_RISK]?: boolean
  [API_PARAM_TEMPLATE_NAME]?: string
  [API_PARAM_DATA_SOURCE_TYPE]?: string
  [API_PARAM_CLASS]?: string
  [API_PARAM_ACCESS_TYPE]?: string
  [API_PARAM_SUB_CLASS]?: string
  [API_PARAM_FILE_TYPE]?: string
  [API_PARAM_ATTRIBUTE]?: string
}

export const parsePageFilters = (query: string): ContextPageFilters => {
  if (!query || typeof query !== 'string') return {}

  // pageFilters: isAtRisk:true,sortOrder:desc,sortColumn:name
  const pageQueryObj: ContextPageFilters = {}
  query.split(';').forEach((pair) => {
    const [key, value] = pair.split(':')
    pageQueryObj[key] = value
  })

  return pageQueryObj
}

export const getFilters = (query: string | number | boolean): Filters => {
  if (!query || typeof query !== 'string' || query.trim().length === 0) return {}

  // incoming: danger:isAtRisk;page:2;class:invoice,financial_document;template:gusto_paystub
  // result: {danger: "isAtRisk", page: "2", class: ["invoice", "financial document"], template: ["gusto_paystub"]}
  const pageQueryObj = {}
  query.split(';').forEach((pair) => {
    const [key, value] = pair.split(':')
    pageQueryObj[key] = key.endsWith('[]') ? value.split(',') : value
  })

  return pageQueryObj
}

export const managePageFilters = (params: {
  pageName: string
  pathname?: string
  filterKey: keyof Filters | string
  filterValues: string[]
  action: 'add' | 'remove'
  toggle?: boolean
}): void => {
  const { pathname = '', pageName, filterKey, filterValues, action, toggle = false } = params

  // { datasourceType: "gdrive", /files: "danger:isAtRisk;page:2;class:invoice,financial_document;template:gusto_paystub" }
  const queryParams = parse(history.location.search) || {}

  // danger:isAtRisk;page:2;class:invoice,financial_document;template:gusto_paystub
  const currentFiltersStr = (queryParams[pageName] || '') as string

  // {danger: "isAtRisk", page: "3", sortDir: "ascending", class: ["invoice", "financial document"], template: ["gusto_paystub"]}
  const pageQueryObj = currentFiltersStr ? getFilters(currentFiltersStr) : {}

  if (action === 'add') {
    if (filterKey && filterKey.endsWith('[]')) {
      pageQueryObj[filterKey] = toggle
        ? [filterValues]
        : [...new Set([...(pageQueryObj[filterKey] || []), ...filterValues])]
    } else {
      pageQueryObj[filterKey] = filterValues.join(',')
    }
  }

  if (action === 'remove') {
    if (filterKey.endsWith('[]') && pageQueryObj[filterKey] && pageQueryObj[filterKey].length > 1) {
      if (toggle) {
        delete pageQueryObj[filterKey]
      } else {
        const newValues = pageQueryObj[filterKey].filter((val) => !filterValues.includes(val))
        newValues.length > 0
          ? (pageQueryObj[filterKey] = newValues)
          : delete pageQueryObj[filterKey]
      }
    } else {
      delete pageQueryObj[filterKey]
    }
  }

  // remove page filter to be sure that data is visible
  if (pageQueryObj[PAGE] && filterKey !== PAGE) {
    delete pageQueryObj[PAGE]
  }

  history.push({
    pathname,
    search: '?' + stringify({ ...queryParams, [pageName]: stringifyPageFilters(pageQueryObj) })
  })
}

export interface IPageFilter {
  filterKey: keyof Filters | string
  filterValues: string[]
  action: 'add' | 'remove' | 'replace'
}
export const managePageFiltersMultiple = (params: {
  pageName: string
  pathname?: string
  resetFilters?: boolean
  filters: IPageFilter[]
  newQueryParams?: { [key: string]: string | number | boolean }
}): void => {
  const { pageName, pathname, filters, resetFilters = false, newQueryParams } = params

  // { datasourceType: "gdrive", /files: "danger:isAtRisk;page:2;class:invoice,financial_document;template:gusto_paystub" }
  const queryParams = newQueryParams ? newQueryParams : parse(history.location.search) || {}

  // danger:isAtRisk;page:2;class:invoice,financial_document;template:gusto_paystub
  const currentFiltersStr = (queryParams[pageName] || '') as string

  // {danger: "isAtRisk", page: "3", sortDir: "ascending", class: ["invoice", "financial document"], template: ["gusto_paystub"]}
  const pageQueryObj = !resetFilters && currentFiltersStr ? getFilters(currentFiltersStr) : {}

  filters.forEach((filter) => {
    const { filterKey, filterValues, action } = filter

    if (action === 'add' || action === 'replace') {
      if (filterKey && filterKey.endsWith('[]')) {
        const currentValues = action === 'replace' ? [] : pageQueryObj[filterKey] || []
        pageQueryObj[filterKey] = [...new Set([...currentValues, ...filterValues])]
      } else {
        pageQueryObj[filterKey] = filterValues.join(',')
      }
    }

    if (action === 'remove') {
      if (filterKey.endsWith('[]') && filterValues?.length && pageQueryObj[filterKey]?.length) {
        const newValues = pageQueryObj[filterKey].filter((val) => !filterValues.includes(val))
        newValues.length > 0
          ? (pageQueryObj[filterKey] = newValues)
          : delete pageQueryObj[filterKey]
      } else {
        delete pageQueryObj[filterKey]
      }
    }
  })

  history.push({
    pathname,
    search: '?' + stringify({ ...queryParams, [pageName]: stringifyPageFilters(pageQueryObj) })
  })
}

export const stringifyPageFilters = (filters: ContextPageFilters): string => {
  return Object.entries(filters)
    .map(([key, value]) => `${key}:${value}`)
    .join(';')
}

//Todo: Make it generic
interface URLFilterTypes extends FiltersApi {
  Risk?: boolean
  Sensitive?: boolean
  risky?: string
  [FILTER_RESIDENCY_KEY]?: string
  [FILTER_SENSITIVITY_MULTIPLE]?: string
  [FILTER_CONSENT_EVENT]?: string
  [FILTER_CONSENT_PURPOSE]?: string
  [FILTER_ENTITY_TYPE]?: string
  [FILTER_LOCATION]?: string
  [FILTER_ACCESS]?: string
  [FILTER_RISK_LEVEL_KEY]?: string
  [FILTER_RISK_TYPE_KEY]?: string
  [FILTER_ATTRIBUTE_NAME]?: string
  [FILTER_PROCESS_GROUP]?: string
  [FILTER_TEMPLATE_IDS]?: string
  [FILTER_ORG_ROLE]?: string
  [FILTER_ATTRIBUTE_ID]?: string
  [FILTER_ATTRIBUTE_SET]?: string
  [FILTER_DATABASE]?: string
  [FILTER_COLUMNS_STATUS]?: string
  [FILTER_CHANGE_TYPE]?: string
  [FILTER_PII]?: string
  [FILTER_COMMON_TYPE]?: string
  [FILTER_REVIEW]?: string
  [FILTER_STATUS_KEY]?: string
  [FILTER_CHANNEL_NAME]?: string
  [FILTER_CHANNEL_ID]?: string
  [FILTER_CHANNEL_TYPE]?: string
  [FILTER_TEAM_ID]?: string
  [FILTER_DATASOURCES_IDS]?: string
  [FILTER_EMAIL_KEY]?: string
  [FILTER_DATA_SOURCE_TYPE_KEY]?: DATA_SOURCE_TYPES
  [FILTER_RULE_SET_KEY]?: string
  [FILTER_SEVERITY_KEY]?: SEVERITY_LEVEL
  [FILTER_POLICY_TYPE]?: POLICY_TYPES
  [FILTER_ALERT_STATUS]?: ALERT_STATUS
  [FILTER_ATTRIBUTE_HAS_INSTANCES]?: string
  [FILTER_POLICY_TYPES_KEY]?: string
  [FILTER_RULESET_ID_KEY]?: string
  [FILTER_SKIP_REASON_KEY]?: string
  [FILTER_SKIP_REASON_CATEGORY_KEY]?: string
  [FILTER_OWNER_KEY]?: string
  [FILTER_PROCESS_KEY]?: string
  [FILTER_BUCKET_KEY]?: string
  [FILTER_ALERT_KEY]?: string
  [FILTER_RETENTION_VIOLATION]?: string
  [FILTER_LAST_MODIFIED_TIME_KEY]?: string
  [FILTER_SKIP_KEY]?: string
  [FILTER_ATTRIBUTE_KEY]?: string
  [FILTER_RETAIN_UNTIL]?: string
  [FILTER_DRIVES_KEY]?: string
  [FILTER_SHARED_KEY]?: string
  [FILTER_DANGER_KEY]?: string
  [FILTER_FROM_EMAIL_KEY]?: string
  [FILTER_RECIPIENT_EMAIL_KEY]?: string
  [FILTER_MAILBOX_KEY]?: string
  [FILTER_MAIL_FOLDER_TYPE]?: MAIL_FOLDER_TYPE
  [FILTER_MAIL_KEY]?: string
  [FILTER_MAIL_RECIPIENT]?: string
  [FILTER_PROJECT_ID]?: string
  [FILTER_PROJECT_IDS]?: string
  [GROUP_BY]?: string
  [FILTER_ASSIGNEE_EMAIL]?: string
  [FILTER_REPORTER_EMAIL]?: string
  [FILTER_HAS_ATTACHMENT]?: string
  [FILTER_CLASS_KEY]?: string
  [FILTER_ACCESS_TYPE_KEY]?: string
  [FILTER_MODULE_TYPE]?: string
  [FILTER_DSR_REQUEST_STEP]?: string
  [FILTER_DSR_REQUEST_ASSIGNEE]?: string
  [FILTER_DSR_REQUEST_TYPE]?: string
  [FILTER_DSR_REQUEST_TEMPLATE]?: string
  [FILTER_IDENTIFIER]?: string
  [FILTER_NO_OF_DATA_SOURCES]?: string
  [FILTER_BLOB_PARENT]?: string
  [FILTER_DATA_SOURCE_NAME]?: string
  [FILTER_DATA_SOURCE_ID_KEY]?: string
  [FILTER_DATA_SOURCE_STATUS]?: string
  [FILTER_DATA_SOURCE_OWNER]?: string
  [FILTER_DATASOURCE_CATEGORY]?: string
  [FILTER_SCHEMA]?: string
  [IS_CLOSED]?: boolean
  [IS_IN_PROGRESS]?: boolean
  [SEARCH_QUERY]?: string
  [FILTER_HAS_ENTITY_ACCESS]?: string
  [FILTER_ACCESS_MODES]?: string
  [ENTITY_ID]?: string
  [BLOB_ID]?: string
  [FILTER_EVENT_TYPE]?: string
  [FILTER_REQUEST_TYPE]?: string
  [FILTER_LABEL_KEY]?: string
  [FILTER_ENTITY]?: string
  [FILTER_DATA_SOURCE_KEY]?: string
  [START_DATE]?: string
  [END_DATE]?: string
  [ACTIVE_TAB]?: string
  [RETURN_URL]?: string
  [FILTER_LEGAL_HOLD_KEY]?: string
  [FILTER_REVIEW_STATUS]?: string
  [FILTER_REVIEW_SCHEDULE]?: string
  [FILTER_RISK_LEVEL]?: string
  [FILTER_FILE_TYPE]?: string
  [FILTER_FILE_SIZE]?: string
  [FILTER_LAST_UPDATED]?: string
  [FILTER_CREATED_AT]?: string
  [FILTER_SENSITIVITY]?: string
  [FILTER_FILE_CATEGORY]?: string
  [FILTER_SITES]?: string
  [FILTER_SCAN_STATUS]?: string
  [FILTER_EMPLOYEE_TYPE]?: string
  [FILTER_ACTION_STATE]?: string
  [FILTER_DEPARTMENT]?: string
  [FILTER_USER_ID]?: string
  [USER_NAME]?: string
  [FILTER_IS_OWNER]?: string
  [SORT_BY]?: string
  [FILTER_MEMBER_TYPE]?: string
  [FILTER_CLOUD_PLATFORM_ID]?: string
  [FILTER_CLOUD_ACCOUNT_ID]?: string
  [FILTER_REGION]?: string
  [FILTET_ENTITY_KEY]?: string
  [FILTER_RISK_SCORE]?: string
  [FILTER_ACTION_TAKEN_BY]?: string
  [FILTER_DATA_EXTENSIONS_KEY]?: string
  [FILTER_BUSINESS_UNITS_KEY]?: string
  [FILTER_HAS_MEMBERS]?: string
}
export const formatFiltersToApiUrlParams = (filters: Filters): URLFilterTypes => {
  const query: URLFilterTypes = {}

  if (filters[PAGE]) query[PAGE] = filters[PAGE]
  if (filters[PAGE_SIZE]) query[PAGE_SIZE] = filters[PAGE_SIZE]

  const danger = filters[FILTER_DANGER_KEY]
  if (danger) query[danger] = true

  if (filters[FILTER_TEMPLATE_KEY]) {
    const values = filters[FILTER_TEMPLATE_KEY] || []
    query[API_PARAM_TEMPLATE_NAME] = values.join(`&${API_PARAM_TEMPLATE_NAME}=`)
  }

  if (filters[FILTER_DATA_SOURCE_KEY]) {
    const values = filters[FILTER_DATA_SOURCE_KEY] || []
    query[API_PARAM_DATA_SOURCE_TYPE] = values.join(`&${API_PARAM_DATA_SOURCE_TYPE}=`)
  }

  if (filters[FILTER_FILE_TYPE_KEY]) {
    query[API_PARAM_FILE_TYPE] = filters[FILTER_FILE_TYPE_KEY]?.join()
  }

  if (filters[FILTER_CLASS_KEY]) {
    const values = filters[FILTER_CLASS_KEY] || []
    query[API_PARAM_CLASS] = values.join(`&${API_PARAM_CLASS}=`)
  }

  if (filters[FILTER_ACCESS_TYPE_KEY]) {
    const values = filters[FILTER_ACCESS_TYPE_KEY] || []
    query[API_PARAM_ACCESS_TYPE] = values.join(`&${API_PARAM_ACCESS_TYPE}=`)
  }

  if (filters[FILTER_SUB_CLASS_KEY]) {
    query[API_PARAM_SUB_CLASS] = filters[FILTER_SUB_CLASS_KEY]?.join(`&${API_PARAM_SUB_CLASS}=`)
  }

  if (filters[FILTER_ATTRIBUTE_KEY]) {
    query[API_PARAM_ATTRIBUTE] = filters[FILTER_ATTRIBUTE_KEY].join()
  }
  if (filters[FILTER_STATUS_KEY]) {
    query[FILTER_STATUS_KEY] = filters[FILTER_STATUS_KEY]?.join()
  }
  if (filters[FILTER_RISK_KEY]) {
    query[FILTER_RISK_KEY] = filters[FILTER_RISK_KEY]
  }

  if (filters[FILTER_RISK_SCORE]) {
    query[FILTER_RISK_SCORE] = filters[FILTER_RISK_SCORE]
  }

  if (filters[FILTER_ACTION_TAKEN_BY]) {
    query[FILTER_ACTION_TAKEN_BY] = filters[FILTER_ACTION_TAKEN_BY]
  }

  if (filters[FILTET_ENTITY_KEY]) {
    query[FILTET_ENTITY_KEY] = filters[FILTET_ENTITY_KEY]
  }

  if (filters[FILTER_MODULE_TYPE]) {
    query[FILTER_MODULE_TYPE] = filters[FILTER_MODULE_TYPE]
  }

  if (filters[FILTER_RESIDENCY_KEY]) {
    query[FILTER_RESIDENCY_KEY] = filters[FILTER_RESIDENCY_KEY].join()
  }
  if (filters[FILTER_SENSITIVITY_MULTIPLE]) {
    query[FILTER_SENSITIVITY_MULTIPLE] = filters[FILTER_SENSITIVITY_MULTIPLE].join()
  }
  if (filters[FILTER_CONSENT_EVENT]) {
    query[FILTER_CONSENT_EVENT] = filters[FILTER_CONSENT_EVENT].join()
  }
  if (filters[FILTER_CONSENT_PURPOSE]) {
    query[FILTER_CONSENT_PURPOSE] = filters[FILTER_CONSENT_PURPOSE].join()
  }
  if (filters[FILTER_ENTITY_TYPE]) {
    query[FILTER_ENTITY_TYPE] = filters[FILTER_ENTITY_TYPE].join()
  }
  if (filters[FILTER_LOCATION]) {
    query[FILTER_LOCATION] = filters[FILTER_LOCATION].join()
  }
  if (filters[FILTER_ACCESS]) {
    query[FILTER_ACCESS] = filters[FILTER_ACCESS].join()
  }
  if (filters[FILTER_RISK_LEVEL_KEY]) {
    query[FILTER_RISK_LEVEL_KEY] = filters[FILTER_RISK_LEVEL_KEY].join()
  }
  if (filters[FILTER_RISK_TYPE_KEY]) {
    query[FILTER_RISK_TYPE_KEY] = filters[FILTER_RISK_TYPE_KEY].join()
  }
  if (filters[FILTER_ATTRIBUTE_NAME]) {
    query[FILTER_ATTRIBUTE_NAME] = filters[FILTER_ATTRIBUTE_NAME].join()
  }
  if (filters[FILTER_PROCESS_GROUP]) {
    query[FILTER_PROCESS_GROUP] = filters[FILTER_PROCESS_GROUP].join()
  }
  if (filters[FILTER_ATTRIBUTE_ID]) {
    query[FILTER_ATTRIBUTE_ID] = filters[FILTER_ATTRIBUTE_ID].join()
  }
  if (filters[FILTER_ATTRIBUTE_SET]) {
    query[FILTER_ATTRIBUTE_SET] = filters[FILTER_ATTRIBUTE_SET].join()
  }
  if (filters[FILTER_DATASOURCES_IDS]) {
    query[FILTER_DATASOURCES_IDS] = filters[FILTER_DATASOURCES_IDS].join()
  }
  if (filters[FILTER_TEMPLATE_IDS]) {
    query[FILTER_TEMPLATE_IDS] = filters[FILTER_TEMPLATE_IDS].join()
  }
  if (filters[FILTER_ORG_ROLE]) {
    query[FILTER_ORG_ROLE] = filters[FILTER_ORG_ROLE].join()
  }
  if (filters[FILTER_CHANNEL_NAME]) {
    query[FILTER_CHANNEL_NAME] = filters[FILTER_CHANNEL_NAME]
  }

  if (filters[FILTER_DATASOURCE_CATEGORY]) {
    query[FILTER_DATASOURCE_CATEGORY] = filters[FILTER_DATASOURCE_CATEGORY]
  }

  if (filters[FILTER_CHANNEL_NAME]) {
    query[FILTER_CHANNEL_NAME] = filters[FILTER_CHANNEL_NAME]
  }

  if (filters[FILTER_RETAIN_UNTIL]) {
    query[FILTER_RETAIN_UNTIL] = filters[FILTER_RETAIN_UNTIL]
  }

  if (filters[FILTER_BLOB_PARENT]) {
    query[FILTER_BLOB_PARENT] = filters[FILTER_BLOB_PARENT].join()
  }

  if (filters[FILTER_PROJECT_ID]) {
    query[FILTER_PROJECT_ID] = filters[FILTER_PROJECT_ID]
  }
  if (filters[FILTER_PROJECT_IDS]) {
    query[FILTER_PROJECT_IDS] = filters[FILTER_PROJECT_IDS].join()
  }

  if (filters[FILTER_ASSIGNEE_EMAIL]) {
    query[FILTER_ASSIGNEE_EMAIL] = filters[FILTER_ASSIGNEE_EMAIL]
  }

  if (filters[FILTER_REPORTER_EMAIL]) {
    query[FILTER_REPORTER_EMAIL] = filters[FILTER_REPORTER_EMAIL]
  }

  if (filters[FILTER_CHANNEL_ID]) {
    query[FILTER_CHANNEL_ID] = filters[FILTER_CHANNEL_ID]
  }

  if (filters[GROUP_BY]) {
    query[GROUP_BY] = filters[GROUP_BY]
  }

  if (filters[FILTER_CHANNEL_TYPE]) {
    query[FILTER_CHANNEL_TYPE] = filters[FILTER_CHANNEL_TYPE]
  }

  if (filters[FILTER_TEAM_ID]) {
    query[FILTER_TEAM_ID] = filters[FILTER_TEAM_ID].join()
  }
  if (filters[FILTER_NO_OF_DATA_SOURCES]) {
    query[FILTER_NO_OF_DATA_SOURCES] = filters[FILTER_NO_OF_DATA_SOURCES].join()
  }

  if (filters[FILTER_DSR_REQUEST_TEMPLATE]) {
    query[FILTER_DSR_REQUEST_TEMPLATE] = filters[FILTER_DSR_REQUEST_TEMPLATE].join()
  }

  if (filters[FILTER_IDENTIFIER]) {
    query[FILTER_IDENTIFIER] = filters[FILTER_IDENTIFIER].join()
  }

  if (filters[FILTER_DSR_REQUEST_ASSIGNEE]) {
    query[FILTER_DSR_REQUEST_ASSIGNEE] = filters[FILTER_DSR_REQUEST_ASSIGNEE].join()
  }

  if (filters[FILTER_DSR_REQUEST_TYPE]) {
    query[FILTER_DSR_REQUEST_TYPE] = filters[FILTER_DSR_REQUEST_TYPE].join()
  }

  if (filters[FILTER_DSR_REQUEST_STEP]) {
    query[FILTER_DSR_REQUEST_STEP] = filters[FILTER_DSR_REQUEST_STEP].join()
  }

  if (filters[FILTER_DATA_SOURCE_TYPE_KEY]) {
    query[FILTER_DATA_SOURCE_TYPE_KEY] = filters[FILTER_DATA_SOURCE_TYPE_KEY].join()
  }

  if (filters[FILTER_RISK_DENSITY]) {
    query[FILTER_RISK_DENSITY] = filters[FILTER_RISK_DENSITY].join()
  }

  if (filters[FILTER_RULE_SET_KEY]) {
    query[FILTER_RULE_SET_KEY] = filters[FILTER_RULE_SET_KEY].join()
  }

  if (filters[FILTER_DATA_SOURCE_STATUS]) {
    query[FILTER_DATA_SOURCE_STATUS] = filters[FILTER_DATA_SOURCE_STATUS].join()
  }

  if (filters[FILTER_DATA_SOURCE_NAME]) {
    query[FILTER_DATA_SOURCE_NAME] = filters[FILTER_DATA_SOURCE_NAME].join()
  }
  if (filters[FILTER_DATA_SOURCE_ID_KEY]) {
    query[FILTER_DATA_SOURCE_ID_KEY] = filters[FILTER_DATA_SOURCE_ID_KEY].join()
  }
  if (filters[FILTER_ENTITY]) {
    query[FILTER_ENTITY] = filters[FILTER_ENTITY].join()
  }

  if (filters[FILTER_DATA_SOURCE_OWNER]) {
    query[FILTER_DATA_SOURCE_OWNER] = filters[FILTER_DATA_SOURCE_OWNER].join()
  }
  if (filters[FILTER_CLUSTER]) {
    query[FILTER_CLUSTER] = filters[FILTER_CLUSTER]
  }
  if (filters[FILTER_EMAIL_KEY]) {
    query[FILTER_EMAIL_KEY] = filters[FILTER_EMAIL_KEY].join()
  }
  if (filters[FILTER_SEVERITY_KEY]) {
    query[FILTER_SEVERITY_KEY] = filters[FILTER_SEVERITY_KEY].join()
  }
  if (filters[FILTER_POLICY_TYPE]) {
    query[FILTER_POLICY_TYPE] = filters[FILTER_POLICY_TYPE].join()
  }
  if (filters[FILTER_ALERT_STATUS]) {
    query[FILTER_ALERT_STATUS] = filters[FILTER_ALERT_STATUS].join()
  }
  if (filters[FILTER_ATTRIBUTE_HAS_INSTANCES]) {
    query[FILTER_ATTRIBUTE_HAS_INSTANCES] = filters[FILTER_ATTRIBUTE_HAS_INSTANCES]
  }
  if (filters[FILTER_POLICY_TYPES_KEY]) {
    query[FILTER_POLICY_TYPES_KEY] = filters[FILTER_POLICY_TYPES_KEY]
  }
  if (filters[FILTER_RULESET_ID_KEY]) {
    query[FILTER_RULESET_ID_KEY] = filters[FILTER_RULESET_ID_KEY]
  }
  if (filters[FILTER_OWNER_KEY]) {
    query[FILTER_OWNER_KEY] = filters[FILTER_OWNER_KEY].join()
  }
  if (filters[FILTER_PROCESS_KEY]) {
    query[FILTER_PROCESS_KEY] = filters[FILTER_PROCESS_KEY].join()
  }
  if (filters[FILTER_BUCKET_KEY]) {
    query[FILTER_BUCKET_KEY] = filters[FILTER_BUCKET_KEY].join()
  }
  if (filters[FILTER_ALERT_KEY]) {
    query[FILTER_ALERT_KEY] = filters[FILTER_ALERT_KEY]
  }
  if (filters[FILTER_RETENTION_VIOLATION]) {
    query[FILTER_RETENTION_VIOLATION] = filters[FILTER_RETENTION_VIOLATION]
  }
  if (filters[FILTER_LAST_MODIFIED_TIME_KEY]) {
    query[FILTER_LAST_MODIFIED_TIME_KEY] = filters[FILTER_LAST_MODIFIED_TIME_KEY]
  }
  if (filters[FILTER_SKIP_KEY]) {
    query[FILTER_SKIP_KEY] = filters[FILTER_SKIP_KEY]
  }
  if (filters[FILTER_DRIVES_KEY]) {
    query[FILTER_DRIVES_KEY] = filters[FILTER_DRIVES_KEY].join()
  }
  if (filters[FILTER_SKIP_REASON_KEY]) {
    query[FILTER_SKIP_REASON_KEY] = filters[FILTER_SKIP_REASON_KEY].join()
  }
  if (filters[FILTER_SKIP_REASON_CATEGORY_KEY]) {
    query[FILTER_SKIP_REASON_CATEGORY_KEY] = filters[FILTER_SKIP_REASON_CATEGORY_KEY].join()
  }
  if (filters[FILTER_SHARED_KEY]) {
    query[FILTER_SHARED_KEY] = filters[FILTER_SHARED_KEY].join()
  }
  if (filters[FILTER_FROM_EMAIL_KEY]) {
    query[FILTER_FROM_EMAIL_KEY] = filters[FILTER_FROM_EMAIL_KEY]
  }
  if (filters[FILTER_MAILBOX_KEY]) {
    query[FILTER_MAILBOX_KEY] = filters[FILTER_MAILBOX_KEY].join()
  }
  if (filters[FILTER_MAIL_FOLDER_TYPE]) {
    query[FILTER_MAIL_FOLDER_TYPE] = filters[FILTER_MAIL_FOLDER_TYPE]
  }
  if (filters[FILTER_RECIPIENT_EMAIL_KEY]) {
    query[FILTER_RECIPIENT_EMAIL_KEY] = filters[FILTER_RECIPIENT_EMAIL_KEY]
  }
  if (filters[FILTER_MAIL_KEY]) {
    query[FILTER_MAIL_KEY] = filters[FILTER_MAIL_KEY]
  }
  if (filters[FILTER_MAIL_RECIPIENT]) {
    query[FILTER_MAIL_RECIPIENT] = filters[FILTER_MAIL_RECIPIENT]
  }
  if (filters[FILTER_HAS_ATTACHMENT]) {
    query[FILTER_HAS_ATTACHMENT] = filters[FILTER_HAS_ATTACHMENT]
  }
  if (filters[FILTER_CHANNEL_TYPE]) {
    query[FILTER_CHANNEL_TYPE] = filters[FILTER_CHANNEL_TYPE]
  }
  if (filters[FILTER_SCHEMA]) {
    query[FILTER_SCHEMA] = filters[FILTER_SCHEMA].join()
  }
  if (filters[FILTER_DATABASE]) {
    query[FILTER_DATABASE] = filters[FILTER_DATABASE].join()
  }
  if (filters[FILTER_TABLE]) {
    query[FILTER_TABLE] = filters[FILTER_TABLE].join()
  }
  if (filters[FILTER_COLUMNS_STATUS]) {
    query[FILTER_COLUMNS_STATUS] = filters[FILTER_COLUMNS_STATUS].join()
  }
  if (filters[FILTER_CHANGE_TYPE]) {
    query[FILTER_CHANGE_TYPE] = filters[FILTER_CHANGE_TYPE].join()
  }
  if (filters[FILTER_PII]) {
    query[FILTER_PII] = filters[FILTER_PII].join()
  }
  if (filters[FILTER_COMMON_TYPE]) {
    query[FILTER_COMMON_TYPE] = filters[FILTER_COMMON_TYPE].join()
  }
  if (filters[FILTER_REVIEW]) {
    query[FILTER_REVIEW] = filters[FILTER_REVIEW].join()
  }
  if (filters[SEARCH_QUERY]) {
    query[SEARCH_QUERY] = filters[SEARCH_QUERY]
  }
  if (filters[FILTER_HAS_ENTITY_ACCESS]) {
    query[FILTER_HAS_ENTITY_ACCESS] = filters[FILTER_HAS_ENTITY_ACCESS]
  }
  if (filters[FILTER_HAS_MEMBERS]) {
    query[FILTER_HAS_MEMBERS] = filters[FILTER_HAS_MEMBERS]
  }
  if (filters[FILTER_ACCESS_MODES]) {
    query[FILTER_ACCESS_MODES] = filters[FILTER_ACCESS_MODES].join()
  }
  if (filters[ENTITY_ID]) {
    query[ENTITY_ID] = filters[ENTITY_ID]
  }
  if (filters[BLOB_ID]) {
    query[BLOB_ID] = filters[BLOB_ID]
  }
  if (filters[FILTER_EVENT_TYPE]) {
    query[FILTER_EVENT_TYPE] = filters[FILTER_EVENT_TYPE].join()
  }
  if (filters[FILTER_REQUEST_TYPE]) {
    query[FILTER_REQUEST_TYPE] = filters[FILTER_REQUEST_TYPE].join()
  }
  if (filters[FILTER_REVIEW_STATUS]) {
    query[FILTER_REVIEW_STATUS] = filters[FILTER_REVIEW_STATUS].join()
  }
  if (filters[FILTER_REVIEW_SCHEDULE]) {
    query[FILTER_REVIEW_SCHEDULE] = filters[FILTER_REVIEW_SCHEDULE].join()
  }
  if (filters[FILTER_RISK_LEVEL]) {
    query[FILTER_RISK_LEVEL] = filters[FILTER_RISK_LEVEL].join()
  }
  if (filters[FILTER_LABEL_KEY]) {
    query[FILTER_LABEL_KEY] = filters[FILTER_LABEL_KEY].join()
  }

  if (filters[FILTER_LEGAL_HOLD_KEY]) {
    query[FILTER_LEGAL_HOLD_KEY] = filters[FILTER_LEGAL_HOLD_KEY]
  }

  if (filters[FILTER_SCAN_STATUS]) {
    query[FILTER_SCAN_STATUS] = filters[FILTER_SCAN_STATUS]
  }
  if (filters[FILTER_FILE_TYPE]) {
    query[FILTER_FILE_TYPE] = filters[FILTER_FILE_TYPE]?.join()
  }
  if (filters[FILTER_FILE_SIZE]) {
    query[FILTER_FILE_SIZE] = filters[FILTER_FILE_SIZE]
  }
  if (filters[FILTER_LAST_UPDATED]) {
    query[FILTER_LAST_UPDATED] = filters[FILTER_LAST_UPDATED]
  }
  if (filters[FILTER_SITES]) {
    query[FILTER_SITES] = filters[FILTER_SITES].join()
  }
  if (filters[FILTER_FILE_CATEGORY]) {
    query[FILTER_FILE_CATEGORY] = filters[FILTER_FILE_CATEGORY]?.join()
  }
  if (filters[FILTER_CREATED_AT]) {
    query[FILTER_CREATED_AT] = filters[FILTER_CREATED_AT]
  }
  if (filters[FILTER_SENSITIVITY]) {
    query[FILTER_SENSITIVITY] = filters[FILTER_SENSITIVITY]
  }

  if (filters[FILTER_EMPLOYEE_TYPE]) {
    query[FILTER_EMPLOYEE_TYPE] = filters[FILTER_EMPLOYEE_TYPE].join()
  }
  if (filters[FILTER_ACTION_STATE]) {
    query[FILTER_ACTION_STATE] = filters[FILTER_ACTION_STATE].join()
  }
  if (filters[FILTER_DEPARTMENT]) {
    query[FILTER_DEPARTMENT] = filters[FILTER_DEPARTMENT]
  }
  if (filters[FILTER_USER_ID]) {
    query[FILTER_USER_ID] = filters[FILTER_USER_ID]
  }
  if (filters[USER_NAME]) {
    query[USER_NAME] = filters[USER_NAME]
  }
  if (filters[FILTER_IS_OWNER]) {
    query[FILTER_IS_OWNER] = filters[FILTER_IS_OWNER]
  }
  if (filters[SORT_BY]) {
    query[SORT_BY] = filters[SORT_BY]
  }
  if (filters[FILTER_MEMBER_TYPE]) {
    query[FILTER_MEMBER_TYPE] = filters[FILTER_MEMBER_TYPE]
  }

  if (filters[FILTER_CLOUD_ACCOUNT_ID]) {
    query[FILTER_CLOUD_ACCOUNT_ID] = filters[FILTER_CLOUD_ACCOUNT_ID].join()
  }

  if (filters[FILTER_REGION]) {
    query[FILTER_REGION] = filters[FILTER_REGION].join()
  }

  if (filters[FILTER_DATA_EXTENSIONS_KEY]) {
    query[FILTER_DATA_EXTENSIONS_KEY] = filters[FILTER_DATA_EXTENSIONS_KEY].join()
  }

  if (filters[FILTER_BUSINESS_UNITS_KEY]) {
    query[FILTER_BUSINESS_UNITS_KEY] = filters[FILTER_BUSINESS_UNITS_KEY].join()
  }

  return query
}

export const removeAllPageFilters = (pageName: string): void => {
  const queryParams = parse(history.location.search) || {}
  delete queryParams[pageName]
  history.push({ pathname: '', search: '?' + stringify({ ...queryParams }) })
}

/** Other func */
export const addToUrlQuery = (newQueryObj: { [key: string]: string | number | boolean }): void => {
  const queryObj = parse(history.location.search) || {}
  const updatedQueryObj = { ...queryObj, ...newQueryObj }

  history.push({ pathname: '', search: '?' + stringify(updatedQueryObj) })
}

export const goToLoginPage = () => {
  const fromPath = location.pathname + location.search
  sessionStorage.setItem(FROM, fromPath || URL_DASHBOARD)
  window.location.href = URL_LOGIN
}

export const changeUrl = (
  pathname: string,
  newQueryObj?: { [key: string]: string | number | boolean },
  resetQuery?: boolean,
  isPageRefresh?: boolean
): void => {
  const queryObj = parse(history.location.search) || {}
  const updatedQueryObj = resetQuery ? { ...newQueryObj } : { ...queryObj, ...newQueryObj }

  const cleanedQuery = removeFalsyValues(updatedQueryObj)

  if (isPageRefresh) {
    if (Object.keys(cleanedQuery).length) {
      window.location.href = pathname + '?' + stringify(cleanedQuery)
    } else {
      window.location.href = pathname
    }
  } else {
    history.push({ pathname, search: '?' + stringify(cleanedQuery) })
  }
}

export const updateSearchStr = (
  context: UrlContextParams,
  newPathName: string,
  newQueryParams?: UrlContextParams['queryParams']
): string => {
  const additionalMetaUrl =
    context.listUrl.substr(1) !== newPathName.split('/')[1]
      ? { [META_URL]: (context.queryParams[META_URL] || '').toString() + context.pathname }
      : {}

  return stringify({
    ...context.queryParams,
    ...newQueryParams,
    ...additionalMetaUrl
  })
}

const commonQueryKeys = [
  META_URL,
  DATA_SOURCE_TYPE,
  DATA_SOURCE_ID,
  ANNOTATION_TYPE,
  DRIVE_ID,
  TICKET_ID,
  PREVIEW
]

export const getUpdatedUrl = (params: {
  pathName?: string
  queryParams?: { [key: string]: string | number | boolean }
  resetQuery?: boolean
  pageName?: string
  pageQueryParams?: { [key: string]: string | number | boolean }
  resetPageQuery?: boolean | string[]
}): any => {
  const pathname = params.pathName || history.location.pathname
  const newPageQueryParams = params.pageQueryParams || {}
  const currentQueryParams = parse(history.location.search) || {}

  // // update meta url query param (if needed)
  // const additionalMetaUrl = params.pathName
  //   ? { [META_URL]: (currentQueryParams[META_URL] || '').toString() + currentPathname }
  //   : {}

  // clean up query params, keep only common and page-specific params
  const pathQueryKeys = pathname.split('/').map((path) => '/' + path)
  const queryParamsToKeep = [...commonQueryKeys, ...pathQueryKeys]

  // rewrite query params
  let pageQueryParams = {}

  if (params.pageName) {
    const currentPageFilters = currentQueryParams[params.pageName]
      ? parsePageFilters(currentQueryParams[params.pageName] + '')
      : ''

    if (Array.isArray(params.resetPageQuery)) {
      const filtersToRemove = params.resetPageQuery
      const updatedPageFilters = { ...currentPageFilters }
      filtersToRemove.forEach((filter) => delete updatedPageFilters[filter])
      pageQueryParams = {
        [params.pageName]: stringifyPageFilters(updatedPageFilters)
      }
    } else {
      pageQueryParams = {
        [params.pageName]: stringifyPageFilters(
          params.resetPageQuery
            ? newPageQueryParams
            : { ...currentPageFilters, ...newPageQueryParams }
        )
      }
    }
  }

  const cleanedQuery = {}
  for (const [key, value] of Object.entries(currentQueryParams)) {
    if (queryParamsToKeep.includes(key)) cleanedQuery[key] = value
  }

  const queryParams = params.resetQuery
    ? params.queryParams
    : { ...cleanedQuery, ...params.queryParams }

  const combinedParams = { ...queryParams, ...pageQueryParams }

  return pathname + '?' + stringify({ ...combinedParams })
}

export const openNewWindow = (url: string): void => {
  window.open(url)
}

export const filterTypesToApiTypes = (fileTypes: FileType[], type?: string) => {
  const typeArr = type?.split(',')
  let typesArr: Array<string> = []
  typeArr?.forEach((type) => {
    typesArr = typesArr.concat(fileTypes.find((fileType) => fileType.name === type)?.types || [])
  })
  return typesArr
}

/**
 * Returns notification's URL
 * based on module type.
 *
 * @param {object} module Notification's module object
 * @returns {string} Notification's URL.
 */
export const getNotificationUrl = (module): string => {
  const urlMapper = {
    [NOTIFICATION_TYPES.dsr]: () => {
      return getUpdatedUrl({
        pageName: URL_REQUEST,
        pathName: `${URL_DSR}${URL_DSR_REQUEST_MANAGEMENT}/${module.id}/${DSR_REQUEST_STEP.ID_VERIFICATION}`,
        resetQuery: true,
        pageQueryParams: {}
      })
    },
    [NOTIFICATION_TYPES.dsrTicket]: () => {
      return getUpdatedUrl({
        pageName: URL_TICKETS,
        pathName: `${URL_DSR}${URL_TICKETS}/${module.id}`
      })
    },
    [NOTIFICATION_TYPES.ropa]: () => {
      return getUpdatedUrl({
        pathName: `${URL_ROPA}${URL_TICKETS}/${module.id}`
      })
    },
    [NOTIFICATION_TYPES.alert]: () => {
      return getUpdatedUrl({
        pageName: URL_ALERTS,
        pathName: `${URL_POLICIES}${URL_ALERTS}/${module.id}${URL_OVERVIEW}`,
        pageQueryParams: {
          [PAGE_VIEW_KEY]: PAGE_VIEW_VALUES.list,
          [DATA_SOURCE_TYPE]: module.datasourceType,
          [DATA_SOURCE_ID]: module.datasourceId,
          [ASSIGNEE]: module.assignee
        }
      })
    },
    [NOTIFICATION_TYPES.ruleSet]: () => {
      return getUpdatedUrl({
        pathName: `${URL_POLICIES}${URL_DETAIL}/${module.id}`,
        queryParams: {
          [POLICY_TYPE]: module.policyType
        }
      })
    },
    [NOTIFICATION_TYPES.pia]: () => {
      return getUpdatedUrl({
        pathName: `${URL_PIA}${URL_TICKETS}/${module.id}`
      })
    }
  }

  return module.id !== '' && urlMapper[module.type]
    ? urlMapper[module.type]()
    : `${URL_POLICIES}${URL_NOTIFICATIONS}`
}

/**
 * Checks if the URL string
 * is a valid URL or not.
 *
 * @param {string} url
 * @returns {boolean} isValid
 */
export const isValidURL = (url: string) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$', // validate fragment locator
    'i'
  )
  return !!urlPattern.test(url)
}

// custom date range - date format YYYY-MM-DD
export const getCustomDateRange = (dateRange: string) => {
  const customDateRange = dateRange?.split(',')
  if (customDateRange.length === 3 && customDateRange[0] === FILTER_DUE_DATE_CUSTOM_KEY) {
    return {
      start: dayjs(customDateRange[1]).startOf('day').unix(),
      end: dayjs(customDateRange[2]).endOf('day').unix()
    }
  }
  return null
}

export const getGlobalParams = (state: RootState): IGlobalState => {
  const { global } = state
  const { pageName = '' } = global
  const queryParams = parse(window.location.search) as UrlContextParams['queryParams']
  const pageQueryString = queryParams[pageName || '']
  const filters = getFilters(pageQueryString)
  const pageSizeMapper = {
    [IS_AT_RISK]: filters[`${IS_AT_RISK}_${PAGE_SIZE}`],
    [IS_SENSITIVE]: filters[`${IS_SENSITIVE}_${PAGE_SIZE}`],
    [IS_RESOLVED]: filters[`${IS_RESOLVED}_${PAGE_SIZE}`],
    [IS_UNRESOLVED]: filters[`${IS_UNRESOLVED}_${PAGE_SIZE}`]
  }
  const pageSize = filters[IS_AT_RISK]
    ? pageSizeMapper[IS_AT_RISK]
    : filters[IS_SENSITIVE]
    ? pageSizeMapper[IS_SENSITIVE]
    : filters[IS_RESOLVED]
    ? pageSizeMapper[IS_RESOLVED]
    : filters[IS_UNRESOLVED]
    ? pageSizeMapper[IS_UNRESOLVED]
    : filters[PAGE_SIZE] || LIMIT_DEFAULT

  return { pageSize }
}

export const getRedirectionPath = () => {
  const from = sessionStorage.getItem(FROM) || ''
  const fromPage = from && from !== 'undefined' ? from : URL_DASHBOARD
  const isRootOrQuery = fromPage === '/' || /^\/\?.*/.test(fromPage) // check if path is / or /?something. We don't need to redirect to dashboard if it is
  if (isRootOrQuery) {
    return URL_DASHBOARD
  }
  return fromPage
}

import { Typography } from '@lightbeamai/design-system'
import classNames from 'classnames'
import React from 'react'

interface IProps {
  icon?: React.ReactElement
  iconRight?: React.ReactElement
  content: React.ReactNode
  className?: string
  textCapitalized?: boolean
  flexItemsCentered?: boolean
  onClick?: () => void
  tooltip?: number | string
  testId?: string
  showTooltip?: boolean
}

const RegularColumn = ({
  icon = <></>,
  iconRight = <></>,
  content,
  textCapitalized = false,
  flexItemsCentered = false,
  className = '',
  tooltip,
  testId = '',
  showTooltip = true
}: IProps): React.ReactElement => {
  const isContentString = Object.prototype.toString.call(content) === '[object String]'
  const cellContent = textCapitalized
    ? isContentString
      ? (content as string).toLowerCase()
      : content
    : content

  return (
    <>
      {icon}
      <Typography
        as="div"
        color="primary"
        variant="base"
        className={classNames('ag-regular-column truncate xs-p-0', {
          [className]: className,
          'ag-content-capitalized': textCapitalized,
          'ag-items-flex-content-centered': flexItemsCentered
        })}
        {...(testId ? { 'data-test-id': testId } : {})}
        {...(showTooltip ? { title: (tooltip || content) as string } : {})}
        data-test-id="datatable-regular-column"
      >
        {cellContent}
      </Typography>
      {iconRight}
    </>
  )
}

export default RegularColumn

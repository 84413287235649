import apiService from '../services/api/apiService'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

export interface FeatureFlags {
  accessControl: boolean
  auditLog: boolean
  dashboardCountersForFileDataSourcesEnabled: boolean
  patternBasedDetectionEnabled: boolean
  outlookProcessInboxMail: boolean
  riskScore: boolean
  [key: string]: boolean
}

const initialState: FeatureFlags = {
  accessControl: false,
  auditLog: false,
  patternBasedDetectionEnabled: false,
  dashboardCountersForFileDataSourcesEnabled: false,
  outlookProcessInboxMail: false,
  riskScore: false
}

export const ACTION_FETCH_FEATURE_FLAGS = 'featureFlags/fetch'
export const fetchFeatureFlags = createAsyncThunk(ACTION_FETCH_FEATURE_FLAGS, async () => {
  return await apiService.getFeatureFlags()
})

const featureFlagSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    resetFeatureFlag: (
      state,
      {
        payload
      }: PayloadAction<{
        feature: keyof FeatureFlags
        value: boolean
      }>
    ) => {
      state[payload.feature] = payload.value
    },
    setFeatureFlag: (state, { payload }) => {
      state[payload.feature] = payload.value
      window.__featureFlags = JSON.parse(JSON.stringify(state))
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeatureFlags.fulfilled, (_, { payload }) => {
      window.__featureFlags = payload
      return payload
    })
  }
})

export const { resetFeatureFlag, setFeatureFlag } = featureFlagSlice.actions
export default featureFlagSlice.reducer

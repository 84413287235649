import {
  mapQueryDrivesList,
  mapQueryDrivesOwners,
  queryDrivesList,
  queryDrivesListCompact,
  queryDrivesOwners
} from './queries'
import { Drive, GDriveWidget, GDriveWidgetQueryParams } from '../../services/api/apiTypes'
import service from '../../services/api/apiService'
import { getSortDirection, SortParams } from '../../utils/sortUtil'
import { IGetDrivesParams, SORT_ORDER } from '../../interfaces'
import graphqlService from '../../services/graphqlService'
import { RootState } from '../../rootReducer'
import { getGlobalParams } from '../../utils/urlUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type DrivesListSettings = {
  list?: Drive[]
  total?: number
  sort: SortParams
}

interface GoogleDriveDashboardState {
  widget: GDriveWidget | null
  list: DrivesListSettings
  owners?: string[]
}

const initialList: DrivesListSettings = {
  sort: {
    column: 'driveName',
    direction: SORT_ORDER.ASC
  }
}

export const initialState: GoogleDriveDashboardState = {
  widget: null,
  list: { ...initialList }
}

export const fetchDrivesWidget = createAsyncThunk(
  'drives/widget',
  async (filters: GDriveWidgetQueryParams) => await service.getDrivesWidget(filters || {})
)

export const ACTION_DRIVES_FETCH = 'drives/list'
export const fetchDrivesList = createAsyncThunk(
  ACTION_DRIVES_FETCH,
  async (params: IGetDrivesParams, { getState }) => {
    const queryParams = { ...params, ...getGlobalParams(getState() as RootState) }
    const resultRaw = await graphqlService.execute(queryDrivesList(queryParams))
    return mapQueryDrivesList(resultRaw)
  }
)

export const ACTION_DRIVES_COMPACT_FETCH = 'drives/list/compact'
export const fetchDrivesListCompact = createAsyncThunk(
  ACTION_DRIVES_COMPACT_FETCH,
  async (params: IGetDrivesParams) => {
    const resultRaw = await graphqlService.execute(queryDrivesListCompact(params))
    return mapQueryDrivesList(resultRaw)
  }
)

export const ACTION_DRIVES_OWNERS_FETCH = 'drives/owners'
export const fetchDrivesOwners = createAsyncThunk(
  ACTION_DRIVES_OWNERS_FETCH,
  async (params: IGetDrivesParams) => {
    const resultRaw = await graphqlService.execute(queryDrivesOwners(params))
    return mapQueryDrivesOwners(resultRaw)
  }
)

const drivesSlice = createSlice({
  name: 'drives',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state.list.sort = getSortDirection(state.list.sort, payload.column)
    },
    resetWidget: (state) => {
      state.widget = initialState.widget
    },
    clearList: (state) => {
      state.list = initialState.list
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDrivesWidget.fulfilled, (state, action) => {
      state.widget = action.payload
    })
    builder.addCase(fetchDrivesList.fulfilled, (state, { payload }) => {
      const { list, total } = payload
      state.list.list = list
      state.list.total = total
    })
    builder.addCase(fetchDrivesListCompact.fulfilled, (state, { payload }) => {
      const { list, total } = payload
      state.list.list = list
      state.list.total = total
    })
    builder.addCase(fetchDrivesOwners.fulfilled, (state, { payload }) => {
      state.owners = [...new Set(payload.list)]
    })
  }
})

export const { setSort, resetWidget, clearList } = drivesSlice.actions

export default drivesSlice.reducer

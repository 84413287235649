import {
  mapQueryAttributeNameIdsForRiskScore,
  mapQueryRiskScoreByDataSourceId,
  mapQueryDataSourcesRiskScore,
  mapQueryRiskScoreConfig,
  mapQueryRiskScoreStatus,
  mutationSaveRiskScoreConfig,
  queryAttributeNamesIdsForRiskScore,
  queryRiskScoreByDataSourceId,
  queryDataSourcesRiskScore,
  queryRiskScoreConfig,
  queryRiskScoreStatus
} from './queries'
import { FEATURE_FLAGS, SENSITIVE_LABEL } from '../../constants'
import graphqlService from '../../services/graphqlService'
import { AttributeNameIds } from '../attributes/attributesSlice'
import { store } from '../..'
import { setFeatureFlag } from '../../configs/featureFlagSlice'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export function getTagType(sensitivityLabel: string): any {
  if (sensitivityLabel === 'high') {
    return 'error'
  }
  if (sensitivityLabel === 'medium') {
    return 'caution'
  }
  return 'success'
}

const sortSensitivityRiskWeights = (list: sensitivityRiskWeightsType[]) => {
  const order = {
    [SENSITIVE_LABEL.HIGH]: 1,
    [SENSITIVE_LABEL.MEDIUM]: 2,
    [SENSITIVE_LABEL.LOW]: 3
  }

  return list.sort((a, b) => order[a.sensitivityLevel] - order[b.sensitivityLevel])
}

export type sensitivityRiskWeightsType = {
  sensitivityLevel: SENSITIVE_LABEL
  riskWeight: number
}

export interface DataSourceRiskScoreData {
  numberOfSensitiveObjects: number
  riskDensity: number
  riskScoreGraphData: any
}

export enum RiskScoreWorkFlows {
  pending = 'PENDING',
  inProgress = 'IN_PROGRESS',
  completed = 'COMPLETED'
}

export enum DefaultRiskWeights {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 10
}

export type RiskScoreDistribution = {
  riskScore: number
  numberOfObjects: number
}
export type DataSourceRiskScore = {
  id: string
  name: string
  type: string
  totalRiskScore: number
  riskDensity: number
}

interface RiskScoreState {
  isRiskScoreEnabled: boolean
  attributeSensitivityRiskWeights?: sensitivityRiskWeightsType[]
  attributeRiskWeights?: AttributeNameIds[]
  dataSourceRiskScoreData?: DataSourceRiskScoreData
  dataSourcesRiskScore?: DataSourceRiskScore[]
  workflowStatus?: RiskScoreWorkFlows
}

const initialState: RiskScoreState = {
  isRiskScoreEnabled: false
}

export const ACTION_FETCH_RISK_SCORE_STATUS = 'settings/fetchRiskScoreStatus'
export const fetchRiskScoreStatus = createAsyncThunk(ACTION_FETCH_RISK_SCORE_STATUS, async () => {
  try {
    const resultRaw = await graphqlService.execute(queryRiskScoreStatus())
    const value = mapQueryRiskScoreStatus(resultRaw)
    store.dispatch(setFeatureFlag({ feature: FEATURE_FLAGS.RISK_SCORE, value }))
    return value
  } catch (error) {
    store.dispatch(setFeatureFlag({ feature: FEATURE_FLAGS.RISK_SCORE, value: false }))
  }
})

export const ACTION_FETCH_RISK_SCORE_CONFIG = 'settings/fetchRiskScoreConfig'
export const fetchRiskScoreConfig = createAsyncThunk(ACTION_FETCH_RISK_SCORE_CONFIG, async () => {
  const resultRaw = await graphqlService.execute(queryRiskScoreConfig())
  return mapQueryRiskScoreConfig(resultRaw)
})

export const ACTION_SAVE_RISK_SCORE_CONFIG = 'settings/saveRiskScoreConfig'
export const saveRiskScoreConfig = createAsyncThunk(
  ACTION_SAVE_RISK_SCORE_CONFIG,
  async ({ isRiskScoreEnabled, sensitivityRiskWeights, attributeRiskWeights }: any) => {
    const resultRaw = await graphqlService.execute(
      mutationSaveRiskScoreConfig({
        isRiskScoreEnabled,
        sensitivityRiskWeights,
        attributeRiskWeights
      })
    )
    return resultRaw
  }
)

export const ACTION_FETCH_ATTRIBUTE_RISK_WEIGHTS = 'settings/fetchAttributeRiskWeights'
export const fetchAttributeRiskWeights = createAsyncThunk(
  ACTION_FETCH_ATTRIBUTE_RISK_WEIGHTS,
  async () => {
    const resultRaw = await graphqlService.execute(queryAttributeNamesIdsForRiskScore())
    return mapQueryAttributeNameIdsForRiskScore(resultRaw)
  }
)

export const ACTION_FETCH_RISK_SCORE_BY_DATASOURCE_ID = 'fetch/riskScoreByDataSourceId'
export const fetchRiskScoreByDataSourceId = createAsyncThunk(
  ACTION_FETCH_RISK_SCORE_BY_DATASOURCE_ID,
  async (dataSourceId: string) => {
    const resultRaw = await graphqlService.execute(queryRiskScoreByDataSourceId(dataSourceId))
    return mapQueryRiskScoreByDataSourceId(resultRaw)
  }
)

export const ACTION_DATA_SOURCES_RISK_SCORE = 'settings/dataSourcesRiskScore'
export const fetchDataSourcesRiskScore = createAsyncThunk(
  ACTION_DATA_SOURCES_RISK_SCORE,
  async () => {
    const resultRaw = await graphqlService.execute(queryDataSourcesRiskScore())
    return mapQueryDataSourcesRiskScore(resultRaw)
  }
)

const riskScoreSlice = createSlice({
  name: 'riskScores',
  initialState,
  reducers: {
    setAttributeRiskWeights: (state, { payload }) => {
      state.attributeRiskWeights = payload
    },
    setAttributeSensitivityRiskWeights: (state, { payload }) => {
      state.attributeSensitivityRiskWeights = payload
    },
    setIsRiskScoreEnabled: (state, { payload }) => {
      state.isRiskScoreEnabled = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRiskScoreConfig.fulfilled, (state, { payload }) => {
      state.workflowStatus = payload.workflowStatus
      state.attributeSensitivityRiskWeights =
        payload.sensitivityRiskWeights.length > 0
          ? sortSensitivityRiskWeights(payload.sensitivityRiskWeights)
          : []
    })
    builder.addCase(fetchAttributeRiskWeights.fulfilled, (state, { payload }) => {
      state.attributeRiskWeights = payload.list
    })
    builder.addCase(fetchRiskScoreStatus.fulfilled, (state, { payload }) => {
      state.isRiskScoreEnabled = payload
    })
    builder.addCase(fetchRiskScoreStatus.rejected, (state) => {
      state.isRiskScoreEnabled = false
    })
    builder.addCase(fetchRiskScoreByDataSourceId.fulfilled, (state, { payload }) => {
      state.dataSourceRiskScoreData = payload
    })
    builder.addCase(fetchDataSourcesRiskScore.fulfilled, (state, { payload }) => {
      state.dataSourcesRiskScore = payload
    })
  }
})

export const {
  setAttributeRiskWeights,
  setAttributeSensitivityRiskWeights,
  setIsRiskScoreEnabled
} = riskScoreSlice.actions

export default riskScoreSlice.reducer
